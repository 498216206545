import {
  TOGGLE_CART,
  ADD_SAMPLE_TO_CART,
  REMOVE_SAMPLE_FROM_CART,
  REMOVE_SAMPLE_FROM_CART_BY_INDEX,
  EMPTY_CART
} from "../actions/cart.actions";
import { isEqual } from "lodash";
import { CATEGORY_COHORT } from "../actions/category-types";

export const initialState = {
  open: false,
  items: []
};

export const cart = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CART:
      if (state.open) {
        document.body.classList.remove("body-cart-open");
      } else {
        document.body.classList.add("body-cart-open");
      }

      return { ...state, open: !state.open };
    case ADD_SAMPLE_TO_CART:
      // if the combination of sample id and filters already exist, dont add it
      if (
        state.items.filter(
          item =>
            isEqual(item.codeInfo.id, action.codeInfo.id) &&
            isEqual(item.specimenType, action.specimenType) &&
            isEqual(item.filters, action.filters)
        ).length > 0
      ) {
        return state;
      }

      const newItem = {
        category: action.category,
        codeInfo: action.codeInfo,
        specimenType: action.specimenType,
        filters: action.filters,
        sample: action.sample,
        quantity: action.quantity
      };

      return {
        ...state,
        items: [...state.items, newItem]
      };
    case REMOVE_SAMPLE_FROM_CART:
      const idx = state.items.findIndex(item => {
        const checkIfCohortItemIsMatch =
          isEqual(item.category, CATEGORY_COHORT) &&
          isEqual(item.codeInfo.id, action.codeInfo.id);
        const checkIfCatalogItemIsMatch =
          isEqual(item.codeInfo.id, action.codeInfo.id) &&
          isEqual(item.specimenType, action.specimenType) &&
          isEqual(item.filters, action.filters);

        return checkIfCohortItemIsMatch || checkIfCatalogItemIsMatch;
      });
      if (idx >= 0) {
        return {
          ...state,
          items: [...state.items.slice(0, idx), ...state.items.slice(idx + 1)]
        };
      }
      return state;
    case REMOVE_SAMPLE_FROM_CART_BY_INDEX:
      if (state.items && state.items[action.idx]) {
        return {
          ...state,
          items: [
            ...state.items.slice(0, action.idx),
            ...state.items.slice(action.idx + 1)
          ]
        };
      }

      return state;

    case EMPTY_CART:
      return { ...initialState, open: state.open };

    default:
      return state;
  }
};
