import { CommonContactInfoSection } from "./CommonContactInfoSection";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import { countryCodeLength } from "../../helpers/formSchemaHelper";
import React from "react";
import { useTranslation } from "react-i18next";

export const PathologistContactInfoSection = ({
  formControl: FormControl,
  disabled = false
}) => {
  const { t } = useTranslation();

  return (
    <>
      <CommonContactInfoSection
        formControl={FormControl}
        prefix={"p"}
        disabled={disabled}
      >
        <Form.Group controlId={"formCPhone"}>
          <FormControl
            type={"tel"}
            name={"p_phone"}
            label={t("phone")}
            maxLength={30}
            disabled={disabled}
          />
        </Form.Group>
        <Form.Group controlId={"formCPhone"}>
          <FormControl
            name={"p_address"}
            label={t("address")}
            maxLength={100}
            disabled={disabled}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId={"formCCountryCode"}>
            <FormControl
              name={"p_countrycd"}
              label={t("country_code", { countryCodeLength })}
              length={countryCodeLength}
              disabled={disabled}
            />
          </Form.Group>

          <Form.Group as={Col} controlId={"formCZip"}>
            <FormControl
              name={"p_zip"}
              label={t("zip")}
              maxLength={10}
              disabled={disabled}
            />
          </Form.Group>

          <Form.Group as={Col} controlId={"formCCity"}>
            <FormControl
              name={"p_city"}
              label={t("city")}
              maxLength={40}
              disabled={disabled}
            />
          </Form.Group>
        </Form.Row>
      </CommonContactInfoSection>
    </>
  );
};
