import React from "react";
import { useTranslation } from "react-i18next";

const CartItemDeleteButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <button className="remove" onClick={onClick}>
      {t("remove_from_cart")}
    </button>
  );
};

export default CartItemDeleteButton;
