// TODO: Disallow duplicate files
import React from "react";
import { useDropzone } from "react-dropzone";
import "core-js/modules/es.array.from";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import RequestFileList from "./RequestFileList";
import { formatFileSize } from "../../helpers/fileSizeFormatter";
import styles from "./file-uploader.module.scss";

const maxFileSize = 30000000;

// https://codesandbox.io/s/removable-drop-zone-82km9
const FileUploader = ({
  onDrop,
  initialFilenames,
  onFilesFromFeasibilityChange
}) => {
  const { t } = useTranslation();
  const [myFiles, setMyFiles] = React.useState([]);

  // These are filenames that need to be rendered in the FileUploader's file list.
  // Since these aren't full file-objects, we need to store these in own variable.
  // This is used when user creates access request from existing feasibility request,
  // which has attachment files.
  const initialFiles = initialFilenames
    ? initialFilenames.map(filename => ({
        name: filename
      }))
    : [];

  const onDropCallback = React.useCallback(
    acceptedFiles => {
      const files = [...myFiles, ...acceptedFiles];
      setMyFiles(files);
      onDrop(files);
    },
    [myFiles, onDrop]
  );

  const acceptedFileTypes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx
    "application/pdf",
    "application/rtf",
    "text/rtf",
    "text/plain"
  ];

  const onDropRejected = files => {
    let errorMessage = `${t("max_file_size_exceeded")} ${formatFileSize(
      maxFileSize
    )}`;
    for (const file of files) {
      if (!acceptedFileTypes.includes(file.type)) {
        errorMessage = t("accepted_filetypes");
        break;
      }
    }
    alert(`${errorMessage}`);
  };

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: acceptedFileTypes,
    noKeyboard: true,
    maxSize: maxFileSize,
    multiple: true,
    onDrop: onDropCallback,
    onDropRejected
  });

  const handleRemoveFile = React.useCallback(
    fileName => {
      const dt = new DataTransfer();

      // Add selected fields to DataTransfer object
      for (const file of myFiles) {
        file.name !== fileName && dt.items.add(file); // Add only file name not matched files
      }

      if (initialFiles.length > 0) {
        // To trigger the change in the state of the parent.
        onFilesFromFeasibilityChange(
          initialFilenames.filter(filename => filename !== fileName)
        );
      }

      inputRef.current.files = dt.files; // Overwrite files
      setMyFiles(Array.from(dt.files)); // Set states to render file list
    },
    // eslint-disable-next-line
    [inputRef, myFiles]
  );

  return (
    <section>
      <RequestFileList
        files={[...initialFiles, ...myFiles]}
        handleRemoveFile={fileName => handleRemoveFile(fileName)}
      />
      <div {...getRootProps({ className: styles.dropZone })}>
        <input {...getInputProps()} />
        <p>
          {t("drop_attachments_or")}
          <br />
          <button type={"button"} className={"button"}>
            {t("browser_directory")}
          </button>
        </p>
        <p>
          <small>
            {t("accepted_filetypes")}
            <br />
            {t("max_file_size_is")} {formatFileSize(maxFileSize)}
          </small>
        </p>
      </div>
    </section>
  );
};

FileUploader.propTypes = {
  onDrop: PropTypes.func.isRequired
};

export default FileUploader;
