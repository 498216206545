import React from "react";
import ContentLoader from "react-content-loader";

const LoadingForm = () => (
  <ContentLoader
    speed={2}
    width={719}
    height={200}
    viewBox="0 0 719 200"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className="custom-loader"
  >
    <rect x="3" y="0" rx="0" ry="0" width="216" height="32" />
    <rect x="236" y="20" rx="0" ry="0" width="478" height="27" />
    <rect x="236" y="0" rx="0" ry="0" width="52" height="13" />
    <rect x="236" y="90" rx="0" ry="0" width="234" height="27" />
    <rect x="236" y="70" rx="0" ry="0" width="75" height="13" />
    <rect x="236" y="158" rx="0" ry="0" width="478" height="27" />
    <rect x="236" y="138" rx="0" ry="0" width="58" height="13" />
    <rect x="481" y="90" rx="0" ry="0" width="234" height="27" />
    <rect x="481" y="70" rx="0" ry="0" width="115" height="13" />
  </ContentLoader>
);

export default LoadingForm;
