import React from "react";
import { useTranslation } from "react-i18next";

const CartClosed = ({ items, onToggle }) => {
  const { t } = useTranslation();

  const samplesSelectedTranslation = {
    __html: t("search_samples_selected", { total: items.length })
  };

  return (
    <div className="cart-closed">
      <div className="finbb-container cart-bar">
        <ol className="padder">
          <li>
            <span>{t("find_samples")}</span>
          </li>
          <li>
            <span>{t("feasibility_request")}</span>
          </li>
          <li>
            <span>{t("access_request")}</span>
          </li>
        </ol>
        <button
          className="toggle"
          href="#"
          onClick={onToggle}
          dangerouslySetInnerHTML={samplesSelectedTranslation}
        ></button>
      </div>
    </div>
  );
};

export default CartClosed;
