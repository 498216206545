import { connect } from "react-redux";
import Cart from "./Cart";
import { toggleCart, emptyCart } from "../actions/cart";

const mapStateToProps = state => {
  return {
    items: state.cart.items,
    isOpen: state.cart.open
  };
};

function mapDispatchToProps(dispatch) {
  return {
    onToggle() {
      dispatch(toggleCart());
    },
    onEmpty() {
      dispatch(emptyCart());
    }
  };
}

const CartContainer = connect(mapStateToProps, mapDispatchToProps)(Cart);
export default CartContainer;
