import React, { useContext, useEffect } from "react";
import BreadcrumbContainer from "../components/BreadcrumbContainer";
import ICD10DirectoryContainer from "../components/ICD10DirectoryContainer";
import MainContainer from "../components/MainContainer";
import AfterLoginContext from "../contexts/AfterLogin";

const FrontPage = () => {
  const { isAfterLogin, setIsAfterLogin } = useContext(AfterLoginContext);

  useEffect(() => {
    if (isAfterLogin) {
      setIsAfterLogin(false);
    }
  }, [isAfterLogin, setIsAfterLogin]);

  return (
    <div className="App">
      <MainContainer>
        <div className="finbb-container">
          <BreadcrumbContainer />
          <ICD10DirectoryContainer />
        </div>
      </MainContainer>
    </div>
  );
};

export default FrontPage;
