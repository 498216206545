import axios from "../helpers/axios";
import config from "../config";

import {
  SELECT_DIRECTORY,
  FETCH_DIRECTORIES_REQUEST,
  FETCH_DIRECTORIES_SUCCESS,
  FETCH_DIRECTORIES_FAILURE
} from "./directory.actions";
import { logError } from "../helpers/logger";

export const selectDirectory = function(target) {
  return function(dispatch) {
    dispatch({
      type: SELECT_DIRECTORY,
      category: target
    });

    dispatch(fetchDirectories(target));
  };
};

export const requestDirectories = function(target) {
  return {
    type: FETCH_DIRECTORIES_REQUEST,
    target
  };
};

export const requestDirectoriesSuccess = function(target, results) {
  return {
    type: FETCH_DIRECTORIES_SUCCESS,
    target,
    results
  };
};

export const requestDirectoriesFailure = function(target) {
  return {
    type: FETCH_DIRECTORIES_FAILURE,
    target
  };
};

export const fetchDirectories = function(target) {
  return function(dispatch, getState) {
    // First dispatch: the app state is updated to inform
    // that the API call is starting.

    dispatch(requestDirectories(target));

    const currentLanguage = getState().lng;

    let url = `${config.catalogApiUrl}/v1/icd10/`;
    if (target) {
      url += target;
    }
    if (currentLanguage && currentLanguage.length) {
      url += "?lang=" + currentLanguage;
    }

    axios
      .get(url)
      .then(
        function(response) {
          dispatch(requestDirectoriesSuccess(target, response.data));
        },
        err => {
          console.error(`Failed to GET ${url}, got ${JSON.stringify(err)}`);
        }
      )
      .catch(function(error) {
        // handle error
        logError(error);
        dispatch(requestDirectoriesFailure(target));
      });
  };
};
