import { USER_LOGOUT } from "../actions/root.actions";
import catalogApp from "../store/catalogApp";
import { clearCookies } from "../helpers/session";

import { initialState as aRequestState } from "./access-request";
import { initialState as fRequestState } from "./feasibility-request";
import { initialState as cartState } from "./cart";
import { initialState as formsState } from "./forms";
import { defaultState as cohortState } from "./cohort-collection";
import { defaultState as miabisState } from "./miabis-collection-types";
import { defaultState as miabisDataState } from "./miabis-data-categories";
import { defaultState as miabisMaterialsState } from "./miabis-material-types";
import { initialState as samplesState } from "./samples";
import { initialState as searchState } from "./search";
import { initialState as userState } from "./user";
import { initialState as langState } from "./i18n";

const initialState = {
  accessRequestReducer: aRequestState,
  feasibilityRequestReducer: fRequestState,
  cart: cartState,
  forms: formsState,
  user: userState,
  search: searchState,
  lng: langState,
  cohortCollections: cohortState,
  miabisCollectionTypes: miabisState,
  miabisDataCategories: miabisDataState,
  miabisMaterialTypes: miabisMaterialsState,
  currentSample: samplesState
};

const rootReducer = routerReducer => (state, action) => {
  const app = catalogApp(routerReducer);

  if (action.type === USER_LOGOUT) {
    clearCookies();
    return app(initialState, action);
  }
  return app(state, action);
};

export { rootReducer };
