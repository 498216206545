import config from "../config";
import axios from "../helpers/axios";
import { logError } from "../helpers/logger";
import {
  FETCH_COHORT_COLLECTION,
  FETCH_COHORT_COLLECTION_FAILURE,
  FETCH_COHORT_COLLECTION_SUCCESS,
  FETCH_COHORT_COLLECTIONS,
  FETCH_COHORT_COLLECTIONS_FAILURE,
  FETCH_COHORT_COLLECTIONS_SUCCESS
} from "./cohort-collections.actions";

export const requestCohortCollections = function(target) {
  return {
    type: FETCH_COHORT_COLLECTIONS,
    target
  };
};

export const requestCohortCollectionsSuccess = function(target, results) {
  return {
    type: FETCH_COHORT_COLLECTIONS_SUCCESS,
    target,
    results
  };
};

export const requestCohortCollectionsFailure = function(target) {
  return {
    type: FETCH_COHORT_COLLECTIONS_FAILURE,
    target
  };
};

export const requestCohortCollection = function(target) {
  return {
    type: FETCH_COHORT_COLLECTION,
    target
  };
};

export const requestCohortCollectionSuccess = function(target, result) {
  return {
    type: FETCH_COHORT_COLLECTION_SUCCESS,
    result
  };
};

export const requestCohortCollectionFailure = function(target) {
  return {
    type: FETCH_COHORT_COLLECTION_FAILURE,
    target
  };
};

export const fetchCohortCollection = function(target) {
  return async function(dispatch) {
    dispatch(requestCohortCollection(target));

    const url = `${config.catalogApiUrl}/v1/cohort-collections/${target}`;

    try {
      const response = await axios.get(url);
      dispatch(
        requestCohortCollectionSuccess(target, response.data.cohortCollection)
      );
    } catch (error) {
      logError(error);
      dispatch(requestCohortCollectionFailure(target));
    }
  };
};

export const fetchCohortCollections = function(target) {
  return async function(dispatch) {
    dispatch(requestCohortCollection(target));

    const url = `${config.catalogApiUrl}/v1/cohort-collections/`;

    try {
      const response = await axios.get(url);
      dispatch(
        requestCohortCollectionsSuccess(target, response.data.cohortCollections)
      );
    } catch (error) {
      logError(error);
      dispatch(requestCohortCollectionsFailure(target));
    }
  };
};
