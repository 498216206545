export const translateMiabisEntityName = (miabisEntity, language) => {
  const fallbackKey = "name_en";

  const key = `name_${language}`;

  return miabisEntity[key] ?? miabisEntity[fallbackKey];
};

const sortKeywordsLast = (stringA, stringB, keywords) => {
  const sortedKeyWords = keywords
    .map(keyword => keyword.toLocaleLowerCase())
    .sort((a, b) => a.localeCompare(b));

  if (sortedKeyWords.includes(stringA.toLocaleLowerCase())) {
    return 1;
  } else if (sortedKeyWords.includes(stringB.toLocaleLowerCase())) {
    return -1;
  } else {
    return stringA.localeCompare(stringB);
  }
};

export const sortMiabisEntitiesAlphabetically = (entities, language) => {
  return entities.sort((a, b) => {
    return sortKeywordsLast(
      translateMiabisEntityName(a, language),
      translateMiabisEntityName(b, language),
      ["other", "muu"]
    );
  });
};
