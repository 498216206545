import React from "react";
import FilterGroup from "./FilterGroup";

const filterGroups = [
  {
    groupId: "genome_data_available",
    filters: [
      {
        id: "yes"
      }
    ],
    tooltip: true
  },
  {
    groupId: "contact_permission",
    filters: [
      {
        id: "yes"
      }
    ],
    tooltip: true
  },
  {
    groupId: "gender",
    filters: [
      {
        id: "male"
      },
      {
        id: "female"
      }
    ],
    tooltip: false
  }
];

const Filters = ({ selectedFilters, disabledFilters, onSelectFilter }) => (
  <div className="filters-holder">
    <div className="finbb-container">
      <div className="filters">
        {filterGroups.map(group => {
          const activeFiltersInThisGroup = selectedFilters.find(
            filter => filter.field === group.groupId
          );

          return (
            <FilterGroup
              key={group.groupId}
              groupId={group.groupId}
              groupTitle={group.groupTitle}
              filters={group.filters}
              tooltip={group.tooltip}
              onSelectFilter={onSelectFilter}
              active={
                activeFiltersInThisGroup ? activeFiltersInThisGroup.values : []
              }
              enabled={!disabledFilters.includes(group.groupId)}
            />
          );
        })}
      </div>
    </div>
  </div>
);

export default Filters;
