import { Card, LoadingIndicator } from "@finbb/ui-components";
import { IconListSearch } from "@tabler/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import {
  filterCohortCollectionsByCollectionTypes,
  filterCohortCollectionsByDataCategories,
  filterCohortCollectionsByGenders,
  filterCohortCollectionsByMaterialTypes,
  filterCohortCollectionsBySearchValue
} from "../../helpers/cohort-collection-util";
import { translateMiabisEntityName } from "../../helpers/field-translate-util";
import {
  StyledNumberOfSearchResults,
  StyledSearchHeader,
  StyledSearchIcon
} from "../../pages/CohortCollectionSearchPage/CohortCollectionSearchPage.styles";
import MiabisCategoryIcon from "../MiabisCategoryIcon/MiabisCategoryIcon";
import {
  StyledCardList,
  StyledCardListItem,
  StyledCardListItemMessage,
  StyledCohortCollectionSearchList
} from "./CohortCollectionSearchList.styles";

const CohortCollectionSearchList = ({
  cohortCollections,
  collectionTypes,
  dataCategories,
  genders,
  loading,
  materialTypes,
  searchValue
}) => {
  const theme = useTheme();
  const {
    t,
    i18n: { language }
  } = useTranslation();

  if (loading) {
    return <LoadingIndicator />;
  }

  const cohortsFilteredDataCategories = filterCohortCollectionsByDataCategories(
    cohortCollections,
    dataCategories
  );

  const cohortsFilteredByCollectionTypes = filterCohortCollectionsByCollectionTypes(
    cohortsFilteredDataCategories,
    collectionTypes
  );

  const cohortsFilteredByMaterialTypes = filterCohortCollectionsByMaterialTypes(
    cohortsFilteredByCollectionTypes,
    materialTypes
  );

  const cohortsFilteredByFauxGenders = filterCohortCollectionsByGenders(
    cohortsFilteredByMaterialTypes,
    genders
  );

  const filteredCohortCollections = filterCohortCollectionsBySearchValue(
    cohortsFilteredByFauxGenders,
    searchValue
  );

  filteredCohortCollections?.sort((a, b) =>
    a.name.localeCompare(b.name, "fi", { ignorePunctuation: true })
  );

  return (
    <StyledCohortCollectionSearchList>
      <StyledSearchHeader>
        <StyledSearchIcon>
          <IconListSearch
            color={theme.values.colorPalette.greyCold[50]}
            size={theme.values.iconSizes.LARGE}
            strokeWidth={theme.values.strokeWidths.THIN}
          />
        </StyledSearchIcon>

        <span>{t("cohort_collection_search.search_results")}</span>
        <StyledNumberOfSearchResults>
          <span>•</span>
          <span>
            {t("cohort_collection_search.number_of_results", {
              count: filteredCohortCollections.length
            })}
          </span>
        </StyledNumberOfSearchResults>
      </StyledSearchHeader>

      <StyledCardList>
        {filteredCohortCollections.length === 0 ? (
          <StyledCardListItemMessage>
            {t("cohort_collection_search.none_found")}
          </StyledCardListItemMessage>
        ) : (
          filteredCohortCollections.map(cohortCollection => {
            const collectionCategoryIcons = cohortCollection.data_categories.map(
              dataCategory => ({
                title: translateMiabisEntityName(dataCategory, language),
                icon: <MiabisCategoryIcon miabisId={dataCategory.id} />
              })
            );

            return (
              <StyledCardListItem key={cohortCollection.id}>
                <Card
                  imageSrc={cohortCollection.image_url}
                  properties={collectionCategoryIcons}
                  content={{
                    title: cohortCollection.name,
                    description: cohortCollection.description
                  }}
                  linkTo={`/cohorts/${cohortCollection.id}`}
                />
              </StyledCardListItem>
            );
          })
        )}
      </StyledCardList>
    </StyledCohortCollectionSearchList>
  );
};

export default CohortCollectionSearchList;
