import { SELECTED_MATERIALS } from "../common/selectedMaterials";
import { specimenTypeMapping } from "./feasibilityRequestMapper";
import { ACCESS_PROPERTIES_BY_SECTIONS_MAP } from "./formsRequestMappers";

/**
 * Returns a new object with an update of the values passed based on the new properties values defined on a Map
 * The Map defines a new value or an operation(function) to update the value
 */
const mapToCleanValues = (values, propMapToClean) => {
  const cleanProperties = { ...values };
  Object.keys(propMapToClean).forEach(property => {
    const newValue = propMapToClean[property];
    const currentValue = cleanProperties[property];
    cleanProperties[property] =
      typeof newValue === "function" ? newValue(currentValue) : newValue;
  });
  return cleanProperties;
};

export const cleanPropsForNonSelectedSections = values => {
  let cleanProperties = { ...values };
  const sectionSelectorsIds = [
    SELECTED_MATERIALS.DONOR,
    SELECTED_MATERIALS.PHENOTYPE,
    SELECTED_MATERIALS.SAMPLE,
    SELECTED_MATERIALS.LIQUID,
    SELECTED_MATERIALS.TISSUE
  ];

  sectionSelectorsIds.forEach(propertyName => {
    if (!cleanProperties.selected_materials.includes(propertyName)) {
      const propsMapToClean = ACCESS_PROPERTIES_BY_SECTIONS_MAP[propertyName];
      const cleanValues = mapToCleanValues(cleanProperties, propsMapToClean);
      cleanProperties = {
        ...cleanProperties,
        ...cleanValues
      };
    }
  });

  if (!cleanProperties.sample_types.includes(specimenTypeMapping.genome_data)) {
    const propsMapToClean = ACCESS_PROPERTIES_BY_SECTIONS_MAP.GENOTYPE;
    const cleanValues = mapToCleanValues(cleanProperties, propsMapToClean);
    cleanProperties = {
      ...cleanProperties,
      ...cleanValues
    };
  }
  return cleanProperties;
};
