import { RequestSection } from "../../RequestSection";
import { useTranslation } from "react-i18next";
import React from "react";
import Form from "react-bootstrap/Form";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";

export const ReturnResultsDataSection = ({
  formProps,
  formControl: FormControl,
  datePicker: DatePicker,
  label: Label,
  radio: Radio,
  disabled
}) => {
  const { t } = useTranslation();

  return (
    <RequestSection
      title={t("arForm_results_data")}
      description={t("arForm_results_data_desc")}
    >
      <DatePicker
        disabled={disabled}
        namePrefix="est_data_return_"
        label={t("arForm_estimated_return_label")}
      />
      <Form.Group controlId="formWhatResults">
        <FormControl
          as={"textarea"}
          name={"what_results_returns"}
          label={t("arForm_what_results_returns_label")}
          maxLength={2500}
        />
      </Form.Group>
      <Form.Group id="formRelevantFindingsFound">
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"relevant_findings_found"}
        >
          {t("arForm_relevant_findings_label")}
        </Label>
        <Radio
          label={t("arForm_relevant_findings_found_no")}
          name={"relevant_findings_found"}
          value={"10"}
        />
        <Radio
          label={t("arForm_relevant_findings_found_yes")}
          name={"relevant_findings_found"}
          value={"20"}
        />
      </Form.Group>
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={
          <Popover id="popover-basic">
            <Popover.Content>
              <p>{t("arForm_what_findings_desc")}</p>
            </Popover.Content>
          </Popover>
        }
      >
        <strong>
          {t("arForm_what_findings_title")}
          <Button variant="outline-info" className="tooltip-button">
            i
          </Button>
        </strong>
      </OverlayTrigger>
      {/** TODO: move this to info box */}
      <Form.Group controlId="formFindings">
        <FormControl
          as={"textarea"}
          name={"what_findings"}
          label={t("arForm_what_findings_label")}
          maxLength={500}
        />
      </Form.Group>
    </RequestSection>
  );
};
