import "../App.scss";

import { useAuth0 } from "@auth0/auth0-react";
import { DesignSystemProvider, themes } from "@finbb/ui-components";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
import { Navigate, Routes } from "react-router";
import { Route } from "react-router-dom";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";

import { AfterLoginProvider } from "../contexts/AfterLogin";
import { getTokens, redirectToLogout, setHistory } from "../helpers/session";
import { emptyStorage } from "../helpers/storageHelper";
import useTracking from "../hooks/useTracking";
import AccessRequestRoutes from "../routes/AccessRequestRoutes";
import CatalogRoutes from "../routes/CatalogRoutes";
import CohortRoutes from "../routes/CohortRoutes";
import ExpertRoutes from "../routes/ExpertRoutes";
import FeasibilityRequestRoutes from "../routes/FeasibilityRequestRoutes";
import RequestRoutes from "../routes/RequestRoutes";
import StaticRoutes from "../routes/StaticRoutes";
import UserRoutes from "../routes/UserRoutes";
import configureStore from "../store/configureStore";
import AppLoadScreen from "./AppLoadScreen/AppLoadScreen";
import Layout from "./Layout";
import PrivateRoute from "./PrivateRoute";
import withIEFallback from "./withIEFallback";

const handleRoute = props => {
  if (props.render) {
    return props.render;
  }

  return props.private ? (
    <Route key={props.path} {...props} element={<PrivateRoute />}>
      <Route {...props} element={props.element} />
    </Route>
  ) : (
    <Route key={props.path} {...props} />
  );
};

const routes = [
  ...FeasibilityRequestRoutes,
  ...AccessRequestRoutes,
  ...UserRoutes,
  ...CatalogRoutes,
  ...CohortRoutes,
  ...ExpertRoutes,
  ...RequestRoutes,
  ...StaticRoutes,
  {
    path: "/",
    exact: true,
    private: false,
    element: <Navigate key="default-navigate-route" to="/user/login" />
  }
];

const { store, persistor, history } = configureStore();

const pathRequiresAuth = path => {
  const pathRoute = routes.find(route => route.path === path);
  return pathRoute.private;
};

const AppWithoutRouter = () => {
  const [isAfterLogin, setIsAfterLogin] = useState(false);

  useTracking();

  const providerValue = { isAfterLogin, setIsAfterLogin };

  return (
    <Layout>
      <AfterLoginProvider value={providerValue}>
        <Routes>{routes.map(handleRoute, history)}</Routes>
      </AfterLoginProvider>
    </Layout>
  );
};

const AppWithRouter = () => {
  const [isTokensFetched, setTokensFetched] = useState();
  const {
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isAuthenticated,
    isLoading
  } = useAuth0();

  // Empty the storage on first render
  useEffect(() => emptyStorage(), []);

  useEffect(() => {
    getTokens(
      isLoading,
      isAuthenticated,
      getAccessTokenSilently,
      getAccessTokenWithPopup
    ).then(data => {
      setTokensFetched(data.tokensFetched);
    });
  }, [
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isAuthenticated,
    isLoading
  ]);

  const theme = themes.dark;

  setHistory(history);

  const path = window && window.location.pathname;
  // Logout if not authenticated and auth0 is done loading
  if (!isLoading && !isAuthenticated && pathRequiresAuth(path)) {
    redirectToLogout();
  }

  return (
    <Provider store={store}>
      <DesignSystemProvider theme={theme}>
        <ThemeProvider theme={theme}>
          {!isTokensFetched && isAuthenticated ? (
            <AppLoadScreen />
          ) : (
            <PersistGate loading={null} persistor={persistor}>
              <Router history={history}>
                <AppWithoutRouter />
              </Router>
              <ReduxToastr
                timeOut={4000}
                newestOnTop={false}
                preventDuplicates
                position="bottom-center"
                getState={state => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                closeOnToastrClick
              />
            </PersistGate>
          )}
        </ThemeProvider>
      </DesignSystemProvider>
    </Provider>
  );
};

export default withIEFallback(AppWithRouter);
