import { createBrowserHistory } from "history";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createReduxHistoryContext } from "redux-first-history";
import freezeMiddleware from "redux-freeze";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { rootReducer } from "../reducers/rootReducer";

const persistConfig = {
  key: "finbb",
  storage,
  whitelist: ["lng", "user", "cart"]
};

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({
  history: createBrowserHistory()
});

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer(routerReducer)
);

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(thunk, routerMiddleware, freezeMiddleware)
      // other store enhancers if any
    )
  );

  const history = createReduxHistory(store);
  const persistor = persistStore(store);

  return { store, persistor, history };
};

export default configureStore;
