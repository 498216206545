export const getAttachmentIdByFilename = (files, filenameToBeDeleted) => {
  const fileToBeDeleted = files.find(
    file => file.filename === filenameToBeDeleted
  );
  if (fileToBeDeleted) {
    return fileToBeDeleted.attachmentid ?? fileToBeDeleted.filename;
  }

  return null;
};

export const FileInfoType = {
  GENOTYPE: "genotype",
  PHENOTYPE_OR_LIFESTYLE: "phenotypeOrLifestyle",
  RECRUIT: "recruit",
  PERMITS: "permits",
  MANDATORY_FILES: "mandatory_files"
};
