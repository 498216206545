import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

import { RequestSection } from "../RequestSection";
import { specimenTypeMapping } from "../../helpers/feasibilityRequestMapper";
import { ICD10CodeLink } from "../ICD10CodeLink";

export const MaterialSection = ({
  formProps,
  label: Label,
  checkBox: Checkbox,
  formControl: FormControl
}) => {
  const { t } = useTranslation();
  const { values } = formProps;

  const isLiquidOrTissueSelected = values.selected_materials.includes(
    "samples"
  );

  return (
    <RequestSection
      title={t("material_title")}
      description={t("material_description")}
    >
      <Form.Group>
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"label_types_of_samples_needed"}
        >
          {t("types_of_samples_label")}
        </Label>
        <Checkbox
          label={t("samples_label")}
          name={"selected_materials"}
          value={"samples"}
        />
        <Checkbox
          label={t("phenotype_or_lifestyle_data_label")}
          name={"selected_materials"}
          value={"phenotype_or_lifestyle_data"}
        />
        <Checkbox
          label={t("genome_data")}
          name={"sample_types"}
          value={specimenTypeMapping.genome_data}
        />
        <Checkbox
          label={t("sample_donor_recruit_label")}
          name={"selected_materials"}
          value={"sample_donor_recruit"}
        />
      </Form.Group>

      {isLiquidOrTissueSelected && (
        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"materials_kind_needed"}
          >
            {t("materials_kind_needed_label")}
          </Label>
          <Checkbox
            label={t("liquid_samples_label")}
            name={"selected_materials"}
            value={"liquid_samples"}
          />
          <Checkbox
            label={t("tissue_samples_label")}
            name={"selected_materials"}
            value={"tissue_samples"}
          />
        </Form.Group>
      )}

      <Form.Group>
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"already_existing_research_cohort"}
        >
          {t("already_existing_research_cohort")}
        </Label>
        <Checkbox
          label={t("already_existing_research_cohort_label")}
          name={"preselected_research_cohort"}
          value={"preselected_research_cohort"}
        />
      </Form.Group>
      <Form.Group>
        <Label formProps={formProps} fieldName={"inclusion_criteria_title"}>
          <h5>{t("inclusion_criteria_title")}</h5>
          <Trans
            i18nKey="material_inclusion_criteria_label"
            components={{
              link: <ICD10CodeLink />
            }}
          />
        </Label>
        <FormControl
          as={"textarea"}
          name={"material_inclusion_criteria"}
          placeholder={t("material_inclusion_criteria_eg")}
          maxLength={2000}
        />
        <Label formProps={formProps} fieldName={"exclusion_criteria_title"}>
          <h5>{t("exclusion_criteria_title")}</h5>
        </Label>
        <FormControl
          as={"textarea"}
          name={"material_exclusion_criteria"}
          label={t("material_exclusion_criteria_label")}
          placeholder={t("material_exclusion_criteria_eg")}
          maxLength={2000}
        />
      </Form.Group>
    </RequestSection>
  );
};
