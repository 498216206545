import React from "react";

import ICD10Category from "./ICD10Category";
import ICD10Code from "./ICD10Code";

// TODO: remove random totalSamples
const ICD10Directory = ({ directories }) => (
  <div className="directory">
    {directories.map(directory => {
      return directory.is_group ? (
        <ICD10Category
          key={directory.id}
          id={directory.id}
          name={directory.code}
          label={directory.title}
          totalSamples={Math.floor(Math.random() * 7000)}
        />
      ) : (
        <ICD10Code
          key={directory.id}
          id={directory.id}
          name={directory.code}
          label={directory.title}
          totalSamples={Math.floor(Math.random() * 7000)}
        />
      );
    })}
  </div>
);

export default ICD10Directory;
