import {
  findContactDetail,
  addLegacyContactDetails,
  biobankNameToNumber,
  projectTypeToNumber,
  sampleTypeToNumber,
  dataCollectorToNumber,
  invoicingMethodToNumber
} from "./legacyTypeMapperHelpers";
import { FileInfoType } from "./attachmentHelper";

const addLegacyInvoicingDetails = (legacyObject, invoicingDetails) => {
  addLegacyContactDetails(legacyObject, invoicingDetails, "i");
  legacyObject["i_businessid"] = invoicingDetails?.organizationId;
};

const accessRequestToLegacy = request => {
  const legacyAccessRequest = {
    requestid: request.id,
    type: "access-request",
    included_biobanks: request.biobanks?.map(biobankNameToNumber) || [],
    projecttype: [projectTypeToNumber(request.projectType)],
    projektin_nimi: request.projectName,
    brief_project_desc: request.projectDescription,
    project_desc: request.englishSummary,
    project_summary: request.finnishSummary,
    project_short_name: request.projectShortName,
    starting_month: request.startingMonth,
    starting_year: request.startingYear,
    ending_month: request.endingMonth,
    ending_year: request.endingYear,
    selected_cohort_names: request.selectedCohortNames,
    project_timeline_months: request.projectTimelineMonths,
    have_budget: request.haveBudget ? "1" : "0",
    budget_until_year: request.budgetUntilYear,
    budget: request.budget,
    createdate: request.createdAt,
    changedate: request.updatedAt,
    state: request.state
  };

  const miscData = request.miscellaneousDetail;
  legacyAccessRequest.selected_materials = miscData?.selectedMaterials || [];
  legacyAccessRequest.preselected_research_cohort =
    miscData?.preselectedResearchCohort;
  legacyAccessRequest.liite_selected = miscData?.liiteSelected;
  legacyAccessRequest.research_permit_desc =
    miscData?.researchPermitDescription;
  legacyAccessRequest.deleted = miscData?.deleted;
  legacyAccessRequest.preanalysisid = miscData?.preanalysisId;
  legacyAccessRequest.researchers = miscData?.researchers;

  const contactPersonDetail = findContactDetail(request, "person");
  const backgroundOrganizationDetails = findContactDetail(
    request,
    "organization"
  );
  const investigatorContactDetails = findContactDetail(request, "investigator");
  const pathologistContactDetails = findContactDetail(request, "pathologist");
  const invoiceContactDetails = findContactDetail(request, "invoice");
  const otherContactDetails = findContactDetail(request, "other");
  addLegacyContactDetails(legacyAccessRequest, contactPersonDetail, "c");
  addLegacyContactDetails(
    legacyAccessRequest,
    backgroundOrganizationDetails,
    "bg"
  );
  addLegacyContactDetails(legacyAccessRequest, investigatorContactDetails, "r");
  addLegacyContactDetails(legacyAccessRequest, pathologistContactDetails, "p");
  addLegacyInvoicingDetails(legacyAccessRequest, invoiceContactDetails);
  addLegacyContactDetails(legacyAccessRequest, otherContactDetails, "a");

  const sampleDetail = request.sampleDetail;
  // legacyAccessRequest.already_existing_research_cohort = sampleDetail?.sampleDonorRecruitData;
  legacyAccessRequest.material_inclusion_criteria =
    sampleDetail?.materialsInclusionCriteria;
  legacyAccessRequest.material_exclusion_criteria =
    sampleDetail?.materialsExclusionCriteria;
  legacyAccessRequest.material_exclusion_criteria =
    sampleDetail?.materialsExclusionCriteria;

  legacyAccessRequest.other_cell_sample_type =
    sampleDetail?.otherCellSampleType;
  legacyAccessRequest.other_liquid_sample_type =
    sampleDetail?.otherLiquidSampleType;
  legacyAccessRequest.liquid_samples_additional_info =
    sampleDetail?.liquidsAdditionalInfo;
  legacyAccessRequest.sample_volume_description =
    sampleDetail?.liquidsSampleVolume;
  legacyAccessRequest.requirements_for_critical_study =
    sampleDetail?.liquidsCriticalRequirements;
  legacyAccessRequest.samples_from_each_donor =
    sampleDetail?.samplesFromEachDonor || [];
  legacyAccessRequest.liquid_sample_inclusion_criteria_description =
    sampleDetail?.liquidsInclusionCriteria;
  legacyAccessRequest.liquid_sample_exclusion_criteria_description =
    sampleDetail?.liquidsExclusionCriteria;

  legacyAccessRequest.tissue_sample_inclusion_criteria =
    sampleDetail?.tissuesInclusionCriteria;
  legacyAccessRequest.tissue_sample_exclusion_criteria =
    sampleDetail?.tissuesExclusionCriteria;
  legacyAccessRequest.tissue_sample_additional_info =
    sampleDetail?.tissuesAdditionalInfo;
  legacyAccessRequest.pathologist_by_biobank =
    sampleDetail?.pathologistByBiobank;
  legacyAccessRequest.pathologist_by_specialisation =
    sampleDetail?.pathologistBySpecialisation;

  legacyAccessRequest.phenotype_or_lifestyle_desc =
    sampleDetail?.phenotypeLifestyleDesc;
  legacyAccessRequest.genotype_desc = sampleDetail?.genotypeDesc;
  legacyAccessRequest.recruitment_desc = sampleDetail?.recruitmentDesc;

  legacyAccessRequest.sample_types =
    sampleDetail?.sampleType?.map(sampleTypeToNumber) || [];
  legacyAccessRequest.sequence_data_desc =
    sampleDetail?.sequenceDataDescription;
  legacyAccessRequest.other_sample_type = sampleDetail?.otherSampleType;
  legacyAccessRequest.exact_desc = sampleDetail?.exactDescription;
  legacyAccessRequest.requirements = sampleDetail?.requirements;
  legacyAccessRequest.special_requirements = sampleDetail?.specialRequirements;

  legacyAccessRequest.exact_desc_data =
    request.studyDetail?.exactDescriptionData;
  legacyAccessRequest.who_collects_data = [
    dataCollectorToNumber(request.studyDetail?.whoCollectsData)
  ];
  legacyAccessRequest.isauriapato = request.studyDetail?.isauriapato;
  legacyAccessRequest.cotykssapapato = request.studyDetail?.cotykssapapato;
  legacyAccessRequest.who_collects_data_desc =
    request.studyDetail?.whoCollectsDataDescription;
  legacyAccessRequest.est_data_return_month =
    request.studyDetail?.estimatedDataReturnMonth;
  legacyAccessRequest.est_data_return_year =
    request.studyDetail?.estimatedDataReturnYear;
  legacyAccessRequest.what_results_returns =
    request.studyDetail?.whatResultsReturns;
  legacyAccessRequest.relevant_findings_found =
    request.studyDetail?.relevantFindingsFound === "yes" ? "20" : "10";
  legacyAccessRequest.what_findings = request.studyDetail?.whatFindings;

  legacyAccessRequest.invoicing_method = invoicingMethodToNumber(
    request.invoiceDetail?.invoicingMethod
  );
  legacyAccessRequest.i_operator = request.invoiceDetail?.operator;
  legacyAccessRequest.i_broker = request.invoiceDetail?.broker;
  legacyAccessRequest.i_reference = request.invoiceDetail?.reference;

  legacyAccessRequest.ethicscommitteestatementavailable =
    request.rationaleDetail?.isEthicsStatement;
  legacyAccessRequest.scienceprofit = request.rationaleDetail?.scienceProfit;
  legacyAccessRequest.ethical_evaluation =
    request.rationaleDetail?.ethicalEvaluation;
  legacyAccessRequest.research_arguments =
    request.rationaleDetail?.researchArguments;
  legacyAccessRequest.hashealthbenefits =
    request.rationaleDetail?.hasHealthBenefits;
  legacyAccessRequest.healthbenefits = request.rationaleDetail?.healthBenefits;
  legacyAccessRequest.accidental_findings =
    request.rationaleDetail?.accidentalFindings;
  legacyAccessRequest.hasrisks = request.rationaleDetail?.hasRisks;
  legacyAccessRequest.risks = request.rationaleDetail?.risks;
  legacyAccessRequest.linkages = request.rationaleDetail?.linkages;
  legacyAccessRequest.mainfunding = request.rationaleDetail?.mainFunding;
  legacyAccessRequest.research_result_publish_desc =
    request.rationaleDetail?.publicationPlan;

  const filterByFieldName = fieldName => {
    return attachment => attachment.infoType === fieldName;
  };

  const getFileName = attachDetail => ({
    filename: attachDetail.fileName
  });

  const attachmentDetails = request.attachmentDetail;
  legacyAccessRequest.files =
    attachmentDetails?.filter(filterByFieldName("file"))?.map(getFileName) ||
    [];
  legacyAccessRequest.permits_files =
    attachmentDetails
      ?.filter(filterByFieldName(FileInfoType.PERMITS))
      ?.map(getFileName) || [];
  legacyAccessRequest.mandatory_files =
    attachmentDetails
      ?.filter(filterByFieldName(FileInfoType.MANDATORY_FILES))
      ?.map(getFileName) || [];
  legacyAccessRequest.genotype_files =
    attachmentDetails
      ?.filter(filterByFieldName(FileInfoType.GENOTYPE))
      ?.map(getFileName) || [];
  legacyAccessRequest.phenotype_lifestyle_files =
    attachmentDetails
      ?.filter(filterByFieldName(FileInfoType.PHENOTYPE_OR_LIFESTYLE))
      ?.map(getFileName) || [];
  legacyAccessRequest.recruitment_files =
    attachmentDetails
      ?.filter(filterByFieldName(FileInfoType.RECRUIT))
      ?.map(getFileName) || [];

  return legacyAccessRequest;
};

export default accessRequestToLegacy;
