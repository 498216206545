import { Beforeunload } from "react-beforeunload";
import React from "react";
import { useTranslation } from "react-i18next";

const UnsavedChangesPrompt = ({ isEnabled }) => {
  const { t } = useTranslation();
  const msg = t("unsaved_changes");
  return (
    isEnabled && (
      <>
        <Beforeunload onBeforeunload={() => msg} />
      </>
    )
  );
};

export default UnsavedChangesPrompt;
