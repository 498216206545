import {
  DividerBorder,
  DividerBorderContainer,
  DividerContainer,
  DividerText,
  DividerTextContainer
} from "./DividerWithText.styles";

const DividerWithText = ({ children }) => {
  return (
    <DividerContainer>
      <DividerBorderContainer aria-hidden={true}>
        <DividerBorder />
      </DividerBorderContainer>
      <DividerTextContainer>
        <DividerText>{children}</DividerText>
      </DividerTextContainer>
    </DividerContainer>
  );
};

export default DividerWithText;
