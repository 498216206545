import styled from "styled-components";

export const StyledFetchError = styled.div`
  ${({ theme }) => theme.objects.Copy.Style.Caution.Base}
`;

export const StyledCategoryHeading = styled.h2`
  ${({ theme }) => theme.mixins.Container("ROW", { alignItems: "center" })}
  ${({ theme }) => theme.objects.Heading.Style.Secondary.Base}
  ${({ theme }) => theme.objects.Heading.Scale.Large}
`;

export const StyledPageHeading = styled.h1`
  ${({ theme }) => theme.objects.Heading.Style.Primary.Base}
  ${({ theme }) => theme.objects.Heading.Scale.Large}
  font-weight: ${({ theme }) => theme.values.fontWeights.BOLD};
  margin: 0;
`;

export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledMaxWidthRow = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("ROW", {
      alignItems: "center",
      justifyContent: "space-between",
      wrap: "wrap"
    })}
  max-width: ${({ theme }) =>
    `calc((${theme.values.dimensions.CARD_WIDTH} + ${theme.values.spacing.MEDIUM}) * 4)`};
  padding: 0 1em;
  width: 100vw;
  /* end of hardcoded values */
`;

export const StyledCardListItem = styled.li`
  ${({ theme }) => theme.objects.ListItem.Base}
  ${({ theme }) => theme.objects.ListItem.Unobtrusive}
`;

export const StyledCardList = styled.ul`
  ${({ theme }) => theme.objects.List.Base}
  ${({ theme }) => theme.objects.List.Unobtrusive}
  ${({ theme }) => theme.mixins.Container("ROW", { wrap: "wrap" })}
  max-width: ${({ theme }) =>
    `calc((${theme.values.dimensions.CARD_WIDTH} + ${theme.values.spacing.MEDIUM}) * 4)`};
  width: 100%;

  > ${StyledCardListItem} {
    flex-shrink: 0;
  }
`;

export const StyledInfoModalButton = styled.a`
  cursor: pointer;
  color: white;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export const StyledCategory = styled.section`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", {
      alignItems: "center",
      gap: theme.values.spacing.LARGE
    })}
  width: 100%;

  > ${StyledCategoryHeading} {
    max-width: ${({ theme }) =>
      `calc((${theme.values.dimensions.CARD_WIDTH} + ${theme.values.spacing.MEDIUM}) * 4)`};
    width: 100%;
  }
`;

export const StyledCohortCollectionListPage = styled.div`
  ${({ theme }) => theme.mixins.Container("COLUMN", { alignItems: "center" })}
  width: 100%;
`;
