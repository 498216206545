import React from "react";
import ReactTooltip from "react-tooltip";

const InfoModalButton = ({ text }) => (
  <span className="info-btn" data-tip={text} data-effect="solid">
    <span>i</span>
    <ReactTooltip />
  </span>
);

export default InfoModalButton;
