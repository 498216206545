import React, { useEffect, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";

import { getAccessRequestEditUrl } from "../../helpers/linkGenerator";
import Title from "../../components/Title/Title";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import requestStates, {
  completedAccessStates
} from "../../common/requestStates";
import styles from "./access-request-status.module.scss";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import iconProcessing from "../../assets/img/icon_processing.svg";
import RequestResponse from "../../components/RequestResponse/RequestResponse";
import useFetchAccessRequest from "../../hooks/useFetchAccessRequest";
import LoadingWrapper from "../../components/LoadingWrapper";
import { isNewAccessRequest } from "../../helpers/requestHelper";
import { accessRequestUrl } from "../../routes/AccessRequestRoutes";

const isRejected = data => data && data.state === requestStates.rejected;
const COMPLETED_STATES = completedAccessStates;

// Since the new response implementation differs so much from old one the UI needs more rework.
// For now, we set response to null to skip rendering the response block.
const response = null;

const ConditionalInfoBlock = ({ data }) => {
  const { t } = useTranslation();
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    setIsCompleted(
      data && COMPLETED_STATES.some(state => state === data.state)
    );
  }, [data]);

  if (isCompleted) {
    return (
      <InfoBlock
        title={t("response_available_title")}
        iconSrc={iconProcessing}
        description={t("response_available_description")}
      />
    );
  }

  if (isRejected(data)) {
    return (
      <InfoBlock
        title={t("request_rejected")}
        iconSrc={iconProcessing}
        description={t("request_rejected_description")}
      />
    );
  }

  return (
    <InfoBlock
      title={t("request_being_processed")}
      iconSrc={iconProcessing}
      description={t("waiting_for_biobanks_response")}
    />
  );
};

const AccessRequestStatus = () => {
  const match = useMatch(`${accessRequestUrl}/status/:accessRequestId`);
  const { accessRequestId } = match.params;
  const { accessRequest, loading, error } = useFetchAccessRequest(
    accessRequestId
  );

  const { t } = useTranslation();

  if (!loading && isNewAccessRequest(accessRequest)) {
    return <Navigate to={getAccessRequestEditUrl(accessRequestId)} />;
  }

  return (
    <LoadingWrapper
      isLoading={loading}
      isInErrorState={error}
      errorMsg={t("access_request_status_loading_error")}
    >
      <Title
        rightChildren={
          <div className={styles.btnGroup}>
            <Link to={getAccessRequestEditUrl(accessRequestId)}>
              <button type="button" className={"button button-secondary"}>
                {t("view_access_request")}
              </button>
            </Link>
          </div>
        }
      >
        {accessRequest.projektin_nimi || t("untitled_access_request")}
      </Title>
      <HorizontalLine className={"reset-top-margin"} />
      {response ? (
        <RequestResponse
          response={response.response}
          biobankFiles={response.biobankFiles}
        />
      ) : (
        <ConditionalInfoBlock data={accessRequest} />
      )}
    </LoadingWrapper>
  );
};

export default AccessRequestStatus;
