import * as Yup from "yup";
import { requiredText } from "./formValidators";

// Country code is in ISO 3166-1 alpha-2
export const countryCodeLength = 2;

export const email = requiredText.email().max(100);

export const password = Yup.string()
  .min(8)
  .max(128);

export const langId = requiredText.max(2);

export const city = Yup.string()
  .min(3)
  .max(40)
  .required();

export const countryCode = requiredText.length(countryCodeLength);

export const zip = requiredText.max(10);

export const address = Yup.string()
  .min(5)
  .max(100)
  .required();

export const optionalAddress = Yup.string()
  .min(5)
  .max(100);

export const company = requiredText.max(100);

export const organizationType = Yup.number().required();

export const phone = Yup.string()
  .min(5)
  .max(30)
  .required();

export const surName = requiredText.max(50);

export const firstName = requiredText.max(40);

export const projectName = requiredText.min(2).max(500);

export const businessId = Yup.string()
  .min(2)
  .max(50);

export const commonRequiredContactInfoFields = {
  firstname: firstName,
  surname: surName,
  email: email,
  company: company,
  phone: phone,
  countrycd: countryCode,
  zip: zip,
  city: city
};

export const commonOptionalContactInfoFields = {
  firstname: Yup.string()
    .min(0)
    .max(40),
  surname: Yup.string()
    .min(0)
    .max(50),
  email: Yup.string()
    .min(0)
    .email()
    .max(100),
  company: Yup.string()
    .min(0)
    .max(100),
  phone: Yup.string()
    .min(0)
    .max(30),
  countrycd: Yup.string()
    .min(0)
    .max(countryCodeLength),
  zip: Yup.string()
    .min(0)
    .max(10),
  city: Yup.string()
    .min(0)
    .max(40)
};

export const maxProjectDescriptionLength = 5000;

export const projectDescription = requiredText
  .min(2)
  .max(maxProjectDescriptionLength);
