import { connect } from "react-redux";
import SearchBox from "./SearchBox";

import {
  updateInputValue,
  loadSuggestions,
  selectValue
} from "../actions/search";

function mapStateToProps(state) {
  const { value, suggestions, isLoading } = state.search;

  return {
    value,
    suggestions,
    isLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onChange(event, { newValue }) {
      dispatch(updateInputValue(newValue));
    },
    onSuggestionsFetchRequested({ value }) {
      dispatch(loadSuggestions(value));
    },
    onSuggestionSelected(
      event,
      { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
    ) {
      dispatch(
        selectValue(suggestion.id, suggestion.children.length, suggestion.code)
      );
    },
    onSuggestionsClearRequested() {}
  };
}

const SearchBoxContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBox);
export default SearchBoxContainer;
