const contactInfoFields = [
  "firstname",
  "surname",
  "email",
  "company",
  "phone",
  "street1",
  "countrycd",
  "zip",
  "city",
  "org_type"
];

const getContactInfoFields = prefix =>
  contactInfoFields.reduce((result, key) => {
    result[`${prefix}${key}`] = "";
    return result;
  }, {});

const initialValues = {
  submitForApprovalClicked: false,

  // Biobanks section

  included_biobanks: [],

  // Contact person

  ...getContactInfoFields("c_"),

  // Background organization

  ...getContactInfoFields("bg_"),

  // Project details

  projektin_nimi: "",
  goals_of_the_study: "",

  /** New feasibility request fields */
  budget_timeline_week: "",
  budget_timeline_year: "",
  exist_budget: "",
  selected_cohort_names: [],
  samples_from_each_donor: [],
  preselected_research_cohort: "",
  selected_materials: [],

  phenotype_lifestyle_files: [],
  genotype_files: [],
  recruitment_files: [],

  /** Deprecated fields */
  targets_of_preanalys: "",
  diseases_of_interest: "",
  excluded_icd10_codes: "",
  pathological_samples_snomed_codes: "",
  patient_age_range: "",
  patient_gender: [],
  other_Inclusion_exclusion_criterias: "",
  sample_types: [],
  sequence_data_desc: "",
  other_sample_type: "",
  other_sample_type_criteria: "",
  sampling_time: "",
  clinical_data_linked: "",
  who_collects_data: [],
  who_collects_data_desc: "",
  timelines_for_project: "",
  feasibilitylevel: [],

  // Attachments

  files: []
};

export default initialValues;
