import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ROUTE_CHANGE = "react-route-change";

const useTracking = (
  trackingId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_CONTAINER_ID
) => {
  const location = useLocation();

  useEffect(() => {
    if (!window.dataLayer) return;
    if (!trackingId) return;

    window.dataLayer.push({ event: ROUTE_CHANGE });
  }, [trackingId, location]);
};

export default useTracking;
