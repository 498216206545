import {
  findContactDetail,
  addLegacyContactDetails,
  biobankNameToNumber,
  sampleTypeToNumber,
  dataCollectorToNumber,
  feasibilityLevelToNumber,
  genderToNumber
} from "./legacyTypeMapperHelpers";
import { FileInfoType } from "./attachmentHelper";

const feasibilityRequestToLegacy = request => {
  const legacyFeasibilityRequest = {
    preanalysisid: request.id,
    type: "feasibility-request",
    createdate: request.createdAt,
    changedate: request.updatedAt,
    deleted: request.isArchieved ? "T" : "F",
    project_number: request.projectSubCode,
    projektin_nimi: request.projectName,
    goals_of_the_study: request.studyGoals,
    recall_preanalys_report_type: request.recallReportType,
    preanalys_report_type: request.reportType,
    selected_cohort_names: request.cohorts,
    state: request.state
  };

  const misDetail = request.miscellaneousDetail;
  legacyFeasibilityRequest.deleted = misDetail?.deleted;
  legacyFeasibilityRequest.feasibilityresponse = misDetail?.feasibilityResponse;
  legacyFeasibilityRequest.show_feasibility_table_to_client =
    misDetail?.showFeasibilityTable;
  legacyFeasibilityRequest.show_data_table_to_client = misDetail?.showDataTable;

  const contactPersonDetail = findContactDetail(request, "person");
  addLegacyContactDetails(legacyFeasibilityRequest, contactPersonDetail, "c");

  const backgroundOrganizationDetails = findContactDetail(
    request,
    "organization"
  );
  addLegacyContactDetails(
    legacyFeasibilityRequest,
    backgroundOrganizationDetails,
    "bg"
  );

  const sampleDetails = request.sampleDetail;
  legacyFeasibilityRequest.material_inclusion_criteria =
    sampleDetails?.materialsInclusionCriteria;
  legacyFeasibilityRequest.material_exclusion_criteria =
    sampleDetails?.materialsExclusionCriteria;
  legacyFeasibilityRequest.genotype_desc = sampleDetails?.genotypeDesc;
  legacyFeasibilityRequest.recruitment_desc = sampleDetails?.recruitmentDesc;
  legacyFeasibilityRequest.other_cell_sample_type =
    sampleDetails?.otherCellSampleType;
  legacyFeasibilityRequest.other_liquid_sample_type =
    sampleDetails?.otherLiquidSampleType;
  legacyFeasibilityRequest.liquid_samples_additional_info =
    sampleDetails?.liquidsAdditionalInfo;
  legacyFeasibilityRequest.sample_volume_description =
    sampleDetails?.liquidsSampleVolume;
  legacyFeasibilityRequest.requirements_for_critical_study =
    sampleDetails?.liquidsCriticalRequirements;
  legacyFeasibilityRequest.liquid_sample_inclusion_criteria_description =
    sampleDetails?.liquidsInclusionCriteria;
  legacyFeasibilityRequest.samples_from_each_donor =
    sampleDetails?.samplesFromEachDonor || [];
  legacyFeasibilityRequest.liquid_sample_exclusion_criteria_description =
    sampleDetails?.liquidsExclusionCriteria;
  legacyFeasibilityRequest.tissue_sample_inclusion_criteria_description =
    sampleDetails?.tissuesInclusionCriteria;
  legacyFeasibilityRequest.tissue_sample_exclusion_criteria_description =
    sampleDetails?.tissuesExclusionCriteria;
  legacyFeasibilityRequest.tissue_sample_additional_info_description =
    sampleDetails?.tissuesAdditionalInfo;
  legacyFeasibilityRequest.phenotype_or_lifestyle_desc =
    sampleDetails?.phenotypeLifestyleDesc;
  legacyFeasibilityRequest.sample_types =
    sampleDetails?.sampleType?.map(sampleTypeToNumber) || [];
  legacyFeasibilityRequest.sequence_data_desc =
    sampleDetails?.sequenceDataDescription;
  legacyFeasibilityRequest.other_sample_type = sampleDetails?.otherSampleType;
  legacyFeasibilityRequest.diseases_of_interest =
    sampleDetails?.diseasesOfInterest;
  legacyFeasibilityRequest.excluded_icd10_codes =
    sampleDetails?.excludedIcd10Codes;
  legacyFeasibilityRequest.pathological_samples_snomed_codes =
    sampleDetails?.pathologicalSamplesSnomedCodes;
  legacyFeasibilityRequest.patient_age_range = sampleDetails?.patientAgeRange;
  legacyFeasibilityRequest.patient_gender =
    sampleDetails?.patientGender?.map(genderToNumber) || [];
  legacyFeasibilityRequest.other_Inclusion_exclusion_criterias =
    sampleDetails?.otherSelectionCriteria;
  legacyFeasibilityRequest.other_sample_type_criteria =
    sampleDetails?.otherSampleTypeCriteria;

  const studyDetails = request.studyDetail;
  legacyFeasibilityRequest.who_collects_data = [
    dataCollectorToNumber(studyDetails?.whoCollectsData)
  ];
  legacyFeasibilityRequest.who_collects_data_desc =
    studyDetails?.whoCollectsDataDescription;
  legacyFeasibilityRequest.sampling_time = studyDetails?.samplingTime;
  legacyFeasibilityRequest.clinical_data_linked =
    studyDetails?.clinicalDataLinked;
  legacyFeasibilityRequest.timelines_for_project =
    studyDetails?.timelinesForProject;
  legacyFeasibilityRequest.budget_timeline_week =
    studyDetails?.budgetTimelineWeek;
  legacyFeasibilityRequest.exist_budget = studyDetails?.existBudget;
  legacyFeasibilityRequest.budget_timeline_year =
    studyDetails?.budgetTimelineYear;
  legacyFeasibilityRequest.budget_description_desc =
    studyDetails?.budgetDescriptionDesc;

  legacyFeasibilityRequest.included_biobanks =
    request.biobanks?.map(biobankNameToNumber) || [];
  legacyFeasibilityRequest.projektin_nimi = request.projectName;
  legacyFeasibilityRequest.targets_of_preanalys = request.biobankTargetReasons;
  legacyFeasibilityRequest.goals_of_the_study = request.studyGoals;
  legacyFeasibilityRequest.feasibilitylevel =
    request.feasibilityLevel?.map(feasibilityLevelToNumber) || [];
  legacyFeasibilityRequest.recall_preanalys_report_type =
    request.recallReportType;
  legacyFeasibilityRequest.preanalys_report_type = request.reportType;

  const attachmentDetail = request.attachmentDetail;
  const filterByFieldName = fieldName => {
    return attachment => attachment.infoType === fieldName;
  };
  const getFileName = attachDetail => ({
    filename: attachDetail.fileName,
    infoType: attachDetail.infoType
  });

  legacyFeasibilityRequest.files =
    attachmentDetail?.filter(filterByFieldName("file"))?.map(getFileName) || [];
  legacyFeasibilityRequest.genotype_files =
    attachmentDetail
      ?.filter(filterByFieldName(FileInfoType.GENOTYPE))
      ?.map(getFileName) || [];
  legacyFeasibilityRequest.phenotype_lifestyle_files =
    attachmentDetail
      ?.filter(filterByFieldName(FileInfoType.PHENOTYPE_OR_LIFESTYLE))
      ?.map(getFileName) || [];
  legacyFeasibilityRequest.recruitment_files =
    attachmentDetail
      ?.filter(filterByFieldName(FileInfoType.RECRUIT))
      ?.map(getFileName) || [];

  const miscellaneousDetail = request.miscellaneousDetail;
  legacyFeasibilityRequest.selected_materials =
    miscellaneousDetail?.selectedMaterials || [];
  legacyFeasibilityRequest.preselected_research_cohort =
    miscellaneousDetail?.preselectedResearchCohort;

  return legacyFeasibilityRequest;
};

export default feasibilityRequestToLegacy;
