export const FETCH_EXPERT_PROFILE_CATEGORIES =
  "FETCH_EXPERT_PROFILE_CATEGORIES";
export const FETCH_EXPERT_PROFILE_CATEGORIES_SUCCESS =
  "FETCH_EXPERT_PROFILE_CATEGORIES_SUCCESS";
export const FETCH_EXPERT_PROFILE_CATEGORIES_FAILURE =
  "FETCH_EXPERT_PROFILE_CATEGORIES_FAILURE";

export const FETCH_COLLABORATION_ORGANISATIONS =
  "FETCH_COLLABORATION_ORGANISATIONS";
export const FETCH_COLLABORATION_ORGANISATIONS_SUCCESS =
  "FETCH_COLLABORATION_ORGANISATIONS_SUCCESS";
export const FETCH_COLLABORATION_ORGANISATIONS_FAILURE =
  "FETCH_COLLABORATION_ORGANISATIONS_FAILURE";
