import {
  FETCH_COLLABORATION_ORGANISATIONS,
  FETCH_COLLABORATION_ORGANISATIONS_FAILURE,
  FETCH_COLLABORATION_ORGANISATIONS_SUCCESS,
  FETCH_EXPERT_PROFILE_CATEGORIES,
  FETCH_EXPERT_PROFILE_CATEGORIES_FAILURE,
  FETCH_EXPERT_PROFILE_CATEGORIES_SUCCESS
} from "../actions/expert-profile-category.actions";

export const defaultState = {
  loading: false,
  collaborationOrganisationsLoading: false,
  error: false,
  therapyAreas: [],
  researchExpertises: [],
  roles: [],
  researchPhases: [],
  icd10Codes: [],
  rareNeurologicalDiseases: [],
  collaborationOrganisations: []
};

export const populateExpertProfileCategories = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case FETCH_EXPERT_PROFILE_CATEGORIES:
      return {
        ...state,
        loading: true,
        error: false
      };
    case FETCH_EXPERT_PROFILE_CATEGORIES_SUCCESS:
      return {
        ...state,
        therapyAreas: action.results.therapyAreas,
        researchExpertises: action.results.researchExpertises,
        roles: action.results.roles,
        researchPhases: action.results.researchPhases,
        icd10Codes: action.results.icd10Codes,
        rareNeurologicalDiseases: action.results.rareNeurologicalDiseases,
        loading: false
      };
    case FETCH_EXPERT_PROFILE_CATEGORIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    case FETCH_COLLABORATION_ORGANISATIONS:
      return {
        ...state,
        collaborationOrganisationsLoading: true,
        error: false
      };
    case FETCH_COLLABORATION_ORGANISATIONS_SUCCESS:
      return {
        ...state,
        collaborationOrganisations: action.results,
        collaborationOrganisationsLoading: false
      };
    case FETCH_COLLABORATION_ORGANISATIONS_FAILURE:
      return {
        ...state,
        collaborationOrganisationsLoading: false,
        error: true
      };
    default:
      return state;
  }
};
