import React from "react";
import NewRequest from "../pages/NewRequest/NewRequest";
import RequestList from "../pages/RequestList/RequestList";

const requestsUrl = "/requests";

const RequestRoutes = [
  {
    path: requestsUrl,
    exact: true,
    private: true,
    element: <RequestList />
  },
  {
    path: `${requestsUrl}/archived`,
    exact: true,
    private: true,
    element: <RequestList tabIndex={1} />
  },
  {
    path: `${requestsUrl}/new`,
    exact: true,
    private: true,
    element: <NewRequest />
  }
];
export default RequestRoutes;
