import { RequestSection } from "../../RequestSection";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import OptionPlaceholder from "../../OptionPlaceholder";
import Col from "react-bootstrap/Col";
import { countryCodeLength } from "../../../helpers/formSchemaHelper";

export const InvoiceDetailsSection = ({
  formProps,
  select: Select,
  formControl: FormControl,
  disabled
}) => {
  const { t } = useTranslation();
  const { values } = formProps;

  return (
    <>
      <RequestSection
        title={t("arForm_invoicing_address")}
        description={t("arForm_invoicing_address_desc")}
      >
        <Form.Group controlId="formInvoicingMethod">
          <Select disabled={disabled} name="invoicing_method">
            <OptionPlaceholder>
              {t("default_dropdown_method_choose")}
            </OptionPlaceholder>
            <option value="10">{t("arForm_invoicing_method_paper")}</option>
            <option value="20">{t("arForm_invoicing_method_email")}</option>
            <option value="30">{t("arForm_invoicing_method_einvoice")}</option>
          </Select>
        </Form.Group>
        {values.invoicing_method && (
          <>
            <Form.Group controlId="formICompany">
              <FormControl
                name={"i_company"}
                label={t("arForm_i_company_label")}
                maxLength={100}
              />
            </Form.Group>
            <Form.Group controlId="formIAddress">
              <FormControl
                name={"i_address"}
                label={t("arForm_i_address_label")}
                maxLength={100}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="formICountryInfo">
                <FormControl
                  name="i_countrycd"
                  label={t("country_code", {
                    countryCodeLength
                  })}
                  length={countryCodeLength}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formIZip">
                <FormControl name="i_zip" label={t("zip")} maxLength={10} />
              </Form.Group>

              <Form.Group as={Col} controlId="formICity">
                <FormControl name="i_city" label={t("city")} maxLength={40} />
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="formIBusinessId">
              <FormControl
                name={"i_businessid"}
                label={t("arForm_i_businessid_label")}
                maxLength={50}
              />
            </Form.Group>
            <Form.Group controlId="formIReference">
              <FormControl
                name={"i_reference"}
                label={t("arForm_i_reference_label")}
                maxLength={200}
              />
            </Form.Group>
          </>
        )}
        {values.invoicing_method === "20" && (
          <>
            <Form.Group controlId="formIEmail">
              <FormControl
                name={"i_email"}
                label={t("arForm_i_email_label")}
                maxLength={100}
              />
            </Form.Group>
          </>
        )}
        {values.invoicing_method === "30" && (
          <>
            <Form.Group controlId="formIInvaddress">
              <FormControl
                name={"i_invaddress"}
                label={t("arForm_i_invaddress_label")}
                maxLength={100}
              />
            </Form.Group>
            <Form.Group controlId="formIOperator">
              <FormControl
                name={"i_operator"}
                label={t("arForm_i_operator_label")}
                maxLength={50}
              />
            </Form.Group>
            <Form.Group controlId="formIBroker">
              <FormControl
                name={"i_broker"}
                label={t("arForm_i_broker_label")}
                maxLength={50}
              />
            </Form.Group>
          </>
        )}
      </RequestSection>
    </>
  );
};
