import { SelectField } from "./SelectField";

const currentYear = new Date().getFullYear();

export const SelectYearField = ({
  fieldName,
  yearsAmount = 10,
  selectedYear
}) => {
  if (selectedYear) {
    selectedYear = new Date(selectedYear).getFullYear();
  }

  // if selected year was before current year, we use starting year as starting year
  const startingYear =
    selectedYear && selectedYear < currentYear ? selectedYear : currentYear;

  // we always show the list until (current year + yearAmount)
  yearsAmount =
    startingYear < currentYear
      ? currentYear - startingYear + yearsAmount
      : yearsAmount;

  const options = [...Array(yearsAmount).keys()].map(
    index => startingYear + index
  );
  return <SelectField name={fieldName} options={options} />;
};
