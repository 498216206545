import React from "react";
import CartClosed from "./CartClosed";
import CartOpen from "./CartOpen";

class Cart extends React.Component {
  render() {
    return (
      <React.Fragment>
        <CartClosed {...this.props} />
        <CartOpen {...this.props} />
      </React.Fragment>
    );
  }
}

export default Cart;
