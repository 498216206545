import styled, { css } from "styled-components";

export const StyledBreadcrumbs = styled.section`
  ${({ theme }) =>
    theme.mixins.Container("ROW", {
      alignItems: "center",
      gap: theme.values.spacing.SMALL
    })}
  ${({ theme }) => theme.objects.Copy.Scale.Small}

  > a {
    ${({ theme }) => theme.objects.Anchor.Base}
  }
`;

export const StyledDataListItem = styled.li`
  ${({ theme }) => theme.objects.ListItem.Base}

  &::marker {
    ${({ theme }) => theme.objects.Copy.Scale.Tiny}
    color: ${({ theme }) => theme.values.colors.text.accent.BASE};
  }
`;

export const StyledDataList = styled.ul`
  ${({ theme }) => theme.objects.List.Base}
  margin: 0;
  padding-inline-start: ${({ theme }) => theme.values.spacing.MEDIUM};
`;

export const StyledSectionHeading = styled.div`
  ${({ theme }) => theme.objects.Heading.Style.Tertiary.Base}
  ${({ theme }) => theme.objects.Heading.Scale.Base}
  ${({ theme }) => theme.mixins.Border("bottom", theme.objects.Border.Subtle)}
`;

export const StyledCollectionName = styled.div`
  ${({ theme }) => theme.objects.Heading.Style.Primary}
  ${({ theme }) => theme.objects.Heading.Scale.Large}
  font-weight: ${({ theme }) => theme.values.fontWeights.BOLD};
`;

export const StyledTwoColumns = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("ROW", {
      gap: theme.values.spacing.LARGE,
      justifyContent: "space-between"
    })}

  > * {
    width: 50%;
  }
`;

export const StyledColumn = styled.div`
  ${({ theme }) => theme.mixins.Container("COLUMN")}
`;

export const StyledSection = styled.section`
  ${({ theme }) => theme.mixins.Container("COLUMN")}
  width: 100%;

  ${({ theme }) =>
    theme.mixins.RespondTo(
      "LAPTOP",
      css`
        ${({ theme }) =>
          theme.mixins.Container("COLUMN", { gap: theme.values.spacing.LARGE })}
      `
    )}
`;

export const StyledMain = styled.main`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", { gap: theme.values.spacing.XLARGE })}
  padding: ${({ theme }) => theme.values.spacing.MEDIUM};

  ${({ theme }) =>
    theme.mixins.RespondTo(
      "LAPTOP",
      css`
        padding: ${({ theme }) => theme.values.spacing.XLARGE};
        padding-top: ${({ theme }) => theme.values.spacing.MEDIUM};
      `
    )}
`;

export const StyledImage = styled.div`
  background-image: url(${({ backgroundSrc }) => backgroundSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ theme }) => theme.values.dimensions.CARD_IMAGE_HEIGHT};
  width: 100%;
`;

export const StyledHeadRole = styled.div`
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
  ${({ theme }) => theme.objects.Copy.Scale.Small}
`;

export const StyledLinkSmall = styled.div`
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
  ${({ theme }) => theme.objects.Copy.Scale.Small}

  a, a:visited {
    color: ${({ theme }) => theme.values.colors.text.accent.BASE};
  }

  a:hover {
    color: ${({ theme }) => theme.values.colors.text.accent.HIGHLIGHT};
  }
`;

export const StyledBoldCopy = styled.div`
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
  font-weight: ${({ theme }) => theme.values.fontWeights.BOLD};
`;

export const StyledCohortInfo = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", { gap: theme.values.spacing.LARGE })}
  padding: ${({ theme }) => theme.values.spacing.LARGE};

  ${({ theme }) =>
    theme.mixins.RespondTo(
      "LAPTOP",
      css`
        padding: ${({ theme }) => theme.values.spacing.LARGE};
      `
    )}
`;

export const StyledAside = styled.aside`
  ${({ theme }) => theme.mixins.Container("COLUMN", { gap: 0 })}
  ${({ theme }) => theme.objects.Background.Default.Subtle}
  flex-shrink: 0;
  width: ${({ theme }) => theme.values.dimensions.CARD_WIDTH};
`;

export const StyledCohortCollectionPage = styled.div`
  ${({ theme }) => theme.mixins.Container("COLUMN", { gap: 0 })}
  ${({ theme }) => theme.objects.Border.Subtle}
  border-radius: ${({ theme }) => theme.values.radii.SMALL};
  height: fit-content;
  max-width: ${({ theme }) => theme.values.dimensions.MAIN_CONTENT_MAX_WIDTH};

  ${({ theme }) =>
    theme.mixins.RespondTo(
      "LAPTOP",
      css`
        ${({ theme }) => theme.mixins.Container("ROW", { gap: 0 })}
      `
    )}
`;
