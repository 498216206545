import EditProfilePage from "../pages/EditProfilePage";
import LoginPage from "../pages/LoginPage/LoginPage";
import LogoutPage from "../pages/LogoutPage";
import { ThemeProvider } from "styled-components";
import { themes } from "@finbb/ui-components";

const routes = [
  { path: "/user/login", private: false, exact: true, element: <LoginPage /> },
  { path: "/user/logout", private: true, exact: true, element: <LogoutPage /> },
  {
    path: "/user/edit-profile",
    private: true,
    exact: true,
    element: (
      <ThemeProvider theme={themes.light}>
        {" "}
        <EditProfilePage />
      </ThemeProvider>
    )
  }
];

export default routes;
