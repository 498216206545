import { useTranslation } from "react-i18next";
import { RequestSection } from "../../RequestSection";
import React from "react";
import { UploadArea } from "../UploadFiles";
import { FileInfoType } from "../../../helpers/attachmentHelper";

export const MandatoryDocumentsSection = ({
  formProps,
  id,
  disabled,
  label: Label
}) => {
  const { t } = useTranslation();
  const {
    values: { mandatory_files, permits_files, filesFromFeasibility }
  } = formProps;
  const mandatoryFilesFromFeasibility = filesFromFeasibility
    ? filesFromFeasibility.filter(
        f => f.infoType === FileInfoType.MANDATORY_FILES
      )
    : [];

  const permitsFilesFromFeasibility = filesFromFeasibility
    ? filesFromFeasibility.filter(f => f.infoType === FileInfoType.PERMITS)
    : [];

  return (
    <>
      <RequestSection
        title={t("arForm_mandatory_documents")}
        description={t("mandatory_documents_desc")}
      >
        <ul className={"margin-top-sm"}>
          <li>{t("arForm_mandatory_documents_study_protocol")}</li>
          <li>{t("arForm_mandatory_documents_principle_investigator_cv")}</li>
          <li>
            {t("mandatory_documents_data_processing_assessment")}&nbsp;
            <a
              href="/Fingenious%20privacy%20dataregistry%20and%20DPIA%20form%20221013.docx"
              download={true}
            >
              {t("download")}
            </a>
          </li>
          <li>{t("ethics_committee_statement")}</li>
        </ul>

        {!disabled && (
          <UploadArea
            requestId={id}
            fieldName={"mandatory_files"}
            initialFiles={[
              ...mandatoryFilesFromFeasibility,
              ...mandatory_files
            ]}
            formProps={formProps}
            disabled={disabled}
          />
        )}

        <br />
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"label_attach_permits_or_registry"}
        >
          {t("attach_permits_or_registry")}
        </Label>
        <UploadArea
          requestId={id}
          fieldName={"permits_files"}
          initialFiles={[...permitsFilesFromFeasibility, ...permits_files]}
          formProps={formProps}
          disabled={disabled}
        />
      </RequestSection>
    </>
  );
};
