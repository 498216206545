import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./request-list.module.scss";
import Title from "../../components/Title/Title";
import { Link } from "react-router-dom";
import {
  getAccessRequestEditUrl,
  getAccessRequestStatusUrl,
  getActiveRequestsUrl,
  getArchivedRequestsUrl,
  getFeasibilityRequestEditUrl,
  getFeasibilityRequestStatusUrl
} from "../../helpers/linkGenerator";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import useFetchRequests from "../../hooks/useFetchRequests";
import ListItem from "./components/ListItem";
import { isAccessRequest, isFeasibilityRequest } from "./requestListHelper";
import EmptyListPlaceholder from "./EmptyListPlaceholder";
import LoadingWrapper from "../../components/LoadingWrapper";
import { useDispatch } from "react-redux";
import { toggleFeasibilityRequestArchivedStatus } from "../../actions/feasiblity_request";
import { toggleAccessRequestArchivedStatus } from "../../actions/access_request";
import {
  inverseArchived,
  isAccessRequestReturnedForCompletion,
  isArchived,
  isFeasibilityRequestReturnedForCompletion,
  isNewAccessRequest,
  isNewFeasibilityRequest
} from "../../helpers/requestHelper";
import { booleanStringAsBoolean } from "../../helpers/utils";
import { replace } from "redux-first-history";
import RequestListLoadingIndicator from "../../components/RequestListLoadingIndicator";
import VacationNotification from "../../components/VacationNotification/VacationNotification";

const getRequestId = request =>
  isFeasibilityRequest(request) ? request.preanalysisid : request.requestid;

const getRequestStatusUrl = request => {
  const requestId = getRequestId(request);

  return isFeasibilityRequest(request)
    ? getFeasibilityRequestStatusUrl(requestId)
    : getAccessRequestStatusUrl(requestId);
};

const getUrl = request => {
  if (
    isFeasibilityRequest(request) &&
    (isNewFeasibilityRequest(request) ||
      isFeasibilityRequestReturnedForCompletion(request))
  ) {
    return getFeasibilityRequestEditUrl(getRequestId(request));
  } else if (
    isAccessRequest(request) &&
    (isNewAccessRequest(request) ||
      isAccessRequestReturnedForCompletion(request))
  ) {
    return getAccessRequestEditUrl(getRequestId(request));
  } else {
    return getRequestStatusUrl(request);
  }
};

const ListItemContainer = ({ request }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Link className={styles.listItemLink} to={getUrl(request)}>
      <ListItem
        type={request.type}
        title={
          request.projektin_nimi ||
          t(
            isFeasibilityRequest(request)
              ? "untitled_feasibility_request"
              : "untitled_access_request"
          )
        }
        createdTimestamp={request.createdate}
        updatedTimestamp={request.changedate}
        status={request.state}
        projectId={request.project_number}
        requestId={getRequestId(request)}
        options={[
          {
            label: t(!isArchived(request) ? "archive" : "unarchive"),
            onClick: e => {
              const toggleRequestArchivedStatus = isFeasibilityRequest(request)
                ? toggleFeasibilityRequestArchivedStatus
                : toggleAccessRequestArchivedStatus;

              dispatch(
                toggleRequestArchivedStatus(
                  getRequestId(request),
                  booleanStringAsBoolean(inverseArchived(request))
                )
              );

              e.preventDefault();
            }
          }
        ]}
      />
    </Link>
  );
};

const Requests = ({ requests, emptyPlaceholder }) => {
  return requests && requests.length > 0 ? (
    requests.map(request => (
      <ListItemContainer
        key={`${request.type}${getRequestId(request)}`}
        request={request}
      />
    ))
  ) : (
    <EmptyListPlaceholder title={emptyPlaceholder} />
  );
};

const RequestList = ({ tabIndex = 0 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    requests: activeRequests,
    loading: activeRequestsLoading,
    error: activeRequestsError
  } = useFetchRequests();
  const {
    requests: archivedRequests,
    loading: archivedRequestsLoading,
    error: archivedRequestsError
  } = useFetchRequests(true);

  return (
    <>
      <div className={"finbb-container"}>
        <VacationNotification />
      </div>
      <Title
        rightChildren={
          <Link to={"/requests/new"}>
            <button type="button" className={"button"}>
              + {t("new_request")}
            </button>
          </Link>
        }
      >
        {t("requests")}
      </Title>
      <Tabs
        selectedTabClassName={styles.tabSelected}
        defaultIndex={tabIndex}
        onSelect={tabIndex =>
          dispatch(
            replace(
              tabIndex === 1 ? getArchivedRequestsUrl() : getActiveRequestsUrl()
            )
          )
        }
      >
        <div className={"finbb-container"}>
          <TabList className={styles.tabList}>
            <Tab className={styles.tab}>{t("active")}</Tab>
            <Tab className={styles.tab}>{t("archived")}</Tab>
          </TabList>
        </div>
        <div className={styles.horizontalLineBelowTabList} />
        <div className={"finbb-container"}>
          <TabPanel className={styles.tabPanel}>
            <LoadingWrapper
              isLoading={activeRequestsLoading}
              isInErrorState={activeRequestsError != null}
              errorMsg={t("active_requests_loading_error")}
              loadingComponent={<RequestListLoadingIndicator />}
            >
              <Requests
                requests={activeRequests}
                emptyPlaceholder={t("you_have_no_active_requests")}
              />{" "}
            </LoadingWrapper>
          </TabPanel>
          <TabPanel>
            <LoadingWrapper
              isLoading={archivedRequestsLoading}
              isInErrorState={archivedRequestsError !== null}
              errorMsg={t("archived_requests_loading_error")}
              loadingComponent={<RequestListLoadingIndicator />}
            >
              <Requests
                requests={archivedRequests}
                emptyPlaceholder={t("you_have_no_archived_requests")}
              />
            </LoadingWrapper>
          </TabPanel>
        </div>
      </Tabs>
    </>
  );
};

export default RequestList;
