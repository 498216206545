import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { RequestSection } from "../RequestSection";
import React from "react";
import { UploadArea } from "../AccessRequest/UploadFiles";
import { FileInfoType } from "../../helpers/attachmentHelper";

export const RecruitSection = ({
  formProps,
  formControl: FormControl,
  label: Label,
  disabled,
  id
}) => {
  const { t } = useTranslation();
  const {
    values: { selected_materials, recruitment_files, filesFromFeasibility }
  } = formProps;
  const recruitFilesFromFeasibility = filesFromFeasibility
    ? filesFromFeasibility.filter(f => f.infoType === FileInfoType.RECRUIT)
    : [];

  const isEnabled = selected_materials.includes("sample_donor_recruit");

  return (
    isEnabled && (
      <RequestSection
        title={t("recruit")}
        description={t("recruit_description")}
      >
        <FormControl
          as={"textarea"}
          name={"recruitment_desc"}
          label={t("recruitment_label")}
        />
        <Form.Group>
          <Label formProps={formProps} fieldName={"attachements"}>
            <h5>{t("attachments")}</h5>
          </Label>
          <UploadArea
            requestId={id}
            fieldName={"recruitment_files"}
            initialFiles={[
              ...recruitFilesFromFeasibility,
              ...recruitment_files
            ]}
            formProps={formProps}
            disabled={disabled}
          />
        </Form.Group>
      </RequestSection>
    )
  );
};
