import { useField } from "formik";
import Form from "react-bootstrap/Form";
import React, { useMemo } from "react";
import { isRequiredField, existsInSchema } from "../helpers/formHelper";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const CustomFormControl = ({
  label,
  validationSchema,
  finalValidationSchema = null,
  enableValidation = true,
  ...props
}) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { touched, error } = meta;

  const schemaForRequiredLabel =
    finalValidationSchema !== null ? finalValidationSchema : validationSchema;

  // The required label should always be shown on the form,
  // so we check that from the final validation schema
  const showRequiredLabel = useMemo(
    () => isRequiredField(schemaForRequiredLabel, props.name),
    [schemaForRequiredLabel, props.name]
  );

  // Whether the we want to show the error message depends on the active schema
  // e.g. did the user click "Save and continue later" or "Submit"
  const isRequiredHtml5 = useMemo(
    () => isRequiredField(validationSchema, props.name),
    [validationSchema, props.name]
  );

  const itExistsInSchema = useMemo(
    () => existsInSchema(validationSchema, props.name),
    [validationSchema, props.name]
  );

  return (
    <>
      {label && (
        <Form.Label>
          {label} {showRequiredLabel ? "*" : ""}
        </Form.Label>
      )}
      {!props.empty && (
        <Form.Control
          {...field}
          {...props}
          isInvalid={enableValidation && itExistsInSchema && touched && error}
          isValid={enableValidation && itExistsInSchema && touched && !error}
          required={isRequiredHtml5}
        />
      )}
      {touched && error && (
        <Form.Control.Feedback type="invalid">
          {t(error.key, error.values)}
        </Form.Control.Feedback>
      )}
    </>
  );
};

CustomFormControl.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default CustomFormControl;
