import styled from "styled-components";

export const StyledMiabisCategoryIcon = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("ROW", {
      alignItems: "center",
      justifyContent: "center"
    })}
  background-color: ${({ isColorful, theme }) =>
    isColorful && theme.values.colors.brand.BASE};
  border-radius: ${({ isColorful }) => isColorful && "100%"};
  height: ${({ isLarge, theme }) => isLarge && theme.values.iconSizes.XLARGE};
  width: ${({ isLarge, theme }) => isLarge && theme.values.iconSizes.XLARGE};
`;
