import { connect } from "react-redux";
import ViewPage from "./ViewPage";
import { loadViewPage, unloadViewPage } from "../actions/pages";

function mapDispatchToProps(dispatch) {
  return {
    onLoad() {
      dispatch(loadViewPage());
    },
    onUnload() {
      dispatch(unloadViewPage());
    }
  };
}

const ViewPageContainer = connect(null, mapDispatchToProps)(ViewPage);
export default ViewPageContainer;
