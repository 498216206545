import { createContext } from "react";

const defaultContext = {
  isAfterLogin: false,
  setIsAfterLogin: () => {}
};

const AfterLoginContext = createContext(defaultContext);

export const AfterLoginProvider = AfterLoginContext.Provider;
export default AfterLoginContext;
