// source: https://github.com/jaredpalmer/formik/issues/602
import { Field } from "formik";
import Form from "react-bootstrap/Form";
import React from "react";
import { isRequiredField } from "../helpers/formHelper";
import PropTypes from "prop-types";

const isEmptyArray = value => Array.isArray(value) && value.length === 0;
const isTrue = value => {
  if (["T", "F"].includes(value)) {
    return value === "T" ? true : false;
  }
  return Boolean(value);
};

const Checkbox = ({ validationSchema, forceChecked, ...props }) => {
  const { name } = props;

  return (
    <Field name={name}>
      {({ field, form }) => (
        <Form.Check
          id={`${name}${props.value}`}
          type="checkbox"
          {...props}
          checked={
            forceChecked ||
            (Array.isArray(field.value)
              ? field.value.includes(props.value)
              : isTrue(field.value))
          }
          onChange={() => {
            if (
              Array.isArray(field.value) &&
              field.value.includes(props.value)
            ) {
              const nextValue = field.value.filter(
                value => value !== props.value
              );
              form.setFieldValue(name, nextValue);
            } else if (Array.isArray(field.value)) {
              const nextValue = field.value.concat(props.value);
              form.setFieldValue(name, nextValue);
            } else if (field.value === props.value) {
              form.setFieldValue(name, "");
            } else {
              form.setFieldValue(name, props.value);
            }
          }}
          required={
            isEmptyArray(field.value)
              ? isRequiredField(validationSchema, name)
              : !field.value && isRequiredField(validationSchema, name)
          }
        />
      )}
    </Field>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default Checkbox;
