import React from "react";
import Autosuggest from "@deanc/react-autosuggest";
import { withTranslation } from "react-i18next";
import classNames from "classnames";
import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";

const renderSuggestion = (suggestion, { query }) => {
  return renderCodeOrDirectoryLink(suggestion, query, suggestion.depth);
};

const renderCodeOrDirectoryLink = (suggestion, query, depth = 0) => {
  const classes = classNames({
    "search-result": true,
    [`depth-${depth}`]: true
  });

  if ("is_group" in suggestion && suggestion["is_group"] === true) {
    return (
      <div key={suggestion.id} className={classes}>
        <span className="link">
          {suggestionDisplay(suggestion, query, depth)}
        </span>
      </div>
    );
  }

  return (
    <div key={suggestion.id} className={classes}>
      <span className="link">
        {suggestionDisplay(suggestion, query, depth)}
      </span>
    </div>
  );
};

const suggestionDisplay = (suggestion, query) => {
  const suggestionText = suggestion.code + " - " + suggestion.title;
  const matches = AutosuggestHighlightMatch(suggestionText, query);
  const parts = AutosuggestHighlightParse(suggestionText, matches);

  const renderedParts = parts.map((part, index) => {
    const className = part.highlight ? "highlight" : null;

    return (
      <span className={className} key={index}>
        {part.text}
      </span>
    );
  });
  return <div>{renderedParts}</div>;
};

const shouldRenderSuggestions = value => value.trim().length > 2;

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion =>
  suggestion.code + " - " + suggestion.title;

const flattenSuggestions = (suggestions, flattened = [], currentDepth = 0) => {
  if (suggestions && suggestions.length) {
    suggestions.forEach(s => {
      flattened.push({ ...s, depth: currentDepth });
      if (s.children.length) {
        flattenSuggestions(s.children, flattened, currentDepth + 1);
      }
    });
  }

  return flattened;
};

class SearchBox extends React.Component {
  render() {
    const {
      value,
      suggestions,
      onChange,
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      onSuggestionSelected,
      t
    } = this.props;
    const inputProps = {
      placeholder: t("searchbox_placeholder"),
      value,
      onChange
    };

    const flattenedSuggestions = flattenSuggestions(suggestions);

    return (
      <div className="search">
        <div className="search-container">
          <Autosuggest
            suggestions={flattenedSuggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            shouldRenderSuggestions={shouldRenderSuggestions}
            //enableEnsureHighlightedItemIsVisible={false}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            ensure
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(SearchBox);
