import {
  USER_CHANGE_PASSWORD,
  USER_CHANGE_PASSWORD_FAILURE,
  USER_CHANGE_PASSWORD_SUCCESSFUL,
  USER_LOGIN,
  USER_LOGIN_FAILURE,
  USER_LOGIN_SUCCESSFUL,
  USER_REQUEST_PASSWORD_RESET_FAILURE,
  USER_REQUEST_PASSWORD_RESET_SUCCESSFUL,
  USER_RESET_FORM_STATE,
  USER_UPDATE_PROFILE,
  USER_UPDATE_PROFILE_FAILURE,
  USER_UPDATE_PROFILE_SUCCESSFUL,
  USER_UPSERT_EXPERT_PROFILE_FAILURE,
  USER_UPSERT_EXPERT_PROFILE_SUCCESSFUL
} from "../actions/users.actions";

export const initialState = {
  login: {
    errorCode: null,
    errorMessage: null,
    success: false
  },
  requestPasswordReset: {
    errorCode: null,
    errorMessage: null,
    success: false
  },
  changePassword: {
    errorCode: null,
    errorMessage: null,
    success: false
  },
  updateProfile: {
    errorCode: null,
    errorMessage: null,
    success: false
  },
  upsertExpertProfile: {
    errorCode: null,
    errorMessage: null,
    success: false
  }
};

const appendToState = (key, errorCode, errorMessage, success) => ({
  ...initialState,
  [key]: {
    errorCode,
    errorMessage,
    success
  }
});

const reduceSuccess = key => appendToState(key, null, null, true);

const reduceFailure = (key, action) =>
  appendToState(key, action.code, action.message, false);

export const formsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
    case USER_UPDATE_PROFILE:
    case USER_CHANGE_PASSWORD:
      return initialState;
    case USER_LOGIN_FAILURE:
      return reduceFailure("login", action);
    case USER_LOGIN_SUCCESSFUL:
      return reduceSuccess("login");
    case USER_UPDATE_PROFILE_FAILURE:
      return reduceFailure("updateProfile", action);
    case USER_UPDATE_PROFILE_SUCCESSFUL:
      return reduceSuccess("updateProfile");
    case USER_UPSERT_EXPERT_PROFILE_FAILURE:
      return reduceFailure("upsertExpertProfile", action);
    case USER_UPSERT_EXPERT_PROFILE_SUCCESSFUL:
      return reduceSuccess("upsertExpertProfile");
    case USER_REQUEST_PASSWORD_RESET_SUCCESSFUL:
      return reduceSuccess("requestPasswordReset");
    case USER_REQUEST_PASSWORD_RESET_FAILURE:
      return reduceFailure("requestPasswordReset", action);
    case USER_CHANGE_PASSWORD_SUCCESSFUL:
      return reduceSuccess("changePassword");
    case USER_CHANGE_PASSWORD_FAILURE:
      return reduceFailure("changePassword", action);
    case USER_RESET_FORM_STATE:
      return initialState;
    default:
      return state;
  }
};
