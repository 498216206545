import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { RequestSection } from "../../RequestSection";
import { specimenTypeMapping } from "../../../helpers/feasibilityRequestMapper";
import { ICD10CodeLink } from "../../ICD10CodeLink";
import { SELECTED_MATERIALS } from "../../../common/selectedMaterials";

export const TissueSamplesSection = ({
  formProps,
  feasibilityRequestLabel: FeasibilityRequestLabel,
  feasibilityRequestCheckBox: FeasibilityRequestCheckBox,
  feasibilityRequestFormControl: FeasibilityRequestFormControl
}) => {
  const { t } = useTranslation();
  const { values } = formProps;
  const isEnabled =
    values.selected_materials.includes(SELECTED_MATERIALS.SAMPLE) &&
    values.selected_materials.includes(SELECTED_MATERIALS.TISSUE);

  return (
    isEnabled && (
      <RequestSection
        title={t("tissue_samples")}
        description={t("tissue_samples_description")}
      >
        <Form.Group>
          <FeasibilityRequestCheckBox
            label={t("ffpe_tissue")}
            name={"sample_types"}
            value={specimenTypeMapping.ffpe_tissue}
          />
          <FeasibilityRequestCheckBox
            label={t("tma_slides_block")}
            name={"sample_types"}
            value={specimenTypeMapping.tma_slides}
          />
          <FeasibilityRequestCheckBox
            label={t("fresh_frozen_tissue")}
            name={"sample_types"}
            value={specimenTypeMapping.fresh_frozen_tissue}
          />
          <FeasibilityRequestCheckBox
            label={t("digitized_scanned_slides")}
            name={"sample_types"}
            value={specimenTypeMapping.digitized_scanned_slides}
          />
        </Form.Group>
        <Form.Group>
          <FeasibilityRequestLabel
            withErrorIndicator
            formProps={formProps}
            fieldName={"inclusion_criteria_for_tissue_samples"}
          >
            <h5>{t("inclusion_criteria")}</h5>
            <Trans
              i18nKey="tissue_sample_inclusion_criteria_label"
              components={{
                link: <ICD10CodeLink />
              }}
            />
          </FeasibilityRequestLabel>
          <FeasibilityRequestFormControl
            as={"textarea"}
            name={"tissue_sample_inclusion_criteria_description"}
            placeholder={t("tissue_sample_inclusion_criteria_eg")}
          />
        </Form.Group>
        <Form.Group>
          <FeasibilityRequestLabel
            withErrorIndicator
            formProps={formProps}
            fieldName={"exclusion_criteria_for_tissue_samples"}
          >
            <h5>{t("exclusion_criteria")}</h5>
          </FeasibilityRequestLabel>
          <FeasibilityRequestFormControl
            as={"textarea"}
            name={"tissue_sample_exclusion_criteria_description"}
            label={t("tissue_sample_exclusion_criteria_label")}
            placeholder={t("tissue_sample_exclusion_criteria_eg")}
          />
        </Form.Group>
        <Form.Group>
          <FeasibilityRequestLabel
            withErrorIndicator
            formProps={formProps}
            fieldName={"tissue_sample_additional_info"}
          >
            <h5>{t("tissue_sample_additional_info")}</h5>
          </FeasibilityRequestLabel>
          <FeasibilityRequestFormControl
            as={"textarea"}
            name={"tissue_sample_additional_info_description"}
            label={t("tissue_sample_additional_info_label")}
            placeholder={t("tissue_sample_additional_info_eg")}
          />
        </Form.Group>
      </RequestSection>
    )
  );
};
