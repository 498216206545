import * as Yup from "yup";

export const requiredText = Yup.string()
  .min(2)
  .required();

export const requiredTextFn = maxLength =>
  Yup.string()
    .max(maxLength)
    .required();

export const optionalText = (maxLength = 50) => Yup.string().max(maxLength);

export const requiredArray = Yup.array().required();

export const getContactInfoFields = (contactInfoFields, prefix) =>
  Object.entries(contactInfoFields).reduce((result, [key, value]) => {
    result[`${prefix}${key}`] = value;
    return result;
  }, {});
