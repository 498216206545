import axios from "./axios";
import i18next from "i18next";
import { logError } from "./logger";

export const handleError = (error, errorMsgPrefix = "Error") => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    alert(`${errorMsgPrefix}: ${JSON.stringify(error.response)}`);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    alert(`${errorMsgPrefix}: ${JSON.stringify(error.request)}`);
  } else {
    // Something happened in setting up the request that triggered an Error
    alert(`${errorMsgPrefix}: ${error.message}`);
  }
  logError(error);
};

export const toFormData = (values, fieldsToNotFlatten = []) => {
  const formData = new FormData();

  Object.keys(values).forEach(key => {
    const value = values[key] || "";

    if (Array.isArray(value)) {
      if (value[0] instanceof File) {
        value.forEach(arrValue => {
          formData.append(`${key}`, arrValue);
        });
      } else if (fieldsToNotFlatten.includes(key)) {
        value.forEach(arrValue => {
          formData.append(`${key}[]`, JSON.stringify(arrValue));
        });
      } else {
        formData.append(key, value.join(" "));
      }
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const postMultipartFormData = async (url, data, fieldsToNotFlatten) =>
  axios.post(url, toFormData(data, fieldsToNotFlatten), {
    headers: {
      "content-type": `multipart/form-data`
    }
  });

// toError is usually called with AxiosError-object when request fails.
// We parse the message from it, and if we don't find proper message,
// return a generic one.
export const toError = errorObj => {
  const error = {
    error: i18next.t("unknown_error")
  };

  if (errorObj.response?.data?.message) {
    error.error = errorObj.response.data.message;
  } else if (errorObj.message) {
    // If it's a generic error, it has message property
    error.error = errorObj.message;
  }

  return error;
};
