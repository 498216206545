import React from "react";
import { useTranslation } from "react-i18next";
import { CATEGORY_COHORT } from "../actions/category-types";

const CartSampleButton = ({ enabled, category, onAdd, onRemove }) => {
  const { t } = useTranslation();

  const EnabledCatalogButton = () => (
    <button className={"cart-button add-sample"} href="#" onClick={onAdd}>
      {t("select_these_samples")}
    </button>
  );
  const DisabledCatalogButton = () => (
    <button className={"cart-button remove-sample"} href="#" onClick={onRemove}>
      {t("remove_these_samples")}
    </button>
  );

  const EnabledCohortButton = () => (
    <button className={"button big"} href="#" onClick={onAdd}>
      {t("select_cohort")}
    </button>
  );
  const DisabledCohortButton = () => (
    <button className={"button big"} href="#" onClick={onRemove}>
      {t("unselect_cohort")}
    </button>
  );

  return enabled ? (
    category === CATEGORY_COHORT ? (
      <EnabledCohortButton />
    ) : (
      <EnabledCatalogButton />
    )
  ) : category === CATEGORY_COHORT ? (
    <DisabledCohortButton />
  ) : (
    <DisabledCatalogButton />
  );
};

export default CartSampleButton;
