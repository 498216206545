import React from "react";
import CartItem from "./CartItem";
import { useTranslation } from "react-i18next";
import hash from "object-hash";
import noItemsImage from "../assets/img/no_sample_data.svg";
import { logStartFeasibilityRequest } from "../helpers/amplitude";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { getUrlForNewFeasibilityRequest } from "../helpers/linkGenerator";

const CartOpen = ({ isOpen, items, onToggle, onEmpty, feasabilityLink }) => {
  const { t } = useTranslation();

  const onStartFeasibilityRequest = () => {
    // if the cart is not closed then scrolling
    // is not possible in the feasibility request form
    onToggle();
    logStartFeasibilityRequest(items);
  };

  let cartItems = (
    <div className="finbb-container">
      <div className="no-items">
        <img src={noItemsImage} alt="" />
        <p>{t("cart_empty_msg")}</p>
      </div>
    </div>
  );

  const cartClasses = classNames({
    open: isOpen,
    "cart-open": true
  });

  let startButton = null;
  let clearCartButton = null;
  if (items && items.length) {
    cartItems = items.map((item, idx) => (
      <CartItem key={hash(item)} item={item} itemIndex={idx} />
    ));
    startButton = (
      <Link
        className={"link"}
        target={"_blank"}
        to={{
          pathname: getUrlForNewFeasibilityRequest(),
          search: "from_catalog=true"
        }}
        onClick={onStartFeasibilityRequest}
      >
        <button className="finbb-btn start-request">
          {t("start_feasibility_request")}
        </button>
      </Link>
    );

    clearCartButton = (
      <button onClick={onEmpty} className="finbb-btn transparent clear-cart">
        {t("Clear all samples")}
      </button>
    );
  }

  const samplesSelectedTranslation = {
    __html: t("search_samples_selected", { total: items.length })
  };

  return (
    <div className={cartClasses}>
      <div className="full-width">
        <div className="finbb-container cart-bar">
          <button className="transparent close-cart" onClick={onToggle}>
            {t("close")}
          </button>
          <p
            className="light spaced"
            dangerouslySetInnerHTML={samplesSelectedTranslation}
          ></p>
        </div>
      </div>

      <div className="finbb-container">
        <p className="intro">{t("cart_intro")}</p>
      </div>

      <div className="cart-items-holder">
        {cartItems}
        <div className="bottom-row finbb-container">
          {clearCartButton}
          {startButton}
        </div>
      </div>
    </div>
  );
};

export default CartOpen;
