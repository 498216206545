import styled from "styled-components";
import { Card, Popover } from "react-bootstrap";

export const StyledPopover = styled(Popover)`
  display: flex;
  flex-direction: column;
  background-color: #dddddd;
  color: #143386;
  max-width: 20rem;
  padding: 1rem;
  border-radius: 10px;
  cursor: default;
  margin-top: 0.5%;
  margin-left: 1%;
`;

export const StyledPopoverCloseButton = styled.span`
  position: fixed;
  right: 15px;
  top: 5px;
  cursor: pointer;
`;

export const StyledPopoverContent = styled(Popover.Content)`
  padding: 0.1rem;
`;

export const StyledCardActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyleCardLinkPrimary = styled(Card.Link)`
  text-decoration: none;
  font-weight: bold;
`;

export const StyleCardLinkSecondary = styled(Card.Link)`
  text-decoration: none;
  color: grey;
  font-weight: bold;
`;
