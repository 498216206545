import { useEffect, useState } from "react";
import axios from "../helpers/axios";
import { toError } from "../helpers/fetchHelper";
import { logError } from "../helpers/logger";

const initialState = null;

const useFetch = (url, skip) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(initialState);

  useEffect(() => {
    if (!url) {
      setLoading(false);
      return;
    }
    const fetchRequest = async () => {
      setLoading(true);
      try {
        const response = await axios.get(url);

        if (response.status === 200) {
          setData(response.data.data || response.data);
        } else {
          setData(initialState);
          setError({
            error: response.data.message
          });
        }
      } catch (e) {
        logError("Failure with http request: " + JSON.stringify(e));
        setData(initialState);
        setError(toError(e));
      } finally {
        setLoading(false);
      }
    };

    if (!skip) {
      fetchRequest();
    } else {
      setLoading(false);
    }

    return () => {};
  }, [url, skip]);

  return {
    data,
    loading,
    error
  };
};

export default useFetch;
