import InfoBlock from "../../components/InfoBlock/InfoBlock";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import iconAccessRequest from "./iconAccessRequest.svg";
import { getCatalogUrl } from "../../helpers/linkGenerator";

const EmptyListPlaceholder = ({ title }) => {
  const { t } = useTranslation();
  return (
    <InfoBlock
      title={title}
      iconSrc={iconAccessRequest}
      description={t("no_requests_placeholder")}
      button={
        <Link to={getCatalogUrl()}>
          <button className={"button"}>{t("browse_icd10_catalogue")}</button>
        </Link>
      }
    />
  );
};

export default EmptyListPlaceholder;
