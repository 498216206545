import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import noCardsImage from "../assets/img/no_sample_data.svg";
import Card from "./Card";
import FiltersContainer from "./FiltersContainer";

const SampleView = ({ currentSample }) => {
  const { t } = useTranslation();

  // to toggle body class on mount and remove it on unmount
  useEffect(() => {
    document.body.classList.add("sample-view");
    return () => {
      document.body.classList.remove("sample-view");
    };
  }, []);

  const paddingCardsNeeded =
    currentSample && currentSample.results.length
      ? 3 % currentSample.results.length
      : 0;
  const paddingCards = [];
  if (paddingCardsNeeded > 0) {
    for (let i = 0; i < paddingCardsNeeded; i++) {
      paddingCards.push(
        <div key={"fake-card-" + i} className="card fake"></div>
      );
    }
  }

  let cardsHolder = null;
  if (currentSample) {
    if (currentSample.results.length) {
      const cards = currentSample.results.map(card => (
        <Card
          key={card.sample_type}
          code={currentSample.code}
          title={card.sample_type}
          quantity={card.quantity}
          distributions={card.distributions}
        />
      ));
      cardsHolder = (
        <div>
          <h2>{t("sample_view_title")}</h2>
          <p className="desc">{t("sample_view_desc")}</p>
          <div className="cards">
            {cards}
            {paddingCards}
          </div>
        </div>
      );
    } else {
      cardsHolder = (
        <div className="no-results">
          <img src={noCardsImage} alt="" />
          <p>{t("no_sample_data")}</p>
        </div>
      );
    }
  }

  const filters = <FiltersContainer />;

  return (
    <div className="sample-view">
      {currentSample && (
        <div className="sample-view-content">
          {filters}
          <div className="actual-view">
            <div className="finbb-container">{cardsHolder}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SampleView;
