import { Button, LoadingIndicator, SeparatorLine } from "@finbb/ui-components";
import { IconFilter, IconFilterOff, IconSearch } from "@tabler/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { fetchCohortCollections } from "../../actions/cohort-collections";
import { fetchMiabisCollectionTypes } from "../../actions/miabis-collection-types";
import { fetchMiabisDataCategories } from "../../actions/miabis-data-categories";
import { fetchMiabisMaterialTypes } from "../../actions/miabis-material-types";
import CohortCollectionSearchList from "../../components/CohortCollectionSearchPage/CohortCollectionSearchList";
import MiabisFilterList from "../../components/CohortCollectionSearchPage/MiabisFilterList";
import { filterSelectedMiabisEntities } from "../../helpers/cohort-collection-util";
import { sortMiabisEntitiesAlphabetically } from "../../helpers/field-translate-util";
import { StyledFetchError } from "../CohortCollectionListPage/CohortCollectionListPage.styles";
import {
  StyledCenteredColumn,
  StyledCohortCollectionSearchPage,
  StyledFilterButtonContainer,
  StyledFilterContainer,
  StyledSearchContainer,
  StyledSearchInput
} from "./CohortCollectionSearchPage.styles";
import {
  CATEGORIES_KEY,
  COLLECTION_TYPES_KEY,
  FILTER_VISIBLE_KEY,
  GENDERS_KEY,
  getItemFromStorage,
  MATERIAL_TYPES_KEY,
  SEARCH_KEY,
  types
} from "../../helpers/storageHelper";

const CohortCollectionSearchPage = ({
  cohortCollectionState,
  fetchCohortCollections,
  fetchMiabisCollectionTypes,
  fetchMiabisDataCategories,
  fetchMiabisMaterialTypes,
  miabisCollectionTypesState,
  miabisDataCategoriesState,
  miabisMaterialTypesState
}) => {
  const params = useParams();
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const [miabisDataCategories, setMiabisDataCategories] = useState([]);
  const [miabisCollectionTypes, setMiabisCollectionTypes] = useState([]);
  const [miabisMaterialTypes, setMiabisMaterialTypes] = useState([]);
  const [miabisGenders, setMiabisGenders] = useState([]);
  const [searchValue, setSearchValue] = useState(
    getItemFromStorage(SEARCH_KEY, types.string)
  );
  const [areFiltersVisible, setFiltersVisible] = useState(
    getItemFromStorage(FILTER_VISIBLE_KEY, types.boolean)
  );

  const updateMiabisDataCategories = categories => {
    setMiabisDataCategories(categories);
    sessionStorage.setItem(CATEGORIES_KEY, JSON.stringify(categories));
  };

  const updateMiabisCollectionTypes = collectionTypes => {
    setMiabisCollectionTypes(collectionTypes);
    sessionStorage.setItem(
      COLLECTION_TYPES_KEY,
      JSON.stringify(collectionTypes)
    );
  };

  const updateMiabisMaterialTypes = materialTypes => {
    setMiabisMaterialTypes(materialTypes);
    sessionStorage.setItem(MATERIAL_TYPES_KEY, JSON.stringify(materialTypes));
  };

  const updateMiabisGenders = genders => {
    setMiabisGenders(genders);
    sessionStorage.setItem(GENDERS_KEY, JSON.stringify(genders));
  };

  const updateSearchValue = searchValue => {
    setSearchValue(searchValue);
    sessionStorage.setItem(SEARCH_KEY, searchValue.toString());
  };

  const updateFiltersVisible = isVisible => {
    setFiltersVisible(isVisible);
    sessionStorage.setItem(FILTER_VISIBLE_KEY, isVisible.toString());
  };

  useEffect(() => {
    fetchCohortCollections();
  }, [fetchCohortCollections]);

  useEffect(() => {
    fetchMiabisDataCategories(params.target);
  }, [fetchMiabisDataCategories, params]);

  useEffect(() => {
    fetchMiabisCollectionTypes(params.target);
  }, [fetchMiabisCollectionTypes, params]);

  useEffect(() => {
    fetchMiabisMaterialTypes(params.target);
  }, [fetchMiabisMaterialTypes, params]);

  const setInitialMiabisDataCategories = useCallback(
    reset => {
      const savedCategories = getItemFromStorage(CATEGORIES_KEY, types.array);
      const dataCategoriesWithSelected =
        savedCategories.length && !reset
          ? savedCategories
          : miabisDataCategoriesState.miabisDataCategories.map(dataCategory => {
              return {
                ...dataCategory,
                isSelected: false
              };
            });
      updateMiabisDataCategories(
        sortMiabisEntitiesAlphabetically(dataCategoriesWithSelected, language)
      );
    },
    [miabisDataCategoriesState.miabisDataCategories, language]
  );

  const setInitialMiabisCollectionTypes = useCallback(
    reset => {
      const savedTypes = getItemFromStorage(COLLECTION_TYPES_KEY, types.array);
      const collectionTypesWithSelected =
        savedTypes.length && !reset
          ? savedTypes
          : miabisCollectionTypesState.miabisCollectionTypes.map(
              collectionType => {
                return {
                  ...collectionType,
                  isSelected: false
                };
              }
            );
      updateMiabisCollectionTypes(
        sortMiabisEntitiesAlphabetically(collectionTypesWithSelected, language)
      );
    },
    [miabisCollectionTypesState.miabisCollectionTypes, language]
  );

  const setInitialMiabisMaterialTypes = useCallback(
    reset => {
      const savedTypes = getItemFromStorage(MATERIAL_TYPES_KEY, types.array);
      const materialTypesWithSelected =
        savedTypes.length && !reset
          ? savedTypes
          : miabisMaterialTypesState.miabisMaterialTypes.map(materialType => {
              return {
                ...materialType,
                isSelected: false
              };
            });
      updateMiabisMaterialTypes(
        sortMiabisEntitiesAlphabetically(materialTypesWithSelected, language)
      );
    },
    [miabisMaterialTypesState.miabisMaterialTypes, language]
  );

  const setInitialFauxMiabisGenders = useCallback(
    reset => {
      const savedGenders = getItemFromStorage(GENDERS_KEY, types.array);
      const fauxMiabisGenders =
        savedGenders.length && !reset
          ? savedGenders
          : [
              {
                id: "FEMALE",
                isSelected: false,
                name_fi: "Nainen",
                name_en: "Female"
              },
              {
                id: "MALE",
                isSelected: false,
                name_fi: "Mies",
                name_en: "Male"
              }
            ];
      updateMiabisGenders(
        sortMiabisEntitiesAlphabetically(fauxMiabisGenders, language)
      );
    },
    [language]
  );

  useEffect(() => {
    setInitialMiabisDataCategories();
  }, [setInitialMiabisDataCategories]);

  useEffect(() => {
    setInitialMiabisCollectionTypes();
  }, [setInitialMiabisCollectionTypes]);

  useEffect(() => {
    setInitialMiabisMaterialTypes();
  }, [setInitialMiabisMaterialTypes]);

  useEffect(() => {
    setInitialFauxMiabisGenders();
  }, [setInitialFauxMiabisGenders]);

  if (
    miabisDataCategoriesState.loading ||
    miabisCollectionTypesState.loading ||
    miabisMaterialTypesState.loading
  ) {
    return <LoadingIndicator />;
  }

  if (
    cohortCollectionState.error ||
    miabisDataCategoriesState.error ||
    miabisCollectionTypesState.error ||
    miabisMaterialTypesState.error
  ) {
    return (
      <StyledFetchError>{t("cohort_collection_list.error")}</StyledFetchError>
    );
  }

  const changeSearchValue = e => {
    updateSearchValue(e.target.value);
  };

  const clearFilters = () => {
    updateSearchValue("");
    setInitialMiabisDataCategories(true);
    setInitialMiabisCollectionTypes(true);
    setInitialMiabisMaterialTypes(true);
    setInitialFauxMiabisGenders(true);
  };

  return (
    <StyledCohortCollectionSearchPage>
      <StyledCenteredColumn>
        <StyledSearchContainer>
          <StyledSearchInput
            onChange={e => changeSearchValue(e)}
            placeholder={t("cohort_collection_search.search_placeholder")}
            value={searchValue}
          />
          <Button
            tablerIcon={IconSearch}
            text={t("cohort_collection_search.search_button")}
            type="submit"
            variant="CALL_TO_ACTION"
          />
        </StyledSearchContainer>

        <StyledFilterContainer isHidden={!areFiltersVisible}>
          <MiabisFilterList
            language={language}
            miabisEntities={miabisDataCategories}
            setMiabisEntities={updateMiabisDataCategories}
            title={t("cohort_collection_view.data_type")}
          />
          <MiabisFilterList
            language={language}
            miabisEntities={miabisCollectionTypes}
            setMiabisEntities={updateMiabisCollectionTypes}
            title={t("cohort_collection_view.collection_type")}
          />
          <MiabisFilterList
            language={language}
            miabisEntities={miabisMaterialTypes}
            setMiabisEntities={updateMiabisMaterialTypes}
            title={t("cohort_collection_view.sample_type")}
          />
          <MiabisFilterList
            language={language}
            miabisEntities={miabisGenders}
            setMiabisEntities={updateMiabisGenders}
            title={t("cohort_collection_view.gender")}
          />
        </StyledFilterContainer>

        <StyledFilterButtonContainer>
          {areFiltersVisible && (
            <span id="hide-filters-button">
              <Button
                onClick={clearFilters}
                tablerIcon={IconFilterOff}
                text={t("cohort_collection_search.clear_filters")}
                variant="UNOBTRUSIVE_CAUTION"
              />
            </span>
          )}
          <Button
            onClick={() => updateFiltersVisible(!areFiltersVisible)}
            tablerIcon={IconFilter}
            text={t(
              areFiltersVisible
                ? "cohort_collection_search.hide_filters"
                : "cohort_collection_search.show_filters"
            )}
            variant="UNOBTRUSIVE"
          />
        </StyledFilterButtonContainer>
        <SeparatorLine isSubtle />
      </StyledCenteredColumn>

      <CohortCollectionSearchList
        cohortCollections={cohortCollectionState.cohortCollections}
        collectionTypes={filterSelectedMiabisEntities(miabisCollectionTypes)}
        dataCategories={filterSelectedMiabisEntities(miabisDataCategories)}
        genders={filterSelectedMiabisEntities(miabisGenders)}
        loading={cohortCollectionState.loading}
        materialTypes={filterSelectedMiabisEntities(miabisMaterialTypes)}
        searchValue={searchValue}
      />
    </StyledCohortCollectionSearchPage>
  );
};

const mapStateToProps = state => ({
  cohortCollectionState: state.cohortCollections,
  miabisDataCategoriesState: state.miabisDataCategories,
  miabisCollectionTypesState: state.miabisCollectionTypes,
  miabisMaterialTypesState: state.miabisMaterialTypes
});

export default connect(mapStateToProps, {
  fetchCohortCollections,
  fetchMiabisDataCategories,
  fetchMiabisCollectionTypes,
  fetchMiabisMaterialTypes
})(CohortCollectionSearchPage);
