import config from "../config";
import useFetch from "./useFetch";

import feasibilityRequestToLegacy from "../helpers/feasibilityRequestToLegacy";
import accessRequestToLegacy from "../helpers/accessRequestToLegacy";

const combineRequests = (access, feasibility) => {
  if (access && feasibility) {
    return access
      .map(accessRequestToLegacy)
      .concat(feasibility.map(feasibilityRequestToLegacy));
  }

  if (access) {
    return access.map(accessRequestToLegacy);
  }

  if (feasibility) {
    return feasibility.map(feasibilityRequestToLegacy);
  }

  return [];
};

const requestUpdatedSort = (requestA, requestB) => {
  return new Date(requestA.changedate) >= new Date(requestB.changedate)
    ? -1
    : 1;
};

const useFetchRequests = isArchived => {
  const { data: accessRequests, loading: loading1, error: error1 } = useFetch(
    `${config.requestsUrl}/access-request/own?isArchived=${
      isArchived ? "true" : "false"
    }`
  );
  const {
    data: feasibilityRequests,
    loading: loading2,
    error: error2
  } = useFetch(
    `${config.requestsUrl}/feasibility-request/own?isArchived=${
      isArchived ? "true" : "false"
    }`
  );

  const requests = combineRequests(accessRequests, feasibilityRequests).sort(
    requestUpdatedSort
  );

  return {
    requests,
    loading: loading1 || loading2,
    error: error1 || error2
  };
};

export default useFetchRequests;
