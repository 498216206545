import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";

export const useAuth0UserWithPermissions = cacheKey => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    user
  } = useAuth0();
  const [permissions, setPermissions] = useState(null);

  useEffect(() => {
    const setUserPermissions = async () => {
      try {
        if (!isLoading && isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          const { permissions: auth0Permissions } = jwtDecode(accessToken);
          setPermissions(auth0Permissions);
        }
      } catch (error) {
        setPermissions(null);
        console.error("Error getting access token from Auth0.");
      }
    };

    setUserPermissions();
  }, [getAccessTokenSilently, isAuthenticated, isLoading, cacheKey]);

  return { user, permissions, isAuthenticated, isLoading };
};
