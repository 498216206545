const arrayFields = [
  "included_biobanks",
  "patient_gender",
  "sample_types",
  "who_collects_data",
  "feasibilitylevel"
];

export const transformData = data => {
  const transformedData = { ...data };
  arrayFields.forEach(field => {
    if (!data[field]) {
      transformedData[field] = [];
    } else {
      transformedData[field] = data[field].split(" ");
    }
  });
  return transformedData;
};
