import EditFeasibilityRequestContainer from "../pages/EditFeasibilityRequestContainer";
import FeasibilityRequestContainer from "../pages/FeasibilityRequestContainer";
import FeasibilityRequestStatus from "../pages/FeasibilityRequestStatus/FeasibilityRequestStatus";

export const feasibilityRequestUrl = "/feasibility-request";

const routes = [
  {
    path: `${feasibilityRequestUrl}/new`,
    exact: true,
    private: true,
    element: <FeasibilityRequestContainer />
  },
  {
    path: `${feasibilityRequestUrl}/edit/:feasibilityRequestId`,
    exact: true,
    private: true,
    element: <EditFeasibilityRequestContainer />
  },
  {
    path: `${feasibilityRequestUrl}/status/:feasibilityRequestId`,
    private: true,
    element: <FeasibilityRequestStatus />
  }
];

export default routes;
