import {
  IconArchive,
  IconDna2,
  IconDots,
  IconHeartbeat,
  IconHierarchy2,
  IconMicroscope,
  IconNotebook,
  IconReportMedical,
  IconTestPipe
} from "@tabler/icons";
import { memo } from "react";
import { useTheme } from "styled-components";
import { StyledMiabisCategoryIcon } from "./MiabisCategoryIcon.styles";

const MiabisCategoryIcon = ({ isLarge, isColorful, miabisId }) => {
  const theme = useTheme();
  const iconSize = isLarge
    ? theme.values.iconSizes.LARGE
    : theme.values.iconSizes.MEDIUM;
  const strokeWidth = isLarge
    ? theme.values.strokeWidths.THIN
    : theme.values.strokeWidths.MEDIUM;
  const iconColor = isColorful
    ? theme.values.colorPalette.greyCold[50]
    : theme.values.colorPalette.greyCold[100];

  const categoryMap = {
    "a2d23422-f4f4-4316-9d64-345a6e845b07": (
      <IconTestPipe
        color={iconColor}
        size={iconSize}
        strokeWidth={strokeWidth}
      />
    ),
    "dd796fe1-907d-439d-83e8-353c523dda47": (
      <IconNotebook
        color={iconColor}
        size={iconSize}
        strokeWidth={strokeWidth}
      />
    ),
    "f2dce8f9-164a-4121-bcd1-7db9da0fca23": (
      <IconMicroscope
        color={iconColor}
        size={iconSize}
        strokeWidth={strokeWidth}
      />
    ),
    "6223b59a-b8f9-45e0-8203-c76ae77245b4": (
      <IconReportMedical
        color={iconColor}
        size={iconSize}
        strokeWidth={strokeWidth}
      />
    ),
    "8263063d-5fb0-461f-8371-563458dcc3cd": (
      <IconArchive
        color={iconColor}
        size={iconSize}
        strokeWidth={strokeWidth}
      />
    ),
    "47f82d26-1b0a-4bf6-8c75-51498d89fa1d": (
      <IconHierarchy2
        color={iconColor}
        size={iconSize}
        strokeWidth={strokeWidth}
      />
    ),
    "ac3eacea-275a-4ad5-8464-62366308adda": (
      <IconDna2 color={iconColor} size={iconSize} strokeWidth={strokeWidth} />
    ),
    "fd4d202c-1aac-4e40-9beb-21f21514f921": (
      <IconHeartbeat
        color={iconColor}
        size={iconSize}
        strokeWidth={strokeWidth}
      />
    ),
    "16288bb2-3189-4b8e-ba94-a5a78f634823": (
      <IconDots color={iconColor} size={iconSize} strokeWidth={strokeWidth} />
    )
  };

  return (
    <StyledMiabisCategoryIcon isColorful={isColorful} isLarge={isLarge}>
      {categoryMap[miabisId]}
    </StyledMiabisCategoryIcon>
  );
};

export default memo(MiabisCategoryIcon);
