import config from "../config";
import axios from "../helpers/axios";
import { logError } from "../helpers/logger";
import {
  USER_CHANGE_PASSWORD,
  USER_REQUEST_PASSWORD_RESET_FAILURE,
  USER_REQUEST_PASSWORD_RESET_SUCCESSFUL,
  USER_RESET_FORM_STATE,
  USER_UPDATE_PROFILE_FAILURE,
  USER_UPDATE_PROFILE_SUCCESSFUL,
  USER_UPSERT_EXPERT_PROFILE_FAILURE,
  USER_UPSERT_EXPERT_PROFILE_SUCCESSFUL
} from "./users.actions";

const userApiUrl = `${config.platformUrl}/api/user`;

export const resetFormState = () => ({
  type: USER_RESET_FORM_STATE
});

export const userRequestPasswordResetSuccessful = () => ({
  type: USER_REQUEST_PASSWORD_RESET_SUCCESSFUL
});

export const userRequestPasswordResetFailure = (code, message) => ({
  type: USER_REQUEST_PASSWORD_RESET_FAILURE,
  code,
  message
});

export const requestPasswordReset = auth0Guid => {
  return async dispatch => {
    try {
      const response = await axios.put(`${userApiUrl}/${auth0Guid}/password`);
      if (response.status === 200) {
        dispatch(userRequestPasswordResetSuccessful());
      }
    } catch (error) {
      logError(error);
      dispatch(
        userRequestPasswordResetFailure(
          error.response.status,
          error.response.data.message
        )
      );
    }
  };
};

export const userChangePassword = () => ({
  type: USER_CHANGE_PASSWORD
});

export const userUpdateProfileSuccessful = () => ({
  type: USER_UPDATE_PROFILE_SUCCESSFUL
});

export const userUpdateProfileFailure = (code, message) => ({
  type: USER_UPDATE_PROFILE_FAILURE,
  code,
  message
});

export const updateProfile = (userId, fields) => {
  return async dispatch => {
    try {
      const response = await axios.patch(`${userApiUrl}/${userId}`, fields);

      if (response.status === 200) {
        dispatch(userUpdateProfileSuccessful());
      }
    } catch (error) {
      logError(error);
      dispatch(
        userUpdateProfileFailure(
          error.response.status,
          error.response.data.message
        )
      );
    }
  };
};

export const userUpsertExpertProfileSuccessful = () => ({
  type: USER_UPSERT_EXPERT_PROFILE_SUCCESSFUL
});

export const userUpsertExpertProfileFailure = (code, message) => ({
  type: USER_UPSERT_EXPERT_PROFILE_FAILURE,
  code,
  message
});

export const upsertExpertProfile = (userId, expertProfileId, fields) => {
  return async dispatch => {
    try {
      const axiosFunc = !expertProfileId ? axios.post : axios.patch;
      await axiosFunc(
        `${config.platformUrl}/api/user/${userId}/expert-profile`,
        fields
      );

      dispatch(userUpsertExpertProfileSuccessful());
      window.location.reload();
    } catch (error) {
      logError(error);
      dispatch(
        userUpsertExpertProfileFailure(
          error.response.status,
          error.response.data.message
        )
      );
    }
  };
};
