import React, { useEffect, useRef, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import { Navigate } from "react-router";
import { useTranslation } from "react-i18next";

import {
  getFeasibilityRequestEditUrl,
  getUrlForNewAccessRequest,
  getUrlForNewAccessRequestFromFeasibility
} from "../../helpers/linkGenerator";
import Title from "../../components/Title/Title";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import RequestResponse from "../../components/RequestResponse/RequestResponse";
import styles from "./feasibility-request-status.module.scss";
import InfoBlock from "../../components/InfoBlock/InfoBlock";
import iconProcessing from "../../assets/img/icon_processing.svg";
import useFetchFeasibilityRequest from "../../hooks/useFetchFeasibilityRequest";
import LoadingWrapper from "../../components/LoadingWrapper";
import { isNewFeasibilityRequest } from "../../helpers/requestHelper";
import { feasibilityRequestUrl } from "../../routes/FeasibilityRequestRoutes";
import { completedFeasibilityStates } from "../../common/requestStates";
import { isDeprecatedByDate } from "../../helpers/utils";
import config from "../../config";
import { Card, Overlay } from "react-bootstrap";
import {
  StyleCardLinkPrimary,
  StyleCardLinkSecondary,
  StyledCardActions,
  StyledPopover,
  StyledPopoverCloseButton,
  StyledPopoverContent
} from "./FeasibilityRequestStatus.styles";

const COMPLETED_STATES = completedFeasibilityStates;

const ConditionalInfoBlock = ({ isCompleted, isDeprecated }) => {
  const { t } = useTranslation();

  if (isCompleted && !isDeprecated) {
    return (
      <InfoBlock
        title={t("response_available_title")}
        iconSrc={iconProcessing}
        description={t("response_available_description")}
      />
    );
  }

  return (
    <InfoBlock
      title={t("request_being_processed")}
      iconSrc={iconProcessing}
      description={t("waiting_for_biobanks_response")}
    />
  );
};

const FeasibilityRequestStatus = () => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDeprecated, setIsDeprecated] = useState(false);

  const match = useMatch(
    `${feasibilityRequestUrl}/status/:feasibilityRequestId`
  );
  const { feasibilityRequestId } = match.params;
  const {
    feasibilityRequest: data,
    loading,
    error
  } = useFetchFeasibilityRequest(feasibilityRequestId);

  useEffect(() => {
    setIsCompleted(
      data && COMPLETED_STATES.some(state => state === data.state)
    );
    setIsDeprecated(
      data &&
        isDeprecatedByDate(config.requestFormsV1DeadlineDate, data.createdate)
    );
  }, [data]);

  // Response types have changed significantly. UI will need more rework to make it work.
  // For now, we set responses variable to null to not render response block.
  const response = null;
  const { t } = useTranslation();
  const [showOverlay, setShowOverlay] = useState(false);
  const [targetForOverlay, setTargetForOverlay] = useState(null);
  const overlayDivRef = useRef(null);

  const toggleOverlay = event => {
    setShowOverlay(!showOverlay);
    setTargetForOverlay(event.target);
  };

  if (!loading && isNewFeasibilityRequest(data)) {
    return <Navigate to={getFeasibilityRequestEditUrl(feasibilityRequestId)} />;
  }

  const NewAccessRequestOverlay = () => (
    <Overlay
      show={showOverlay}
      target={targetForOverlay}
      placement="bottom"
      container={overlayDivRef.current}
    >
      <StyledPopover id="popover-contained">
        <StyledPopoverContent>
          <Card.Body>
            <Card.Title>
              <b>{t("new_access_request")}</b>
              <StyledPopoverCloseButton onClick={() => setShowOverlay(false)}>
                x
              </StyledPopoverCloseButton>
            </Card.Title>
            <Card.Text>{t("new_access_request_prefill_question")}</Card.Text>
            <StyledCardActions>
              <StyleCardLinkPrimary
                href={getUrlForNewAccessRequestFromFeasibility(
                  feasibilityRequestId
                )}
                className={"button"}
              >
                {t("yes")}
              </StyleCardLinkPrimary>
              <StyleCardLinkSecondary href={getUrlForNewAccessRequest()}>
                {t("no_start_new_request")}
              </StyleCardLinkSecondary>
            </StyledCardActions>
          </Card.Body>
        </StyledPopoverContent>
      </StyledPopover>
    </Overlay>
  );

  return (
    <LoadingWrapper
      isLoading={loading}
      isInErrorState={error}
      errorMsg={t("feasibility_request_status_loading_error")}
    >
      <Title
        rightChildren={
          <div className={styles.btnGroup}>
            <Link to={getFeasibilityRequestEditUrl(feasibilityRequestId)}>
              <button type="button" className={"button button-secondary"}>
                {t("view_feasibility_request")}
              </button>
            </Link>
            {!isDeprecated && isCompleted ? (
              <button
                ref={overlayDivRef}
                type="button"
                className={"button"}
                onClick={toggleOverlay}
              >
                + {t("new_access_request")}
              </button>
            ) : null}
          </div>
        }
      >
        {data.projektin_nimi || t("untitled_feasibility_request")}
      </Title>
      <HorizontalLine className={"reset-top-margin"} />
      {response ? (
        <RequestResponse
          response={response.response}
          biobankFiles={response.biobankFiles}
          otherFiles={response.files}
          answers={response.sampleAvailability}
          data={response.dataAvailability}
        />
      ) : (
        <ConditionalInfoBlock
          isCompleted={isCompleted}
          isDeprecated={isDeprecated}
        />
      )}
      <NewAccessRequestOverlay
        t={t}
        feasibilityRequestId={feasibilityRequestId}
        showOverlay={showOverlay}
        targetForOverlay={targetForOverlay}
        overlayDivRef={overlayDivRef}
        closeOverlay={() => setShowOverlay(false)}
      />
    </LoadingWrapper>
  );
};

export default FeasibilityRequestStatus;
