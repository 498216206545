import { getExpertsUrl } from "../helpers/linkGenerator";
import ExpertsSearchPage from "../pages/ExpertsSearchPage/ExpertsSearchPage";
import { ThemeProvider } from "styled-components";
import { themes } from "@finbb/ui-components";

const expertsUrl = getExpertsUrl();

const routes = [
  {
    path: expertsUrl,
    private: true,
    exact: true,
    element: (
      <ThemeProvider theme={themes.light}>
        <ExpertsSearchPage />
      </ThemeProvider>
    )
  }
];

export default routes;
