import React from "react";
import { useParams } from "react-router-dom";

import FeasibilityRequest from "../components/Feasibilityrequest/FeasibilityRequest";
import DeprecatedFeasibilityRequest from "../components/Feasibilityrequest/DeprecatedFeasibilityRequest";
import requestStates from "../common/requestStates";
import useFetchFeasibilityRequest from "../hooks/useFetchFeasibilityRequest";
import initialValues from "../components/Feasibilityrequest/feasibilityRequestState";
import config from "../config";
import { isDeprecatedByDate } from "../helpers/utils";

const EditFeasibilityRequestContainer = () => {
  const match = useParams();
  const { feasibilityRequestId } = match;
  const {
    feasibilityRequest: data,
    loading,
    error
  } = useFetchFeasibilityRequest(feasibilityRequestId);
  const enabled =
    data && [requestStates.saved, requestStates.refine].includes(data.state);

  const deprecated = isDeprecatedByDate(
    config.requestFormsV1DeadlineDate,
    data.createdate
  );

  return deprecated ? (
    <DeprecatedFeasibilityRequest
      id={feasibilityRequestId}
      initialValues={{ ...initialValues, ...data }}
      isLoading={loading}
      errorPrefilling={error}
      files={data?.files ?? []}
      disabled={!enabled}
    />
  ) : (
    <FeasibilityRequest
      id={feasibilityRequestId}
      initialValues={{ ...initialValues, ...data }}
      isLoading={loading}
      errorPrefilling={error}
      files={data?.files ?? []}
      disabled={!enabled}
    />
  );
};

export default EditFeasibilityRequestContainer;
