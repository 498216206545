import { useTranslation } from "react-i18next";

const FEASIBILITY_REQUEST_TYPES = {
  ACADEMIC: "academic",
  INDUSTRY: "industry",
  OTHER: "other"
};

export const FeasibilityRequestPricing = ({
  requestType = FEASIBILITY_REQUEST_TYPES.OTHER
}) => {
  const { t } = useTranslation();

  const industryPricing = (
    <>
      {t("feasibility_for_industry_categories")}
      <ul>
        <li>{t("simple_request_pricing")}</li>
        <li>{t("basic_request_pricing", { pricingInfo: "1 400 - 6 300" })}</li>
        <li>
          {t("advanced_request_pricing", { pricingInfo: "2 800 - 12 600" })}
        </li>
        <li>{t("tailored_request_pricing")}</li>
      </ul>
      {t("industry_pricing_options_details")}
    </>
  );

  const academicPricing = <>{t("researcher_pricing_options_details")}</>;

  return requestType === FEASIBILITY_REQUEST_TYPES.INDUSTRY ? (
    industryPricing
  ) : requestType === FEASIBILITY_REQUEST_TYPES.ACADEMIC ? (
    academicPricing
  ) : (
    <>
      {industryPricing} {<br />}
      {<br />} {academicPricing}
    </>
  );
};
