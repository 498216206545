import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import NavigationUser from "../components/NavigationUser/NavigationUser";
import config from "../config";
import { getCatalogUrl, getCohortsUrl } from "../helpers/linkGenerator";
import { mtaCentralTermsUrl } from "../pages/static/MTACentralTerms";
import LanguageChangeControlContainer from "./LanguageChangeControlContainer";

const getShowRequestLink = (user, roles) => {
  if (!user || !roles) {
    // The user has no roles or is not logged in, we don't show the link
    return false;
  }

  const isResearcher = roles.length > 0 && roles.includes("researcher");
  const onlyOneRole = roles.length === 1;
  if (isResearcher && onlyOneRole) {
    // The user is a researcher with no additional roles, we don't show the link
    return false;
  }

  // Show the link if none of the above match
  return true;
};

const Header = ({ collapsed = false, children }) => {
  const { user, isLoading } = useAuth0();
  const { t } = useTranslation();
  const [showRequestAppLink, setShowRequestAppLink] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      handleUserChange();
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const handleUserChange = async () => {
    const roles = user && user["https://fingenious.fi/roles"];
    setShowRequestAppLink(getShowRequestLink(user, roles));
  };

  const headerClasses = classNames({
    header: true,
    collapsed: collapsed
  });

  return (
    <header className={headerClasses}>
      <div className="finbb-container">
        <div className="nav-container">
          <nav className="primary">
            <a href={config.fingeniousMain} className="logo hide-text">
              Fingenious
            </a>

            <NavLink to={getCatalogUrl()} className="nav-link">
              {t("catalog")}
            </NavLink>

            {!config.hideCohorts && (
              <NavLink to={getCohortsUrl()} className="nav-link">
                {t("cohorts")}
              </NavLink>
            )}

            {user && (
              <NavLink to="/requests" className="nav-link">
                {t("requests")}
              </NavLink>
            )}

            {showRequestAppLink && (
              <a href={config.requestsAppUrl} className="nav-link">
                {t("requests_app_link_text")}
              </a>
            )}

            {
              // The below was commented out in January of 2024 due to recruit being shutdown
              // TODO: Uncomment when recruit is started again
              /* {user && (
                <a href={config.recruitAppUrl} className="nav-link">
                  {t("recruit")}
                </a>
              )}*/
            }

            <a
              href={config.platformWebUrl + "/privacy-policy"}
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("privacy_policy")}
            </a>

            {user && (
              <NavLink to={mtaCentralTermsUrl} className="nav-link">
                {t("mta_central_terms")}
              </NavLink>
            )}
          </nav>
          <nav className="secondary">
            {config.enableLocalisation && (
              <LanguageChangeControlContainer className="nav-link" />
            )}
            {!isLoading && <NavigationUser className="nav-link" />}
          </nav>
        </div>
        {children}
      </div>
    </header>
  );
};

export default Header;
