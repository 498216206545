import axios from "../helpers/axios";
import config from "../config";
import { push } from "redux-first-history";
import { logEvent } from "../helpers/amplitude";

import {
  SEARCH_UPDATE_INPUT_VALUE,
  SEARCH_CLEAR_SUGGESTIONS,
  SEARCH_MAYBE_UPDATE_SUGGESTIONS,
  SEARCH_LOAD_SUGGESTIONS_BEGIN
} from "./search.actions";
import { logError } from "../helpers/logger";

export const loadSuggestions = function(value) {
  return (dispatch, getState) => {
    dispatch(loadSuggestionsBegin());

    let url =
      `${config.catalogApiUrl}/v1/icd10/search?query=` +
      encodeURIComponent(value);

    const currentLanguage = getState().lng;
    if (currentLanguage && currentLanguage.length) {
      url += "&lang=" + currentLanguage;
    }

    axios
      .get(url)
      .then(function(response) {
        dispatch(maybeUpdateSuggestions(response.data, value));
      })
      .catch(function(error) {
        logError(error);
      });
  };
};

export const updateInputValue = function(value) {
  return {
    type: SEARCH_UPDATE_INPUT_VALUE,
    value
  };
};

export const clearSuggestions = function() {
  return {
    type: SEARCH_CLEAR_SUGGESTIONS
  };
};

export const loadSuggestionsBegin = function() {
  return {
    type: SEARCH_LOAD_SUGGESTIONS_BEGIN
  };
};

export const maybeUpdateSuggestions = function(suggestions, value) {
  return {
    type: SEARCH_MAYBE_UPDATE_SUGGESTIONS,
    suggestions,
    value
  };
};

export const selectValue = (value, isDirectory, code) => dispatch => {
  if (isDirectory) {
    dispatch(push("/catalog/browse/" + value));
  } else {
    logEvent("select_icd10", { source: "search", code });
    dispatch(push("/catalog/samples/" + value));
  }
};
