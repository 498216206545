import { connect } from "react-redux";
import Breadcrumb from "./Breadcrumb";

export const createBreadcrumbObject = (label, url = null) => {
  return {
    label,
    url
  };
};

const mapStateToProps = (state, ownProps) => {
  let crumbs = [];

  if (state.currentSample) {
    const parents = state.currentSample.parents;
    const breadCrumbObjects = parents
      ? parents.map(parent =>
          createBreadcrumbObject(
            parent.code + " " + parent.title,
            "/catalog/browse/" + parent.id
          )
        )
      : [];

    crumbs = [
      ...crumbs,
      ...breadCrumbObjects,
      createBreadcrumbObject(
        state.currentSample.code.code + " " + state.currentSample.code.title
      )
    ];
  } else if (state.directories !== null) {
    if (state.directories.parents && state.directories.parents.length) {
      crumbs = [
        ...crumbs,
        ...state.directories.parents.map(parent =>
          createBreadcrumbObject(
            parent.code + " " + parent.title,
            "/catalog/browse/" + parent.id
          )
        )
      ];
    }
    if (state.directories.code) {
      crumbs.push(
        createBreadcrumbObject(
          state.directories.code.code + " " + state.directories.code.title
        )
      );
    }
  }

  return {
    crumbs
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}

const BreadcrumbContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Breadcrumb);
export default BreadcrumbContainer;
