import styled from "styled-components";

export const ModalContainer = styled.div`
  background: #f9f9f9;
  color: black;
  padding: 2rem;
  border-radius: 15px;
  max-width: 50rem;
  overflow: auto;
  position: fixed;
  z-index: 9999;
`;
