import React from "react";
import { NavLink } from "react-router-dom";
import { createBreadcrumbObject } from "./BreadcrumbContainer";
import { useTranslation } from "react-i18next";

const renderCrumb = (crumb, link = true) => {
  if (crumb.url && link) {
    return <NavLink to={crumb.url}>{crumb.label}</NavLink>;
  }
  return crumb.label;
};

const Breadcrumb = ({ crumbs }) => {
  const { t } = useTranslation();
  crumbs = [
    createBreadcrumbObject(t("icd10_catalogue"), "/catalog"),
    ...crumbs
  ];

  if (crumbs.length === 1) {
    return (
      <div className="breadcrumb">
        <h2>{renderCrumb(crumbs[0], false)}</h2>
      </div>
    );
  }

  return (
    <div className="breadcrumb">
      <ul>
        {crumbs.slice(0, -1).map((crumb, index) => {
          return <li key={index}>{renderCrumb(crumb)}</li>;
        })}
      </ul>
      <h2>{renderCrumb(crumbs[crumbs.length - 1], false)}</h2>
    </div>
  );
};

export default Breadcrumb;
