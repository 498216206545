import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { fetchDirectories } from "../actions/directory";
import ICDDirectory from "./ICD10Directory";

const ICD10DirectoryContainer = props => {
  const params = useParams();
  const { fetchDirectories } = props;

  const fetchData = useCallback(
    target => {
      fetchDirectories(target);
    },
    [fetchDirectories]
  );

  useEffect(() => {
    fetchData(params.target || null);
  }, [fetchData, params, props.lng]);

  return <ICDDirectory {...props} />;
};

const mapStateToProps = state => ({
  directories: state.directories ? state.directories.results : [],
  lng: state.lng
});

export default connect(mapStateToProps, { fetchDirectories })(
  ICD10DirectoryContainer
);
