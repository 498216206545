const CATEGORIES_KEY = "categories";
const COLLECTION_TYPES_KEY = "collection_types";
const MATERIAL_TYPES_KEY = "material_types";
const GENDERS_KEY = "genders";
const SEARCH_KEY = "search";
const FILTER_VISIBLE_KEY = "filter_visible";

const EXPERT_FILTERS_KEY = "expert_filters";

const allKeys = [
  CATEGORIES_KEY,
  COLLECTION_TYPES_KEY,
  MATERIAL_TYPES_KEY,
  GENDERS_KEY,
  SEARCH_KEY,
  FILTER_VISIBLE_KEY,
  EXPERT_FILTERS_KEY
];

const types = {
  boolean: "boolean",
  array: "array",
  string: "string",
  object: "object"
};

const getItemFromStorage = (key, type) => {
  const item = sessionStorage.getItem(key);
  switch (type) {
    case types.boolean:
      return item === "true";
    case types.array:
      return item ? JSON.parse(item) : [];
    case types.object:
      return item ? JSON.parse(item) : {};
    default:
      return item || "";
  }
};

const emptyStorage = () => {
  allKeys.forEach(key => {
    sessionStorage.removeItem(key);
  });
};

export {
  CATEGORIES_KEY,
  COLLECTION_TYPES_KEY,
  MATERIAL_TYPES_KEY,
  GENDERS_KEY,
  SEARCH_KEY,
  FILTER_VISIBLE_KEY,
  EXPERT_FILTERS_KEY,
  types,
  getItemFromStorage,
  emptyStorage
};
