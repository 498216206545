import { CommonContactInfoSection } from "./CommonContactInfoSection";
import Form from "react-bootstrap/Form";
import React from "react";
import { RequestSection } from "../RequestSection";
import { useTranslation } from "react-i18next";

export const BackgroundOrgSection = ({
  formControl: FormControl,
  disabled = false
}) => {
  const { t } = useTranslation();

  return (
    <>
      <RequestSection
        title={t("background_organization")}
        description={t("background_organization_description")}
      >
        <CommonContactInfoSection
          formControl={FormControl}
          prefix={"bg"}
          disabled={disabled}
        >
          <Form.Group controlId="formBgBusinessId">
            <FormControl
              name={"bg_businessid"}
              maxLength={50}
              className={"hidden"}
            />
          </Form.Group>
        </CommonContactInfoSection>
      </RequestSection>
    </>
  );
};
