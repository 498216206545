import { connect } from "react-redux";
import { isEqual } from "lodash";
import CartSampleButton from "./CartSampleButton";
import { addSampleToCart, removeSampleFromCart } from "../actions/cart";
import { CATEGORY_CATALOG, CATEGORY_COHORT } from "../actions/category-types";

const isEnabled = (state, ownProps) => {
  return !state.cart.items.some(item => {
    const checkIfCohortItemIsMatch =
      isEqual(item.category, CATEGORY_COHORT) &&
      isEqual(item.codeInfo.id, ownProps.codeInfo.id);
    const checkIfCatalogItemIsMatch =
      isEqual(item.codeInfo.id, ownProps.codeInfo.id) &&
      isEqual(item.specimenType, ownProps.specimenType) &&
      isEqual(item.filters, state.selectedFilters);

    return checkIfCohortItemIsMatch || checkIfCatalogItemIsMatch;
  });
};

const mapStateToProps = (state, ownProps) => {
  return {
    enabled: isEnabled(state, ownProps),
    sample: state.currentSample,
    category: ownProps.category ?? CATEGORY_CATALOG
  };
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onAdd() {
      dispatch(
        addSampleToCart(
          ownProps.category ?? CATEGORY_CATALOG,
          ownProps.codeInfo,
          ownProps.specimenType,
          ownProps.sample,
          ownProps.quantity
        )
      );
    },
    onRemove() {
      dispatch(
        removeSampleFromCart(
          ownProps.category ?? CATEGORY_CATALOG,
          ownProps.codeInfo,
          ownProps.specimenType
        )
      );
    }
  };
}

const CartSampleButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartSampleButton);
export default CartSampleButtonContainer;
