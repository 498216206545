import { useTranslation } from "react-i18next";
import React from "react";
import FieldErrorBlock from "./FieldErrorBlock";

const FormFieldError = ({ fieldName, formProps }) => {
  const { t } = useTranslation();

  return formProps.errors[fieldName] && formProps.touched[fieldName] ? (
    <FieldErrorBlock>{t(formProps.errors[fieldName].key)}</FieldErrorBlock>
  ) : null;
};

export default FormFieldError;
