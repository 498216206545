export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESSFUL = "USER_LOGIN_SUCCESSFUL";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_REQUEST_PASSWORD_RESET_SUCCESSFUL =
  "USER_REQUEST_PASSWORD_RESET_SUCCESSFUL";
export const USER_REQUEST_PASSWORD_RESET_FAILURE =
  "USER_REQUEST_PASSWORD_RESET_FAILURE";

export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESSFUL =
  "USER_CHANGE_PASSWORD_SUCCESSFUL";
export const USER_CHANGE_PASSWORD_FAILURE = "USER_CHANGE_PASSWORD_FAILURE";

export const USER_UPDATE_PROFILE = "USER_UPDATE_PROFILE";
export const USER_UPDATE_PROFILE_SUCCESSFUL = "USER_UPDATE_PROFILE_SUCCESSFUL";
export const USER_UPDATE_PROFILE_FAILURE = "USER_UPDATE_PROFILE_FAILURE";

export const USER_UPSERT_EXPERT_PROFILE = "USER_UPSERT_EXPERT_PROFILE";
export const USER_UPSERT_EXPERT_PROFILE_SUCCESSFUL =
  "USER_UPSERT_EXPERT_PROFILE_SUCCESSFUL";
export const USER_UPSERT_EXPERT_PROFILE_FAILURE =
  "USER_UPSERT_EXPERT_PROFILE_FAILURE";

export const USER_RESET_FORM_STATE = "USER_RESET_FORM_STATE";
