import { MultiSelect, themes } from "@finbb/ui-components";
import { useFormikContext } from "formik";
/* eslint @typescript-eslint/no-explicit-any: "off" */
import { memo, useState } from "react";
import { ThemeProvider } from "styled-components";

import { useFetchKeywords } from "../../hooks/useFetchKeywords";
import styles from "./KeywordMultiSelect.module.scss";
import { debounce } from "lodash";

const KeywordMultiSelect = ({
  name,
  message,
  label,
  placeholder,
  value,
  required,
  onChanged,
  newItemSelectionText,
  allowNewValues = true
}) => {
  const [inputValue, setInputValue] = useState();
  const { setFieldValue, errors } = useFormikContext();

  const { data } = useFetchKeywords(inputValue);
  const options = (data?.results || []).map(entry => ({
    name: entry.value,
    value: entry.value
  }));

  return (
    <ThemeProvider theme={themes.light}>
      <div className={styles.keywordMultiSelect}>
        <MultiSelect
          name={name}
          label={label}
          value={value ?? []}
          message={message}
          placeholder={placeholder}
          required={required}
          onChanged={newValue => {
            setFieldValue(name, newValue).then(() => {
              if (onChanged) {
                onChanged(newValue);
              }
            });
          }}
          onInputChanged={debounce(setInputValue, 300)}
          newItemSelectionText={newItemSelectionText}
          allowNewValues={allowNewValues}
          options={options}
          variant={errors[name] ? "CAUTION" : "NORMAL"}
        />
      </div>
    </ThemeProvider>
  );
};

export default memo(KeywordMultiSelect);
