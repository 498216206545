import requestStates from "../common/requestStates";
import { SELECTED_MATERIALS } from "../common/selectedMaterials";
import { specimenTypeMapping } from "./feasibilityRequestMapper";
import { FEASIBILITY_PROPERTIES_BY_SECTIONS_MAP } from "./formsRequestMappers";

const REQUEST_BASE_VALUES = [
  {
    field: "deleted",
    additionalCheck: "id",
    value: "F"
  },
  {
    field: "state",
    value: requestStates.saved
  },
  {
    field: "show_feasibility_table_to_client",
    value: "F"
  },
  {
    field: "show_data_table_to_client",
    value: "F"
  },
  {
    field: "preanalys_report_type",
    value: ""
  },
  {
    field: "recall_preanalys_report_type",
    value: ""
  }
];

const valueIsMissing = (values, baseValue) => {
  return (
    !values[baseValue.field] &&
    (!baseValue.additionalCheck || !values[baseValue.additionalCheck])
  );
};

export const transformAttrTypesToApiFormat = ({
  values,
  stringToNumber = [], // "2010" => 2010
  numberToBoolean = [], // 1 => true, everything else false
  booleanToNumber = [] // true => 1, everything else false
}) => {
  const newValues = {};
  Object.keys(values).forEach(key => {
    if (numberToBoolean.includes(key)) {
      newValues[key] = values[key] === 1;
    } else if (stringToNumber.includes(key)) {
      newValues[key] = parseInt(values[key]);
    } else if (booleanToNumber.includes(key)) {
      newValues[key] = values[key] === true ? 1 : "";
    } else {
      newValues[key] = values[key];
    }
  });
  return newValues;
};

export const fillMissingValues = (values, id) => {
  REQUEST_BASE_VALUES.forEach(baseValue => {
    if (valueIsMissing(values, baseValue))
      values[baseValue.field] = baseValue.value;
  });
  return values;
};

/**
 * Returns a new object with an update of the values passed based on the new properties values defined on a Map
 * The Map defines a new value or an operation(function) to update the value
 */
const mapToCleanValues = (values, propMapToClean) => {
  const cleanProperties = { ...values };
  Object.keys(propMapToClean).forEach(property => {
    const newValue = propMapToClean[property];
    const currentValue = cleanProperties[property];
    cleanProperties[property] =
      typeof newValue === "function" ? newValue(currentValue) : newValue;
  });
  return cleanProperties;
};

export const cleanPropsForNonSelectedSections = values => {
  let cleanProperties = { ...values };
  const sectionSelectorsIds = [
    SELECTED_MATERIALS.DONOR,
    SELECTED_MATERIALS.PHENOTYPE,
    SELECTED_MATERIALS.SAMPLE,
    SELECTED_MATERIALS.LIQUID,
    SELECTED_MATERIALS.TISSUE
  ];

  sectionSelectorsIds.forEach(propertyName => {
    if (!cleanProperties.selected_materials.includes(propertyName)) {
      const propsMapToClean =
        FEASIBILITY_PROPERTIES_BY_SECTIONS_MAP[propertyName];
      const cleanValues = mapToCleanValues(cleanProperties, propsMapToClean);
      cleanProperties = {
        ...cleanProperties,
        ...cleanValues
      };
    }
  });

  if (!cleanProperties.sample_types.includes(specimenTypeMapping.genome_data)) {
    const propsMapToClean = FEASIBILITY_PROPERTIES_BY_SECTIONS_MAP.GENOTYPE;
    const cleanValues = mapToCleanValues(cleanProperties, propsMapToClean);
    cleanProperties = {
      ...cleanProperties,
      ...cleanValues
    };
  }
  return cleanProperties;
};
