const config = () => ({
  catalogApiUrl: process.env
    ? process.env.REACT_APP_CATALOG_API_URL
    : "https://api.test.fingenious.fi",
  platformUrl: process.env
    ? process.env.REACT_APP_PLATFORM_API_URL
    : "http://localhost:4000",
  platformWebUrl: process.env
    ? process.env.REACT_APP_PLATFORM_WEB_URL
    : "http://localhost:3000",
  requestsUrl: process.env
    ? process.env.REACT_APP_REQUESTS_API_URL
    : "http://localhost:5000",
  requestsAppUrl: process.env
    ? process.env.REACT_APP_REQUESTS_APP_URL
    : "http://localhost:3001",
  recruitAppUrl: process.env
    ? process.env.REACT_APP_RECRUIT_APP_URL
    : "http://localhost:3003",
  appUrl: process.env ? process.env.REACT_APP_URL : "http://localhost:3000",
  loginUrl: process.env ? process.env.REACT_APP_LOGIN_URL : "/user/login",
  logoutUrl: process.env ? process.env.REACT_APP_LOGOUT_URL : "/user/logout",
  fingeniousMain: process.env
    ? process.env.REACT_APP_FINGENIOUS_MAIN
    : "/catalog",
  amplitudeId: process.env ? process.env.REACT_APP_AMPLITUDE_ID : null,
  defaultLanguage: process.env ? process.env.REACT_APP_DEFAULT_LANGUAGE : "en",
  appEnv: process.env ? process.env.REACT_APP_HOST_ENV : "",
  sentryDSN: process.env ? process.env.REACT_APP_SENTRY_DSN : "",
  registrationUrl: process.env
    ? process.env.REACT_APP_REGISTRATION_URL
    : "http://localhost:3000/signup",
  enableLocalisation:
    process.env && process.env.REACT_APP_ENABLE_LOCALISATION === "true",
  contact_us_email: process.env && process.env.REACT_APP_CONTACT_US_EMAIL,
  hideCohorts: process.env && process.env.REACT_APP_HIDE_COHORTS === "true",
  auth0Domain: process.env ? process.env.REACT_APP_AUTH0_DOMAIN : "",
  auth0ClientId: process.env ? process.env.REACT_APP_AUTH0_CLIENT_ID : "",
  auth0RedirectUri: process.env
    ? process.env.REACT_APP_AUTH0_REDIRECT_URI
    : "http://localhost:3000",
  auth0AudiencePlatform: process.env
    ? process.env.REACT_APP_AUTH0_AUDIENCE_PLATFORM
    : "http://localhost:4000",
  auth0AudienceRequests: process.env
    ? process.env.REACT_APP_AUTH0_AUDIENCE_REQUESTS
    : "http://localhost:3001",

  // Vacation period defaults to 1.7. 16:00 to 1.8. 00:00
  vacationStartMonth: process.env
    ? process.env.REACT_APP_VACATION_START_MONTH
    : 7,
  vacationStartDay: process.env ? process.env.REACT_APP_VACATION_START_DAY : 1,
  vacationStartHours: process.env
    ? process.env.REACT_APP_VACATION_START_HOURS
    : 16,
  vacationStartMinutes: process.env
    ? process.env.REACT_APP_VACATION_START_MINUTES
    : 0,
  vacationEndMonth: process.env ? process.env.REACT_APP_VACATION_END_MONTH : 8,
  vacationEndDay: process.env ? process.env.REACT_APP_VACATION_END_DAY : 1,
  vacationEndHours: process.env ? process.env.REACT_APP_VACATION_END_HOURS : 0,
  vacationEndMinutes: process.env
    ? process.env.REACT_APP_VACATION_END_MINUTES
    : 0,
  requestFormsV1DeadlineDate: process.env
    ? process.env.REACT_APP_REQUEST_FORMS_V1_DEADLINE_DATE
    : "2022-12-05",
  icd10CodesLinkUrl: process.env
    ? process.env.REACT_APP_ICD10_CODES_LINK_URL
    : "https://koodistopalvelu.kanta.fi/codeserver/pages/publication-view-page.xhtml?distributionKey=12822&versionKey=58&returnLink=fromVersionPublicationList"
});

export default config();
