import { useTranslation } from "react-i18next";
import styles from "./list-item.module.scss";
import iconFile from "../../../components/FileUploader/icon_file.svg";
import React, { useRef, useState } from "react";
import { format } from "date-fns";
import StatusIndicator from "./StatusIndicator";
import requestStates from "../../../common/requestStates";
import elipsis from "../../../assets/img/elipsis-vertical.svg";
import classNames from "classnames";
import useOnClickOutside from "use-onclickoutside";
import { array, bool, func, string } from "prop-types";

const translationKeysForRequestStates = {
  [requestStates.submitted]: "submitted_for_approval",
  [requestStates.refine]: "returned_for_completion",
  [requestStates.ongoing]: "waiting_for_response",
  [requestStates.evaluation]: "waiting_for_steering_committees",
  [requestStates.responded]: "response_available",
  [requestStates.hold]: "rejected",
  [requestStates.created]: "waiting_for_response",
  [requestStates.assigned]: "waiting_for_response",
  [requestStates.reported]: "response_available",
  [requestStates.decided]: "waiting_for_response",
  [requestStates.signed]: "waiting_for_response",
  [requestStates.transfer]: "waiting_for_response",
  [requestStates.completed]: "response_available",
  [requestStates.returned]: "response_available"
};

const getTranslationKeyForStatus = (type, status) => {
  if (status === requestStates.saved) {
    return type === "feasibility-request"
      ? "new_feasibility_request"
      : "new_access_request";
  }

  return translationKeysForRequestStates[status];
};

const formatTimestamp = timestamp =>
  format(new Date(timestamp), "dd.MM.yyyy HH:mm:ss");

const Options = ({ options }) => {
  // handle clicking outside menu collapse stuff
  const divRef = useRef();
  const buttonRef = useRef();
  const [menuActive, setMenuActive] = useState(false);
  useOnClickOutside(divRef, e => {
    if (!buttonRef.current.contains(e.target)) {
      setMenuActive(false);
    }
  });

  return (
    <div className={styles.listOptions}>
      <button
        className={styles.listOptionsDropdownButton}
        ref={buttonRef}
        onClick={e => {
          setMenuActive(!menuActive);
          e.preventDefault();
        }}
      >
        <img src={elipsis} alt="" />
      </button>
      <div ref={divRef}>
        <OptionsDropdown
          active={menuActive}
          toggle={setMenuActive}
          options={options}
        />
      </div>
    </div>
  );
};

Options.propTypes = {
  options: array.isRequired
};

const OptionsDropdown = ({ active, options = [] }) => {
  const dropdownClasses = classNames(styles.listOptionsDropdown, {
    [styles.listOptionsDropdownVisible]: active
  });

  return (
    <ul className={dropdownClasses}>
      {options.map((option, k) => (
        <Option key={k} {...option} />
      ))}
    </ul>
  );
};

OptionsDropdown.propTypes = {
  active: bool,
  options: array
};

const Option = ({ label, onClick }) => (
  <li>
    <button className={styles.listOptionsDropdownButton} onClick={onClick}>
      {label}
    </button>
  </li>
);

Option.propTypes = {
  label: string.isRequired,
  onClick: func.isRequired
};

const ListItem = ({
  title,
  createdTimestamp,
  updatedTimestamp,
  status,
  type,
  options = [],
  projectId,
  requestId
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.listItem}>
      <img className={styles.icon} src={iconFile} alt={title} />
      <div className={styles.projectInfo}>
        <h2>{title}</h2>
        <p className={styles.createdAt}>
          {projectId && `${t("project")} #${projectId} - `}
          {t("created_at")} {formatTimestamp(createdTimestamp)}
          {updatedTimestamp &&
            createdTimestamp !== updatedTimestamp &&
            ` - ${t("updated_at")} ${formatTimestamp(updatedTimestamp)}`}
        </p>
      </div>
      <div className={styles.statusContainer}>
        <p className={styles.status}>
          {t(getTranslationKeyForStatus(type, status))}
        </p>
        <StatusIndicator status={status} projectType={type} />
      </div>
      {options.length && <Options options={options} />}
    </div>
  );
};

ListItem.propTypes = {
  title: string.isRequired,
  createdTimestamp: string.isRequired,
  updatedTimestamp: string,
  status: string,
  type: string.isRequired,
  options: array.isRequired,
  projectId: string
};

export default ListItem;
