import config from "../config";
import axios from "../helpers/axios";
import { logError } from "../helpers/logger";
import {
  FETCH_MIABIS_DATA_CATEGORIES,
  FETCH_MIABIS_DATA_CATEGORIES_FAILURE,
  FETCH_MIABIS_DATA_CATEGORIES_SUCCESS
} from "./miabis-data-categories.actions";

export const requestMiabisDataCategories = function(target) {
  return {
    type: FETCH_MIABIS_DATA_CATEGORIES,
    target
  };
};

export const requestMiabisDataCategoriesSuccess = function(target, results) {
  return {
    type: FETCH_MIABIS_DATA_CATEGORIES_SUCCESS,
    target,
    results
  };
};

export const requestMiabisDataCategoriesFailure = function(target) {
  return {
    type: FETCH_MIABIS_DATA_CATEGORIES_FAILURE,
    target
  };
};

export const fetchMiabisDataCategories = function(target) {
  return async function(dispatch) {
    dispatch(requestMiabisDataCategories(target));

    const url = `${config.catalogApiUrl}/v1/miabis-data-categories/`;

    try {
      const response = await axios.get(url);
      dispatch(
        requestMiabisDataCategoriesSuccess(
          target,
          response.data.miabisDataCategories
        )
      );
    } catch (error) {
      logError(error);
      dispatch(requestMiabisDataCategoriesFailure(target));
    }
  };
};

export const fetchMiabisDataCategoriesWithCohortCollections = function(target) {
  return async function(dispatch) {
    dispatch(requestMiabisDataCategories(target));

    const url = `${config.catalogApiUrl}/v1/miabis-data-categories?cohorts`;

    try {
      const response = await axios.get(url);
      const miabisDataCategories = response.data.miabisDataCategories;
      miabisDataCategories.forEach(category => {
        category.cohort_collections.sort((a, b) =>
          a.name.localeCompare(b.name, "fi", { ignorePunctuation: true })
        );
      });

      dispatch(
        requestMiabisDataCategoriesSuccess(target, miabisDataCategories)
      );
    } catch (error) {
      logError(error);
      dispatch(requestMiabisDataCategoriesFailure(target));
    }
  };
};
