import React from "react";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";
import InfoModalButton from "./InfoModalButton";

// TODO: check why passing in field.field as the key is not coming as a unique key
const FilterGroup = ({
  filters,
  tooltip,
  groupId,
  groupTitle,
  active,
  enabled,
  onSelectFilter
}) => {
  const { t } = useTranslation();
  return (
    <div className="filter-group">
      <div className="title">
        {t("filter_group_title_" + groupId)}{" "}
        {tooltip ? (
          <InfoModalButton text={t("filter_group_tooltip_" + groupId)} />
        ) : null}
      </div>
      <ul className={enabled === false ? "disabled" : ""}>
        {filters.map(filter => {
          return (
            <Filter
              key={filter.id}
              group={groupId}
              id={filter.id}
              label={filter.label}
              onSelectFilter={onSelectFilter}
              selected={active && active.includes(filter.id)}
              enabled={enabled}
            ></Filter>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterGroup;
