import { RequestSection } from "../../RequestSection";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

export const BioBanksSection = ({
  formProps,
  feasibilityRequestLabel: FeasibilityRequestLabel,
  feasibilityRequestCheckBox: FeasibilityRequestCheckBox,
  feasibilityRequestFormControl: FeasibilityRequestFormControl
}) => {
  const { t } = useTranslation();

  return (
    <RequestSection
      title={t("biobanks")}
      description={t("biobanks_description")}
    >
      <FeasibilityRequestLabel
        withErrorIndicator
        formProps={formProps}
        fieldName={"included_biobanks"}
      >
        {t("choose_biobanks")}
      </FeasibilityRequestLabel>
      <Form.Group id="formIncludedHospitalBiobanks">
        <FeasibilityRequestLabel
          withErrorIndicator
          formProps={formProps}
          fieldName={"included_biobanks"}
        >
          {t("hospital_biobanks")}
        </FeasibilityRequestLabel>
        <FeasibilityRequestCheckBox
          label={t("auria_biobank")}
          name={"included_biobanks"}
          value={"10"}
        />
        <FeasibilityRequestCheckBox
          label={t("helsinki_biobank")}
          name={"included_biobanks"}
          value={"20"}
        />
        <FeasibilityRequestCheckBox
          label={t("biobank_of_eastern_finland")}
          name={"included_biobanks"}
          value={"30"}
        />
        <FeasibilityRequestCheckBox
          label={t("central_finland_biobank")}
          name={"included_biobanks"}
          value={"40"}
        />
        <FeasibilityRequestCheckBox
          label={t("northern_finland_biobank_borealis")}
          name={"included_biobanks"}
          value={"50"}
        />
        <FeasibilityRequestCheckBox
          label={t("tampere_biobank")}
          name={"included_biobanks"}
          value={"60"}
        />
      </Form.Group>
      <Form.Group id="formIncludedCohortBiobanks">
        <FeasibilityRequestLabel
          withErrorIndicator
          formProps={formProps}
          fieldName={"included_biobanks"}
        >
          {t("cohort_biobanks")}
        </FeasibilityRequestLabel>
        <FeasibilityRequestCheckBox
          label={t("arctic_biobank")}
          name={"included_biobanks"}
          value={"90"}
        />
        <FeasibilityRequestCheckBox
          label={t("thl_biobank")}
          name={"included_biobanks"}
          value={"80"}
        />
      </Form.Group>
      <Form.Group controlId="formSelectionCartsCohorts">
        <FeasibilityRequestFormControl
          as={"textarea"}
          name={"selected_cohort_names"}
          label={t("specify_cohorts_of_interest")}
        />
      </Form.Group>
    </RequestSection>
  );
};
