import { useTranslation } from "react-i18next";
import { Form, FormGroup } from "react-bootstrap";
import { RequestSection } from "../RequestSection";
import { PathologistContactInfoSection } from "./PathologistContactInfoSection";
import { specimenTypeMapping } from "../../helpers/feasibilityRequestMapper";

export const TissueSamplesSection = ({
  formProps,
  label: Label,
  checkBox: Checkbox,
  formControl: FormControl,
  isAccessRequest = false
}) => {
  const { t } = useTranslation();
  const { values } = formProps;

  const isEnabled =
    values.selected_materials.includes("samples") &&
    values.selected_materials.includes("tissue_samples");

  return (
    isEnabled && (
      <RequestSection
        title={t("tissue_samples")}
        description={t("tissue_samples_description")}
      >
        <Form.Group>
          <Checkbox
            label={t("ffpe_tissue")}
            name={"sample_types"}
            value={specimenTypeMapping.ffpe_tissue}
          />
          <Checkbox
            label={t("tma_slides_block")}
            name={"sample_types"}
            value={specimenTypeMapping.tma_slides}
          />
          <Checkbox
            label={t("fresh_frozen_tissue")}
            name={"sample_types"}
            value={specimenTypeMapping.fresh_frozen_tissue}
          />
          <Checkbox
            label={t("digitized_scanned_slides")}
            name={"sample_types"}
            value={specimenTypeMapping.digitized_scanned_slides}
          />
        </Form.Group>

        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"inclusion_criteria_for_tissue_samples"}
          >
            <h5>{t("inclusion_criteria")}</h5>
          </Label>
          <FormControl
            as={"textarea"}
            name={"tissue_sample_inclusion_criteria"}
            label={t("tissue_sample_inclusion_criteria_label")}
            placeholder={t("tissue_sample_inclusion_criteria_eg")}
          />
        </Form.Group>

        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"exclusion_criteria_for_tissue_samples"}
          >
            <h5>{t("exclusion_criteria")}</h5>
          </Label>
          <FormControl
            as={"textarea"}
            name={"tissue_sample_exclusion_criteria"}
            label={t("tissue_sample_exclusion_criteria_label")}
            placeholder={t("tissue_sample_exclusion_criteria_eg")}
          />
        </Form.Group>

        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"tissue_sample_additional_info"}
          >
            <h5>{t("tissue_sample_additional_info")}</h5>
          </Label>
          <FormControl
            as={"textarea"}
            name={"tissue_sample_additional_info"}
            label={t("tissue_sample_additional_info_label")}
            placeholder={t("tissue_sample_additional_info_eg")}
          />
        </Form.Group>

        {isAccessRequest && (
          <FormGroup>
            <Label
              withErrorIndicator
              formProps={formProps}
              fieldName={"label_pathologist_resources"}
            >
              <h5>{t("pathologist_resources")}</h5>
              <p>{t("pathologist_resources_sub_heading")}</p>
              <p>{t("pathologist_resources_desc")}</p>
            </Label>
            <Checkbox
              label={t("pathologist_appointed_by_biobanks")}
              name={"pathologist_by_biobank"}
              value={1}
            />
            <Checkbox
              label={t("pathologist_specialising_type_of_disease")}
              name={"pathologist_by_specialisation"}
              value={1}
            />
            <Label
              withErrorIndicator
              formProps={formProps}
              fieldName={"label_pathologist_contact_details"}
            >
              <h5>{t("pathologist_contact_details_label")}</h5>
            </Label>
            <PathologistContactInfoSection formControl={FormControl} />
          </FormGroup>
        )}
      </RequestSection>
    )
  );
};
