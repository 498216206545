import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { RequestSection } from "../RequestSection";
import { maxProjectDescriptionLength } from "../../helpers/formSchemaHelper";
import { FeasibilityRequestPricing } from "../Feasibilityrequest/FeasibilityRequestPricing";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SelectYearField } from "../SelectYearField";
import { SelectField } from "../SelectField";

export const ProjectDetailsSection = ({
  formProps,
  label: Label,
  switch: Switch,
  formControl: FormControl,
  isAccessRequest = false,
  datePicker: DatePicker,
  disabled
}) => {
  const { t } = useTranslation();
  const {
    values: { budget_until_year: budgetUntilYear }
  } = formProps;

  const labelPrefix = isAccessRequest ? "access" : "feasibility";

  return (
    <RequestSection
      title={t("project_details")}
      description={t("project_details_description")}
    >
      <Form.Group controlId="formProjectName">
        <FormControl
          name={"projektin_nimi"}
          label={t("full_name_of_project")}
        />
      </Form.Group>

      <Form.Group controlId="formProjectDescription">
        <FormControl
          as={"textarea"}
          name={"brief_project_desc"}
          placeholder={t(`${labelPrefix}_project_description`)}
          label={t("project_description", { maxProjectDescriptionLength })}
        />
      </Form.Group>

      {!isAccessRequest && (
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"timeline_target"}
        >
          <h5>{t("timeline_and_budget_title")}</h5>
        </Label>
      )}

      {isAccessRequest && (
        <>
          <Form.Group controlId="formStartMonthYear">
            <DatePicker
              disabled={disabled}
              namePrefix="starting_"
              label={t("arForm_starting_monthyear_label")}
            />
          </Form.Group>
          <Form.Group controlId="formStartMonthYear">
            <DatePicker
              disabled={disabled}
              namePrefix="ending_"
              label={t("arForm_ending_monthyear_label")}
            />
          </Form.Group>
        </>
      )}

      <Form.Group>
        <Row>
          <Col lg={5}>
            <Label
              withErrorIndicator
              formProps={formProps}
              fieldName={"label_timeline_target"}
            >
              <h6 className={"margin-top-none"}>
                {t(`${labelPrefix}_time_constrain_label`)}
              </h6>
              <span className={"sub-section-description"}>
                {t(`${labelPrefix}_time_constrain_label_desc`)}
              </span>
            </Label>
          </Col>
          <Col lg={7}>
            <Form.Group>
              <SelectField
                name={"project_timeline_months"}
                options={[
                  t("2_3_months_after_completed_submission"),
                  t("3_5_months_after_completed_submission"),
                  t("5_7_months_after_completed_submission"),
                  t("7_or_more_months_after_completed_submission")
                ]}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg={5}>
            <Label
              withErrorIndicator
              formProps={formProps}
              fieldName={"label_have_budget"}
            >
              <h6 className={"margin-top-sm"}>{t("budget_target_label")}</h6>
            </Label>
          </Col>
          <Col lg={2}>
            <Switch
              className={"margin-top-sm"}
              label={t("no_or_yes")}
              name={"have_budget"}
              value={"1"}
            />
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Row>
          <Col lg={5}>
            <Label
              withErrorIndicator
              formProps={formProps}
              fieldName={"label_budget_timeline"}
            >
              <h6 className={"margin-top-sm"}>{t("budget_timeline_label")}</h6>
            </Label>
          </Col>
          <Col lg={2}>
            <Form.Group>
              <SelectYearField
                fieldName={"budget_until_year"}
                selectedYear={budgetUntilYear}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form.Group>

      <Form.Group>
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"budget_description"}
        >
          <h6>{t("budget_description_title")}</h6>
        </Label>
        <FormControl
          as={"textarea"}
          placeholder={t("budget_description_label")}
          name={"budget"}
        />
      </Form.Group>

      {!isAccessRequest && (
        <Form.Group>
          <Label
            formProps={formProps}
            fieldName={"feasibility_request_pricing_info"}
          >
            <h5>{t("feasibility_request_pricing_info_label")}</h5>
            {/** TODO: Retrieve and pass the type industry or researcher */}
            <FeasibilityRequestPricing requesType={formProps.c_org_type} />
          </Label>
        </Form.Group>
      )}

      {isAccessRequest && (
        <Form.Group controlId="formPublicDescEng">
          <FormControl
            as={"textarea"}
            name={"project_desc"}
            placeholder={t(`sub_label_public_desc_english`)}
            label={t("label_public_desc_english")}
          />
        </Form.Group>
      )}

      {isAccessRequest && (
        <Form.Group controlId="formPublicDescFin">
          <FormControl
            as={"textarea"}
            name={"project_summary"}
            placeholder={t(`sub_label_public_desc_finnish`)}
            label={t("label_public_desc_finnish")}
          />
        </Form.Group>
      )}
    </RequestSection>
  );
};
