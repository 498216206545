import {
  Card,
  LinkButton,
  LoadingIndicator,
  SeparatorLine
} from "@finbb/ui-components";
import { IconListSearch } from "@tabler/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { fetchMiabisDataCategoriesWithCohortCollections } from "../../actions/miabis-data-categories";
import MiabisCategoryIcon from "../../components/MiabisCategoryIcon/MiabisCategoryIcon";
import { translateMiabisEntityName } from "../../helpers/field-translate-util";
import InfoModal from "../../components/CohortCollectionSearchPage/CohortsInfoModal";
import {
  StyledCardList,
  StyledCardListItem,
  StyledCategory,
  StyledCategoryHeading,
  StyledCohortCollectionListPage,
  StyledFetchError,
  StyledMaxWidthRow,
  StyledPageHeading,
  StyledInfoModalButton,
  HeaderColumn
} from "./CohortCollectionListPage.styles";

const CohortCollectionListPage = ({
  fetchMiabisDataCategoriesWithCohortCollections,
  miabisDataCategoriesState
}) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const params = useParams();
  const {
    t,
    i18n: { language }
  } = useTranslation();

  useEffect(() => {
    fetchMiabisDataCategoriesWithCohortCollections(params.target);
  }, [fetchMiabisDataCategoriesWithCohortCollections, params]);

  if (miabisDataCategoriesState.loading) {
    return <LoadingIndicator />;
  }

  if (miabisDataCategoriesState.error) {
    return (
      <StyledFetchError>{t("cohort_collection_list.error")}</StyledFetchError>
    );
  }

  const categoriesWhichHaveCohorts = miabisDataCategoriesState.miabisDataCategories.filter(
    miabisDataCategory => {
      if (!miabisDataCategory.cohort_collections) {
        return false;
      }

      return miabisDataCategory.cohort_collections.length > 0;
    }
  );

  const toggleCohortsInfoModal = () => {
    setShowInfoModal(!showInfoModal);
  };

  return (
    <StyledCohortCollectionListPage>
      <StyledMaxWidthRow>
        <HeaderColumn>
          <StyledPageHeading>
            {t("cohort_collection_list.collections")}
          </StyledPageHeading>
          <StyledInfoModalButton onClick={toggleCohortsInfoModal}>
            {t("about_cohorts")}
          </StyledInfoModalButton>
        </HeaderColumn>
        <LinkButton
          tablerIcon={IconListSearch}
          to={`/cohorts/search`}
          text={t("cohort_collection_list.search")}
          variant={"CALL_TO_ACTION"}
        />
      </StyledMaxWidthRow>
      {showInfoModal && (
        <InfoModal title={"About cohorts"} onClose={toggleCohortsInfoModal} />
      )}
      {categoriesWhichHaveCohorts.map((miabisDataCategory, index) => (
        <StyledCategory key={miabisDataCategory.id}>
          <StyledCategoryHeading>
            <MiabisCategoryIcon
              miabisId={miabisDataCategory.id}
              isLarge
              isColorful
            />
            <span>
              {translateMiabisEntityName(miabisDataCategory, language)}
            </span>
          </StyledCategoryHeading>

          <StyledCardList>
            {miabisDataCategory.cohort_collections.map(cohortCollection => {
              const collectionCategoryIcons = cohortCollection.data_categories.map(
                dataCategory => ({
                  title: translateMiabisEntityName(dataCategory, language),
                  icon: <MiabisCategoryIcon miabisId={dataCategory.id} />
                })
              );

              return (
                <StyledCardListItem key={cohortCollection.id}>
                  <Card
                    imageSrc={cohortCollection.image_url}
                    properties={collectionCategoryIcons}
                    content={{
                      title: cohortCollection.name,
                      description: cohortCollection.description
                    }}
                    linkTo={`${cohortCollection.id}`}
                  />
                </StyledCardListItem>
              );
            })}
          </StyledCardList>

          {index !== categoriesWhichHaveCohorts.length - 1 && (
            <SeparatorLine isSubtle />
          )}
        </StyledCategory>
      ))}
    </StyledCohortCollectionListPage>
  );
};

const mapStateToProps = state => ({
  miabisDataCategoriesState: state.miabisDataCategories
});

export default connect(mapStateToProps, {
  fetchMiabisDataCategoriesWithCohortCollections
})(CohortCollectionListPage);
