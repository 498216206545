import React from "react";
import { getStaticUrl } from "../../helpers/linkGenerator";

export const mtaCentralTermsUrl = `${getStaticUrl()}/mta-central-terms`;

const MTACentralTerms = () => {
  return (
    <div className="finbb-container">
      <h1>
        CENTRAL TERMS IN FINNISH BIOBANK SAMPLE, DATA AND SERVICES AGREEMENTS
        (“MTA”)
      </h1>

      <p>
        Finnish biobanks are licensed biobank authorities governed by the
        Biobank Act (688/2012). The Act and the biobanks’ informed consent forms
        require certain terms to be included in contracts.
      </p>

      <h2>Rights to material</h2>
      <p>
        Biobanks can provide material for research aiming at: (i) promoting
        health, (ii) understanding disease mechanisms, and (iii) developing
        products, services or treatment practices for health care. The material
        cannot be used for anything else.
      </p>

      <h2>Obligation to share analysis data</h2>
      <p>
        Researchers must return data they get from the biobank samples to the
        biobank to be used in other research projects. The schedule and other
        details will be agreed in the MTA.
      </p>

      <h2>Obligation to share individual results</h2>
      <p>
        Sample donors have a statutory right to get any information derived from
        their samples and related to their health. The researchers must provide
        the biobank with this kind of sample-specific results at the biobank’s
        request.
      </p>

      <h2>Obligation to make results public</h2>
      <p>
        Results from research using biobank material must be made public by some
        means (after steps to protect IP, where necessary) and the biobank(s)
        role acknowledged. The schedule and details will be agreed in the MTA.
      </p>

      <h2>Confidentiality and privacy</h2>
      <p>
        The researchers must protect the material from unauthorised disclosure.
        Data protection authorities have opined that even de-identified material
        must be handled as identifiable personal data. Thus, also transfers
        outside of EU are subject to the GDPR and may require for example the EU
        Commission’s standard contractual clauses in the MTA.
      </p>

      <h2>Data controller</h2>
      <p>
        The research institution will normally be data controller for the
        research data. However, if a biobank performs all the research as a
        service, for example, they may be the controller, or in a shared project
        the parties may be joint controllers.
      </p>

      <h2>Immaterial Property Rights</h2>
      <p>
        Biobanks do not normally claim new IPR in research results created by or
        for the researchers but will get rights to the data (see above
        obligation so share data and results). To protect biobank donors and
        taxpayers from claims based on IPR created using material collected from
        them, biobanks require a limited covenant not to sue. This is not a
        release from having to obtain licences where needed.
      </p>

      <h2>Withdrawal of consent</h2>
      <p>
        The biobank donors can at any time withdraw their consent. If this
        happens the biobank will inform the researchers who must, if legally
        mandatory and also otherwise if reasonable remove the corresponding
        material from their research.
      </p>

      <h2>Limitation of liability</h2>
      <p>
        MTAs will have mutual limitations of liability, excluding privacy and
        confidentiality breaches and damage through gross negligence or wilful
        misconduct.
      </p>

      <h2>Applicable law and jurisdiction</h2>
      <p>
        MTAs are subject to Finnish law, which also governs the biobank
        activities, and Finnish courts.
      </p>

      <div className="mta-footer">
        <div>
          <span>Finnish Biobank Cooperative – FINBB</span>
          <span>Y-tunnus 2853268-2</span>
        </div>
        <div>
          <span>Regus, Ruukinkatu 2-4, 20540 Turku</span>
          <span>FI37 5716 9020 0605 34, OKOYFIHH</span>
        </div>
      </div>
    </div>
  );
};

export default MTACentralTerms;
