import { RequestSection } from "../../RequestSection";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { SELECTED_MATERIALS } from "../../../common/selectedMaterials";

export const RecruitSection = ({
  formProps,
  feasibilityRequestFormControl: FeasibilityRequestFormControl,
  feasibilityRequestLabel: FeasibilityRequestLabel,
  children
}) => {
  const { t } = useTranslation();
  const { values } = formProps;
  const isEnabled = values.selected_materials.includes(
    SELECTED_MATERIALS.DONOR
  );

  return (
    isEnabled && (
      <RequestSection
        title={t("recruit")}
        description={t("recruit_description")}
      >
        <FeasibilityRequestFormControl
          as={"textarea"}
          name={"recruitment_desc"}
          label={t("recruitment_label")}
        />
        <Form.Group>
          <FeasibilityRequestLabel
            formProps={formProps}
            fieldName={"attachements"}
          >
            <h5>{t("add_attachments")}</h5>
          </FeasibilityRequestLabel>
          {children}
        </Form.Group>
      </RequestSection>
    )
  );
};
