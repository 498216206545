import { useAuth0 } from "@auth0/auth0-react";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import config from "../../config";
import {
  EXPERT_FILTERS_KEY,
  FILTER_VISIBLE_KEY,
  getItemFromStorage,
  SEARCH_KEY,
  types
} from "../../helpers/storageHelper";
import { filterSimilarStrings } from "../../helpers/utils";
import useUser from "../../hooks/useUser";
import {
  StyledCenteredColumn,
  StyledExpertsSearchPage,
  StyledFilterButtonContainer,
  StyledFilterContainer,
  StyledMaxWidthRow,
  StyledResultBox,
  StyledSearchContainer,
  StyledSearchInput
} from "./ExpertsSearchPage.styles";
import { Button, MultiSelect } from "@finbb/ui-components";
import { IconFilter, IconFilterOff } from "@tabler/icons";
import { connect, useSelector } from "react-redux";
import { fetchExpertProfileCategories } from "../../actions/expert-profile-categories";
import { searchExpertProfiles } from "../../actions/expert-profiles";
import { debounce } from "lodash";
import {
  StyledFilterColumn,
  StyledFilterName
} from "../../components/CohortCollectionSearchPage/MiabisFilterList.styles";

const SIMILARITY_THRESHOLD = 0.5;
const PLATFORM_LINK_ALLOWED_ROLES = [
  "finbb-admin",
  "finbb-coordinator",
  "organisation-coordinator"
];

class ExpertFilters {
  constructor(filters) {
    this.query = filters?.query;
    this.therapyAreas = filters?.therapyAreas;
    this.researchExpertises = filters?.researchExpertises;
    this.researchPhases = filters?.researchPhases;
    this.roles = filters?.roles;
    this.icd10Codes = filters?.icd10Codes;
    this.rareNeurologicalDiseases = filters?.rareNeurologicalDiseases;
  }

  isEmpty() {
    return Object.values(this).every(value => !value);
  }

  toQueryParams() {
    const filters = this.getFilters();
    return Object.keys(filters)
      .map(key => {
        const value = filters[key];

        if (value === undefined || value === null) {
          return undefined;
        }

        if (value instanceof Array) {
          return value.map(v => `${key}[]=${encodeURIComponent(v)}`).join("&");
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .filter(value => value !== undefined)
      .join("&");
  }

  getFilters() {
    const filters = {};
    if (this.query) {
      filters.query = this.query;
    }
    if (this.therapyAreas?.length) {
      filters.therapyAreas = this.therapyAreas;
    }
    if (this.researchExpertises?.length) {
      filters.researchExpertises = this.researchExpertises;
    }
    if (this.researchPhases?.length) {
      filters.researchPhases = this.researchPhases;
    }
    if (this.roles?.length) {
      filters.roles = this.roles;
    }
    if (this.icd10Codes?.length) {
      filters.icd10Codes = this.icd10Codes;
    }

    if (this.rareNeurologicalDiseases?.length) {
      filters.rareNeurologicalDiseases = this.rareNeurologicalDiseases;
    }

    return filters;
  }
}

const CategoryFilter = memo(({ label, categories, onChanged, value }) => {
  const [inputValue, setInputValue] = useState("");

  const options = categories.map(category => ({
    name: category.name,
    value: category.id
  }));

  const filteredOptions =
    inputValue?.length > 0
      ? options.filter(option =>
          option.name.toLowerCase().includes(inputValue?.toLowerCase())
        )
      : options;

  return (
    <StyledFilterColumn>
      <StyledFilterName>{label}</StyledFilterName>
      <MultiSelect
        name={label}
        value={value ?? []}
        onChanged={onChanged}
        onInputChanged={input => setInputValue(input)}
        allowNewValues={false}
        options={filteredOptions}
      />
    </StyledFilterColumn>
  );
});

const ExpertsSearchPage = ({
  fetchExpertProfileCategories,
  therapyAreas,
  researchExpertises,
  researchPhases,
  roles,
  icd10Codes,
  rareNeurologicalDiseases,
  searchExpertProfiles
}) => {
  const { t } = useTranslation();
  const experts = useSelector(state => state.expertProfiles.expertProfiles);
  const [expertFilters, setExpertFilters] = useState(
    new ExpertFilters(getItemFromStorage(EXPERT_FILTERS_KEY, types.object))
  );

  const [initialExpertFilters, setInitialExpertFilters] = useState(
    new ExpertFilters(getItemFromStorage(EXPERT_FILTERS_KEY, types.object))
  );

  const [resetCount, setResetCount] = useState(0);

  const [searchTerm, setSearchTerm] = useState(
    getItemFromStorage(SEARCH_KEY, types.string)
  );

  const updateSearchTerm = term => {
    setSearchTerm(term);
    sessionStorage.setItem(SEARCH_KEY, term.toString());
  };

  const [areFiltersVisible, setFiltersVisible] = useState(
    getItemFromStorage(FILTER_VISIBLE_KEY, types.boolean)
  );

  const updateFiltersVisible = isVisible => {
    setFiltersVisible(isVisible);
    sessionStorage.setItem(FILTER_VISIBLE_KEY, isVisible.toString());
  };

  const resetExpertFilters = () => {
    sessionStorage.removeItem(EXPERT_FILTERS_KEY);
    setExpertFilters(new ExpertFilters({}));
    setInitialExpertFilters(new ExpertFilters({}));
    setResetCount(prev => prev + 1);
  };

  const clearFilters = () => {
    updateSearchTerm("");
    resetExpertFilters();
  };

  const updateExpertFilters = expertFilters => {
    const existingFilters = getItemFromStorage(
      EXPERT_FILTERS_KEY,
      types.object
    );
    const updatedFilters = new ExpertFilters({
      ...existingFilters,
      ...expertFilters
    });
    sessionStorage.setItem(EXPERT_FILTERS_KEY, JSON.stringify(updatedFilters));

    setExpertFilters(updatedFilters);
    searchExpertProfiles(updatedFilters.getFilters());
  };

  const changeSearchTerm = debounce(e => {
    const value = e.target.value ? e.target.value : undefined;
    updateSearchTerm(e.target.value);
    updateExpertFilters({ query: value });
  }, 300);

  useEffect(() => {
    fetchExpertProfileCategories();
  }, [fetchExpertProfileCategories]);

  const { user } = useAuth0();
  const { data: userData } = useUser(user.sub);
  const isPlatformLinkAllowed =
    userData?.roles?.some(role => PLATFORM_LINK_ALLOWED_ROLES.includes(role)) ||
    false;
  const expertKeywordEntries = experts.map(expert => expert.keywords).flat();
  const expertProfileCategories = [
    ...therapyAreas.filter(c =>
      experts.some(e => e.therapyAreas.map(t => t.id).includes(c.id))
    ),
    ...researchExpertises.filter(c =>
      experts.some(e => e.researchExpertises.map(t => t.id).includes(c.id))
    ),
    ...researchPhases.filter(c =>
      experts.some(e => e.researchPhases.map(t => t.id).includes(c.id))
    ),
    ...roles.filter(c =>
      experts.some(e => e.roles.map(t => t.id).includes(c.id))
    ),
    ...icd10Codes.filter(c =>
      experts.some(e => e.icd10Codes.map(t => t.id).includes(c.id))
    ),
    ...rareNeurologicalDiseases.filter(c =>
      experts.some(e =>
        e.rareNeurologicalDiseases.map(t => t.id).includes(c.id)
      )
    )
  ];

  const categoriesAndKeywords = expertKeywordEntries
    .map(entry => entry.value)
    .concat(expertProfileCategories.map(category => category.name));

  const keywords = filterSimilarStrings({
    filterWith: searchTerm,
    filterFrom: Array.from(new Set(categoriesAndKeywords)),
    similarityThreshold: SIMILARITY_THRESHOLD
  });

  const availableExperts = experts.length;
  const hasAvailableExperts = !!experts.length;

  const queryParams = expertFilters.toQueryParams();
  const platformExpertsUrl = `${config.platformWebUrl}/expert-profiles`;
  const platformWebLink = `${platformExpertsUrl}?${queryParams}`;

  const displayResults =
    searchTerm?.length || Object.keys(expertFilters.getFilters()).length !== 0;

  return (
    <StyledExpertsSearchPage>
      <StyledMaxWidthRow>
        <StyledSearchContainer>
          <StyledSearchInput
            key={`search-input-${resetCount}`}
            className="search-box"
            onChange={e => changeSearchTerm(e)}
            placeholder={t("experts_search.search_placeholder")}
          />
        </StyledSearchContainer>
      </StyledMaxWidthRow>
      <StyledMaxWidthRow>
        <StyledSearchContainer>
          <div className="label">
            <strong>
              {t("experts_search.search_call_to_action_emphasis")}
            </strong>
            {t("experts_search.search_call_to_action")}
          </div>
        </StyledSearchContainer>
      </StyledMaxWidthRow>
      <StyledMaxWidthRow isHidden={!areFiltersVisible}>
        <StyledFilterContainer isHidden={!areFiltersVisible}>
          <CategoryFilter
            label={t("experts_search.therapy_areas")}
            categories={therapyAreas}
            key={`therapy-areas-${resetCount}`}
            value={initialExpertFilters.therapyAreas}
            onChanged={newValue =>
              updateExpertFilters({ therapyAreas: newValue })
            }
          />
          <CategoryFilter
            label={t("experts_search.research_expertises")}
            categories={researchExpertises}
            key={`research-expertises-${resetCount}`}
            value={initialExpertFilters.researchExpertises}
            onChanged={newValue =>
              updateExpertFilters({ researchExpertises: newValue })
            }
          />
          <CategoryFilter
            label={t("experts_search.research_phases")}
            categories={researchPhases}
            key={`research-phases-${resetCount}`}
            value={initialExpertFilters.researchPhases}
            onChanged={newValue =>
              updateExpertFilters({ researchPhases: newValue })
            }
          />
          <CategoryFilter
            label={t("experts_search.roles")}
            categories={roles}
            key={`roles-${resetCount}`}
            value={initialExpertFilters.roles}
            onChanged={newValue => updateExpertFilters({ roles: newValue })}
          />
          <CategoryFilter
            label={t("experts_search.icd10_codes")}
            categories={icd10Codes}
            key={`icd10-codes-${resetCount}`}
            value={initialExpertFilters.icd10Codes}
            onChanged={newValue =>
              updateExpertFilters({ icd10Codes: newValue })
            }
          />
          <CategoryFilter
            label={t("experts_search.rare_neurological_diseases")}
            categories={rareNeurologicalDiseases}
            key={`rare-neurological-diseases-${resetCount}`}
            value={initialExpertFilters.rareNeurologicalDiseases}
            onChanged={newValue =>
              updateExpertFilters({ rareNeurologicalDiseases: newValue })
            }
          />
        </StyledFilterContainer>
      </StyledMaxWidthRow>
      <StyledFilterButtonContainer>
        {areFiltersVisible && (
          <span id="hide-filters-button">
            <Button
              onClick={clearFilters}
              tablerIcon={IconFilterOff}
              text={t("cohort_collection_search.clear_filters")}
              variant="UNOBTRUSIVE_CAUTION"
            />
          </span>
        )}
        <Button
          onClick={() => updateFiltersVisible(!areFiltersVisible)}
          tablerIcon={IconFilter}
          text={t(
            areFiltersVisible
              ? "cohort_collection_search.hide_filters"
              : "cohort_collection_search.show_filters"
          )}
          variant="UNOBTRUSIVE"
        />
      </StyledFilterButtonContainer>

      <StyledMaxWidthRow>
        <StyledCenteredColumn>
          <HorizontalLine />
          <StyledResultBox>
            {displayResults ? (
              <>
                <div className="search-info">
                  <div className="info">
                    <div className="title">
                      {t("experts_search.search_results")}
                    </div>
                    {searchTerm?.length > 0 && (
                      <div className="label">
                        {t("experts_search.search_info_free_text", {
                          term: searchTerm
                        })}
                      </div>
                    )}
                  </div>

                  {searchTerm?.length > 0 && (
                    <div className="label">
                      {t("experts_search.search_info_matches", {
                        keywords: keywords.join(", ")
                      })}
                    </div>
                  )}
                </div>
                <div className="search-results">
                  <div className="title">{availableExperts}</div>
                  <div className="label">
                    {t(
                      availableExperts === 1
                        ? "experts_search.number_of_results"
                        : "experts_search.number_of_results_plural"
                    )}
                  </div>
                  {hasAvailableExperts && isPlatformLinkAllowed && (
                    <div>
                      <a href={platformWebLink} className="nav-link">
                        {t("experts_search.search_result_details")}
                      </a>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="search-info">
                  <div className="info">
                    <div className="title">
                      {t("experts_search.description_short")}
                    </div>
                    <div className="label">
                      {t("experts_search.description_long")}
                    </div>
                  </div>
                </div>
              </>
            )}
          </StyledResultBox>
        </StyledCenteredColumn>
      </StyledMaxWidthRow>
    </StyledExpertsSearchPage>
  );
};

const mapStateToProps = state => ({
  therapyAreas: state.expertProfileCategories.therapyAreas,
  researchExpertises: state.expertProfileCategories.researchExpertises,
  researchPhases: state.expertProfileCategories.researchPhases,
  roles: state.expertProfileCategories.roles,
  icd10Codes: state.expertProfileCategories.icd10Codes,
  rareNeurologicalDiseases:
    state.expertProfileCategories.rareNeurologicalDiseases
});

export default connect(mapStateToProps, {
  fetchExpertProfileCategories,
  searchExpertProfiles
})(ExpertsSearchPage);
