import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { RequestSection } from "../RequestSection";
import { specimenTypeMapping } from "../../helpers/feasibilityRequestMapper";

export const LiquidSamplesSection = ({
  formProps,
  label: Label,
  checkBox: Checkbox,
  formControl: FormControl
}) => {
  const { t } = useTranslation();
  const { values } = formProps;

  const isEnabled =
    values.selected_materials.includes("samples") &&
    values.selected_materials.includes("liquid_samples");

  return (
    isEnabled && (
      <RequestSection
        title={t("liquid_samples")}
        description={t("liquid_samples_description")}
      >
        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"liquid_samples_needed"}
          >
            <h5 className={"margin-top-sm"}>{t("liquid_samples_needed")}</h5>
          </Label>
          <Checkbox
            label={t("data_title_wholeblood")}
            name={"sample_types"}
            value={specimenTypeMapping.wholeblood}
          />
          <Checkbox
            label={t("data_title_serum")}
            name={"sample_types"}
            value={specimenTypeMapping.serum}
          />
          <Checkbox
            label={t("data_title_plasma")}
            name={"sample_types"}
            value={specimenTypeMapping.plasma}
          />
          <Checkbox
            label={t("data_title_buffycoat")}
            name={"sample_types"}
            value={specimenTypeMapping.buffycoat}
          />
          <Checkbox
            label={t("data_title_dna")}
            name={"sample_types"}
            value={specimenTypeMapping.dna}
          />
          <Checkbox
            label={t("ct_dna")}
            name={"sample_types"}
            value={specimenTypeMapping.ct_dna}
          />
          <Checkbox
            label={t("rna_label")}
            name={"sample_types"}
            value={specimenTypeMapping.rna}
          />
          <Checkbox
            label={t("csf")}
            name={"sample_types"}
            value={specimenTypeMapping.csf}
          />
          <FormControl
            as={"textarea"}
            label={t("cells_specify")}
            name={"other_cell_sample_type"}
            maxLength={100}
          />
          <FormControl
            as={"textarea"}
            label={t("other_liquid_samples_specify_label")}
            name={"other_liquid_sample_type"}
            maxLength={100}
          />
        </Form.Group>
        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"liquid_samples_additional_info"}
          >
            <h5>{t("liquid_samples_additional_info_title")}</h5>
          </Label>
          <FormControl
            as={"textarea"}
            name={"liquid_samples_additional_info"}
            label={t("liquid_samples_additional_info")}
            placeholder={t("liquid_samples_additional_eg")}
          />
        </Form.Group>
        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"sample_volume"}
          >
            <h5>{t("sample_volume_title")}</h5>
          </Label>
          <FormControl
            as={"textarea"}
            name={"sample_volume_description"}
            label={t("sample_volume_label")}
          />
        </Form.Group>
        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"sample_donors_availability"}
          >
            <h5>{t("sample_donors_availability")}</h5>
          </Label>
          <Checkbox
            label={t("all_sample_available_per_donor")}
            name={"samples_from_each_donor"}
            value={"all_sample_available_per_donor"}
          />
          <Checkbox
            label={t("at_least_one_sample_available_per_donor")}
            name={"samples_from_each_donor"}
            value={"at_least_one_sample_available_per_donor"}
          />
        </Form.Group>
        <Form.Group>
          <Label
            withErrorIndicator
            formProps={formProps}
            fieldName={"requirements_for_critical_study"}
          >
            <h5>{t("requirements_for_critical_study")}</h5>
          </Label>
          <FormControl
            as={"textarea"}
            name={"requirements_for_critical_study"}
            label={t("requirements_for_critical_study_description")}
            placeholder={t("requirements_for_critical_study_description_eg")}
          />
        </Form.Group>
      </RequestSection>
    )
  );
};
