import config from "../config";
import useFetch from "./useFetch";

export const useExpertProfile = expertProfileId => {
  const url = `${config.platformUrl}/api/expert-profiles/${expertProfileId}`;

  const { data, loading, error } = useFetch(expertProfileId ? url : undefined);

  return {
    data,
    loading,
    error
  };
};
