import { connect } from "react-redux";
import CartItemDeleteButton from "./CartItemDeleteButton";
import { removeSampleFromCartByIndex } from "../actions/cart";

const mapStateToProps = state => {
  return {};
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onClick() {
      dispatch(removeSampleFromCartByIndex(ownProps.itemIndex));
    }
  };
}

const CartItemDeleteButtonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CartItemDeleteButton);
export default CartItemDeleteButtonContainer;
