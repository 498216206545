import amplitude from "amplitude-js";

import config from "../config";
import { CATEGORY_CATALOG } from "../actions/category-types";
// import { logError } from "./logger";

// TODO: Refactor to use with Auth0 or remove if this is not needed (ask the client first)
if (config.amplitudeId) {
  amplitude.getInstance().init(config.amplitudeId);
}

export const logEvent = (eventName, properties) => {
  amplitude.getInstance().logEvent(eventName, properties);
};

const distinct = (value, index, self) => self.indexOf(value) === index;

export const logAddToCart = (
  category = CATEGORY_CATALOG,
  codeInfo,
  specimenType,
  filters
) => {
  const analyticsEvent = { category, code: codeInfo.code, specimenType };
  filters.forEach(filter => (analyticsEvent[filter.field] = filter.values));
  logEvent("add_to_cart", analyticsEvent);
};

export const logStartFeasibilityRequest = items => {
  const event = {
    items: items.map(item => {
      const simplified = {
        code: item.codeInfo.code,
        specimenType: item.specimenType
      };

      item.filters.forEach(
        filter => (simplified[filter.field] = filter.values)
      );

      return simplified;
    }),
    codes: items.map(item => item.codeInfo.code).filter(distinct)
  };

  logEvent("start_feasibility_request", event);
};
