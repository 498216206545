import { useTranslation } from "react-i18next";
import config from "../config";

export const ICD10CodeLink = props => {
  const { t } = useTranslation();
  const { icd10CodesLinkUrl } = config;

  return (
    <a
      href={icd10CodesLinkUrl}
      rel="noreferrer"
      target="_blank"
      title={props.title || ""}
    >
      {t("finnish_icd10_codes")}
    </a>
  );
};
