export const specimenTypeMapping = {
  wholeblood: "40",
  serum: "50",
  plasma: "60",
  buffycoat: "70",
  urine: "80",
  dna: "90",
  genome_data: "111",
  ct_dna: "91",
  rna: "500",
  csf: "501",
  ffpe_tissue: "10",
  tma_slides: "20",
  fresh_frozen_tissue: "30",
  digitized_scanned_slides: "92"
};

export const genderMapping = {
  male: "10",
  female: "20"
};
