import classNames from "classnames";
import styles from "./info-block.module.scss";
import React from "react";

const InfoBlock = ({ title, description, iconSrc, button }) => (
  <div className={classNames("container", styles.infoBlock)}>
    <img src={iconSrc} alt={title} className={styles.icon} />
    <h2>{title}</h2>
    <p className={styles.text}>{description}</p>
    {button}
  </div>
);

export default InfoBlock;
