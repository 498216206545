import { reducer as toastrReducer } from "react-redux-toastr";
import { combineReducers } from "redux";
import { accessRequestReducer } from "../reducers/access-request";
import { cart } from "../reducers/cart";
import { populateDirectories } from "../reducers/directory";
import { feasibilityRequestReducer } from "../reducers/feasibility-request";
import { formsReducer } from "../reducers/forms";
import { lng } from "../reducers/i18n";
import { currentSample, selectedFilters } from "../reducers/samples";
import { searchReducer } from "../reducers/search";
import { userReducer } from "../reducers/user";
import { populateCohortCollections } from "../reducers/cohort-collection";
import { populateMiabisDataCategories } from "../reducers/miabis-data-categories";
import { populateMiabisCollectionTypes } from "../reducers/miabis-collection-types";
import { populateMiabisMaterialTypes } from "../reducers/miabis-material-types";
import { populateExpertProfileCategories } from "../reducers/expert-profile-categories";
import { populateExpertProfiles } from "../reducers/expert-profiles";

const catalogApp = routerReducer =>
  combineReducers({
    directories: populateDirectories,
    search: searchReducer,
    currentSample,
    selectedFilters,
    cart,
    lng,
    router: routerReducer,
    feasibilityRequestReducer,
    accessRequestReducer,
    user: userReducer,
    forms: formsReducer,
    toastr: toastrReducer,
    cohortCollections: populateCohortCollections,
    miabisDataCategories: populateMiabisDataCategories,
    miabisCollectionTypes: populateMiabisCollectionTypes,
    miabisMaterialTypes: populateMiabisMaterialTypes,
    expertProfileCategories: populateExpertProfileCategories,
    expertProfiles: populateExpertProfiles
  });

export default catalogApp;
