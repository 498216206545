import React from "react";
import { useMatch } from "react-router-dom";

import requestStates from "../common/requestStates";
import DeprecatedAccessRequest from "../components/AccessRequest/DeprecatedAccessRequest";
import useFetchAccessRequest from "../hooks/useFetchAccessRequest";
import initialValues from "../components/AccessRequest/accessRequestState";
import { accessRequestUrl } from "../routes/AccessRequestRoutes";
import config from "../config";
import { AccessRequestForm } from "../components/AccessRequest/AccessRequestForm";
import { isDeprecatedByDate } from "../helpers/utils";

const EditAccessRequestContainer = () => {
  const match = useMatch(`${accessRequestUrl}/edit/:accessRequestId`);
  const { accessRequestId } = match.params;
  const { accessRequest, loading, error } = useFetchAccessRequest(
    accessRequestId
  );

  const enabled =
    accessRequest &&
    [requestStates.saved, requestStates.refine].includes(accessRequest.state);

  const deprecated = isDeprecatedByDate(
    config.requestFormsV1DeadlineDate,
    accessRequest.createdate
  );

  return deprecated ? (
    <DeprecatedAccessRequest
      id={accessRequestId}
      initialValues={{ ...initialValues, ...accessRequest }}
      errorPrefilling={error}
      isLoading={loading}
      files={accessRequest.files}
      disabled={!enabled}
    />
  ) : (
    <AccessRequestForm
      id={accessRequestId}
      initialValues={{ ...initialValues, ...accessRequest }}
      errorPrefilling={error}
      isLoading={loading}
      files={accessRequest.files}
      disabled={!enabled}
    />
  );
};

export default EditAccessRequestContainer;
