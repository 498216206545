import styled, { css } from "styled-components";

export const StyledFilterButtonContainer = styled.div`
  ${({ theme }) => theme.mixins.Container("COLUMN", { alignItems: "center" })}
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
  ${({ theme }) => theme.objects.Copy.Scale.Small}
`;

export const StyledFilterContainer = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("ROW", { gap: theme.values.spacing.XLARGE })}

  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;

export const StyledSearchInput = styled.input`
  ${({ theme }) => theme.objects.Input.Base}
  background: ${({ theme }) => theme.values.colors.background.default.SUBTLE};
  max-width: ${({ theme }) => theme.values.dimensions.CARD_WIDTH};
  width: 100%;
  `;

export const StyledNumberOfSearchResults = styled.span`
  ${({ theme }) => theme.mixins.Container("ROW", { alignItems: "center" })}
  ${({ theme }) => theme.objects.Heading.Scale.Base}
  ${({ theme }) => theme.objects.Copy.Style.Default.Subtle}
  line-height: 100%;
`;

export const StyledSearchIcon = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("ROW", {
      alignItems: "center",
      justifyContent: "center"
    })}
  background-color: ${({ theme }) => theme.values.colors.brand.BASE};
  border-radius: 100%;
  height: ${({ theme }) => theme.values.iconSizes.XLARGE};
  width: ${({ theme }) => theme.values.iconSizes.XLARGE};
`;

export const StyledSearchHeader = styled.div`
  ${({ theme }) => theme.mixins.Container("ROW", { alignItems: "center" })}
  ${({ theme }) => theme.objects.Heading.Style.Secondary.Base}
  ${({ theme }) => theme.objects.Heading.Scale.Large}
  align-self: center;
  /* hardcoded to match the main header, values taken from _variables.scss */
  max-width: 1280px;
  padding: 0 1em;
  width: 100vw;
  /* end of hardcoded values */
`;

export const StyledSearchContainer = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("ROW", {
      alignItems: "center",
      justifyContent: "center"
    })}
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
  ${({ theme }) => theme.objects.Copy.Scale.Small}
  width: 100%;
`;

export const StyledCenteredColumn = styled.div`
  ${({ theme }) => theme.mixins.Container("COLUMN", { alignItems: "center" })}
  width: 100%;
`;

export const StyledCohortCollectionSearchPage = styled.div``;
