import { useEffect, useState } from "react";
import axios from "../helpers/axios";
import { toError } from "../helpers/fetchHelper";
import { transformData } from "./fetchRequestHelper";
import { logError } from "../helpers/logger";

const initialState = { data: {} };

const useFetchRequest = url => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState(initialState);

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const response = await axios.get(url);
        if (response.status === 200) {
          setRequest({
            ...response.data,
            data: transformData(response.data)
          });
        } else {
          setRequest(initialState);
          setError({
            error: response.data.message
          });
        }
      } catch (e) {
        logError(e);
        setRequest(initialState);
        setError(toError(e));
      } finally {
        setLoading(false);
      }
    };
    fetchRequest();
    return () => {};
  }, [url]);

  return {
    request,
    loading,
    error
  };
};

export default useFetchRequest;
