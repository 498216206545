// TODO: Get rid of bb_ prefix
import { specimenTypeMapping, genderMapping } from "./feasibilityRequestMapper";

export const biobankTranslationKeyByCode = {
  bb_10: "auria_biobank",
  bb_20: "helsinki_biobank",
  bb_30: "biobank_of_eastern_finland",
  bb_40: "central_finland_biobank",
  bb_50: "northern_finland_biobank_borealis",
  bb_60: "tampere_biobank"
};

export const biobankNameToCodeMap = {
  auria_biobank: "10",
  helsinki_biobank: "20",
  biobank_of_eastern_finland: "30",
  central_finland_biobank: "40",
  northern_finland_biobank_borealis: "50",
  tampere_biobank: "60",
  thl_biobank: "80",
  arctic_biobank: "90"
};

export const sampleTypeToCodeMap = {
  ffpe_tissue: "10",
  tma_slides_block: "20",
  fresh_frozen_tissue: "30",
  wholeblood: specimenTypeMapping.wholeblood,
  serum: specimenTypeMapping.serum,
  plasma: specimenTypeMapping.plasma,
  buffycoat: specimenTypeMapping.buffycoat,
  urine: specimenTypeMapping.urine,
  dna: specimenTypeMapping.dna,
  ct_dna: "91",
  digitized_scanned_slides: "92",
  genome_data: specimenTypeMapping.genome_data,
  any_other: "122",
  other: "122",
  rna: "500",
  csf: "501"
};

export const whoCollectsDataToCodeMap = {
  team: "10",
  biobanks: "20",
  team_and_biobanks: "30"
};

export const projectTypeToCodeMap = {
  data: "10",
  samples: "20",
  samples_and_data: "30"
};

export const invoicingMethodToCodeMap = {
  paper: "10",
  email: "20",
  electronic: "30"
};

export const feasibilityLevelToCodeMap = {
  biobank_study: "10",
  patient_recall: "30"
};

export const genderToCodeMap = genderMapping;
