import axios from "axios";
import { useEffect, useMemo, useState } from "react";

import config from "../config";
import { toError } from "../helpers/fetchHelper";
import { logError } from "../helpers/logger";

export const useFetchKeywords = (searchTerm, options) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [query, setQuery] = useState(null);

  const url = useMemo(() => {
    return searchTerm?.length
      ? `${config.catalogApiUrl}/v1/keywords?query=${searchTerm}`
      : `${config.catalogApiUrl}/v1/keywords`;
  }, [searchTerm]);

  useEffect(() => {
    const fetchRequest = async () => {
      try {
        const response = await axios.get(query);

        if (response.status === 200) {
          setData(response.data.data || response.data);
        } else {
          setData(null);
          setError({
            error: response.data.message
          });
        }
      } catch (e) {
        logError("Failure with http request: " + JSON.stringify(e));
        setData(null);
        setError(toError(e));
      } finally {
        setLoading(false);
      }
    };

    if (!!query) {
      setLoading(true);
      fetchRequest();
    }
  }, [query]);

  useEffect(() => {
    if (query !== url && !options?.skip) {
      setQuery(url);
    }
  }, [url, query, options]);

  return {
    data,
    loading,
    error
  };
};
