import styled from "styled-components";

export const StyledDarkMain = styled.main`
  ${({ theme }) => theme.objects.Background.Default.Base}
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
  ${({ theme }) => theme.mixins.Container("ROW", { justifyContent: "center" })}
  flex: 1;
  padding: ${({ theme }) =>
    `${theme.values.spacing.LARGE} ${theme.values.spacing.MEDIUM} ${theme.values.spacing.XLARGE}`};
  min-width: 480px;
`;
