export const FETCH_COHORT_COLLECTIONS = "FETCH_COHORT_COLLECTIONS";
export const FETCH_COHORT_COLLECTIONS_SUCCESS =
  "FETCH_COHORT_COLLECTIONS_SUCCESS";
export const FETCH_COHORT_COLLECTIONS_FAILURE =
  "FETCH_COHORT_COLLECTIONS_FAILURE";

export const FETCH_COHORT_COLLECTION = "FETCH_COHORT_COLLECTION";
export const FETCH_COHORT_COLLECTION_SUCCESS =
  "FETCH_COHORT_COLLECTION_SUCCESS";
export const FETCH_COHORT_COLLECTION_FAILURE =
  "FETCH_COHORT_COLLECTION_FAILURE";
