import ContentLoader from "react-content-loader";
import React from "react";

const RequestListLoadingIndicator = () => (
  <ContentLoader
    speed={2}
    width={1200}
    height={300}
    viewBox="0 0 1200 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="1235" height="109" />
    <rect x="0" y="120" rx="0" ry="0" width="1235" height="109" />
  </ContentLoader>
);

export default RequestListLoadingIndicator;
