import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Request
import initialValues from "./feasibilityRequestState";
import {
  FeasibilityRequestSchema,
  SaveFeasibilityRequestSchema
} from "./feasibilityRequestSchema";
import requestStates from "../../common/requestStates";

// Actions
import {
  createFeasibilityRequest,
  createFeasibilityRequestForApproval,
  removeAttachment,
  updateFeasibilityRequest,
  updateFeasibilityRequestForApproval
} from "../../actions/feasiblity_request";
import { emptyCart } from "../../actions/cart";

// Components
import FileUploader from "../FileUploader/RequestFileUploader";
import Title from "../Title/Title";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import CustomFormControl from "../CustomFormControl";
import CheckBox from "../CheckBox";
import Radio from "../RadioButton";
import RequestFileList from "../FileUploader/RequestFileList";
import LoadingWrapper from "../LoadingWrapper";
import CustomFormLabel from "../CustomFormLabel";
import FormFieldError from "../FormFieldError";
import UnsavedChangesPrompt from "../UnsavedChangesPrompt";
import LoadingForm from "../LoadingForm";

// Helpers
import {
  genderMapping,
  specimenTypeMapping
} from "../../helpers/feasibilityRequestMapper";
import {
  countryCodeLength,
  maxProjectDescriptionLength
} from "../../helpers/formSchemaHelper";
import { fillMissingValues } from "../../helpers/feasibilityRequestFormHelpers";
import { getAttachmentIdByFilename } from "../../helpers/attachmentHelper";
import { nullsToEmptyStrings } from "../../helpers/formHelper";

const isWaitingForFeedback = initialValues =>
  "state" in initialValues && initialValues.state === requestStates.refine;

const finalValidationSchema = FeasibilityRequestSchema;

const FeasibilityRequestLabel = props => (
  <CustomFormLabel validationSchema={finalValidationSchema} {...props} />
);

export const FeasibilityRequest = ({
  initialValues,
  isLoading,
  errorPrefilling,
  files,
  id,
  disabled
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [filesList, setFilesList] = useState(files || []);
  const [isSubmittedForApproval, setSubmittedForApproval] = useState(false);

  useEffect(() => {
    setFilesList(files || []);
  }, [files]);

  const removeFile = fileId => {
    setFilesList(
      filesList.filter(file => (file.attachmentid ?? file.filename) !== fileId)
    );
  };

  const schema = isSubmittedForApproval
    ? finalValidationSchema
    : SaveFeasibilityRequestSchema;

  const FeasibilityRequestFormControl = props => (
    <CustomFormControl
      disabled={disabled}
      {...props}
      validationSchema={schema}
      finalValidationSchema={finalValidationSchema}
    />
  );
  const FeasibilityRequestRadio = props => (
    <Radio disabled={disabled} {...props} validationSchema={schema} />
  );
  const FeasibilityRequestCheckBox = props => (
    <CheckBox disabled={disabled} {...props} validationSchema={schema} />
  );

  const errorBox = isWaitingForFeedback(initialValues) && (
    <Alert variant="danger">{t("frform_error_returned_for_more")}</Alert>
  );
  const formRef = useRef(null);

  const handleClick = (event, handleSubmit, isSubmittedForApproval) => {
    setTimeout(() => {
      formRef.current.checkValidity();
      formRef.current.reportValidity();
    }, 0);
    setTimeout(handleSubmit, 0);
    event.preventDefault();
    setSubmittedForApproval(isSubmittedForApproval);
  };

  const onSubmit = async values => {
    const checkedValues = fillMissingValues(values, id);

    let submit;
    if (isSubmittedForApproval) {
      submit = dispatch(
        id
          ? updateFeasibilityRequestForApproval(id, checkedValues)
          : createFeasibilityRequestForApproval(checkedValues)
      );
    } else {
      submit = dispatch(
        id
          ? updateFeasibilityRequest(id, checkedValues)
          : createFeasibilityRequest(checkedValues)
      );
    }
    await submit;
    // empty the cart now
    dispatch(emptyCart());
  };

  return (
    <>
      <Title>{t("feasibility_request")}</Title>
      <HorizontalLine className={"double-vertical-margin-bottom"} />
      <LoadingWrapper
        isLoading={isLoading}
        isInErrorState={errorPrefilling}
        errorMsg={t("form_api_offline")}
        loadingComponent={<LoadingForm />}
      >
        <Formik
          enableReinitialize={true}
          initialValues={nullsToEmptyStrings(initialValues)}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {formProps => (
            <Form onSubmit={formProps.handleSubmit} ref={formRef}>
              <UnsavedChangesPrompt isEnabled={formProps.dirty} />
              <Container>
                {errorBox}
                <Row>
                  <Col lg={4}>
                    <h2>{t("contact_person")}</h2>
                    <p className={"section-description"}>
                      {t("contact_person_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formCFirstname">
                        <FeasibilityRequestFormControl
                          name={"c_firstname"}
                          label={t("firstname")}
                          maxLength={40}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formCLastname">
                        <FeasibilityRequestFormControl
                          name={"c_surname"}
                          label={t("lastname")}
                          maxLength={50}
                          disabled
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="formCEmail">
                      <FeasibilityRequestFormControl
                        type="email"
                        name={"c_email"}
                        label={t("email")}
                        maxLength={100}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group controlId="formCOrganization">
                      <FeasibilityRequestFormControl
                        name={"c_company"}
                        label={t("organization")}
                        maxLength={100}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group controlId="formCPhone">
                      <FeasibilityRequestFormControl
                        type={"tel"}
                        name={"c_phone"}
                        label={t("phone")}
                        maxLength={30}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group controlId="formCStreet">
                      <FeasibilityRequestFormControl
                        name={"c_street1"}
                        label={t("address")}
                        maxLength={100}
                        disabled
                      />
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formCCountryCode">
                        <FeasibilityRequestFormControl
                          name={"c_countrycd"}
                          label={t("country_code", { countryCodeLength })}
                          length={countryCodeLength}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formCZip">
                        <FeasibilityRequestFormControl
                          name={"c_zip"}
                          label={t("zip")}
                          maxLength={10}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formCCity">
                        <FeasibilityRequestFormControl
                          name={"c_city"}
                          label={t("city")}
                          maxLength={40}
                          disabled
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("background_organization")}</h2>
                    <p className={"section-description"}>
                      {t("background_organization_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    {/* TODO: Reuse contact person fields for background organization */}
                    <Form.Row>
                      <Form.Group as={Col} controlId="formBgFirstname">
                        <FeasibilityRequestFormControl
                          name={"bg_firstname"}
                          label={t("firstname")}
                          maxLength={40}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formBgLastname">
                        <FeasibilityRequestFormControl
                          name={"bg_surname"}
                          label={t("lastname")}
                          maxLength={50}
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Group controlId="formBgEmail">
                      <FeasibilityRequestFormControl
                        type="email"
                        name={"bg_email"}
                        label={t("email")}
                        maxLength={100}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBgOrganization">
                      <FeasibilityRequestFormControl
                        name={"bg_company"}
                        label={t("organization")}
                        maxLength={100}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBgPhone">
                      <FeasibilityRequestFormControl
                        type={"tel"}
                        name={"bg_phone"}
                        label={t("phone")}
                        maxLength={30}
                      />
                    </Form.Group>
                    <Form.Group controlId="formBgStreet">
                      <FeasibilityRequestFormControl
                        name={"bg_street1"}
                        label={t("address")}
                        maxLength={100}
                      />
                    </Form.Group>
                    <Form.Row>
                      <Form.Group as={Col} controlId="formBgCountryCode">
                        <FeasibilityRequestFormControl
                          name={"bg_countrycd"}
                          label={t("country_code", { countryCodeLength })}
                          length={countryCodeLength}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formBgZip">
                        <FeasibilityRequestFormControl
                          name={"bg_zip"}
                          label={t("zip")}
                          maxLength={10}
                        />
                      </Form.Group>

                      <Form.Group as={Col} controlId="formBgCity">
                        <FeasibilityRequestFormControl
                          name={"bg_city"}
                          label={t("city")}
                          maxLength={40}
                        />
                      </Form.Group>
                    </Form.Row>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("biobanks")}</h2>
                    <p className={"section-description"}>
                      {t("biobanks_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Group id="formIncludedBiobanks">
                      <FeasibilityRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"included_biobanks"}
                      >
                        {t("choose_biobanks")}
                      </FeasibilityRequestLabel>
                      <FeasibilityRequestCheckBox
                        label={t("arctic_biobank")}
                        name={"included_biobanks"}
                        value={"90"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("auria_biobank")}
                        name={"included_biobanks"}
                        value={"10"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("helsinki_biobank")}
                        name={"included_biobanks"}
                        value={"20"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("biobank_of_eastern_finland")}
                        name={"included_biobanks"}
                        value={"30"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("central_finland_biobank")}
                        name={"included_biobanks"}
                        value={"40"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("northern_finland_biobank_borealis")}
                        name={"included_biobanks"}
                        value={"50"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("tampere_biobank")}
                        name={"included_biobanks"}
                        value={"60"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("thl_biobank")}
                        name={"included_biobanks"}
                        value={"80"}
                      />
                    </Form.Group>
                    <Form.Group controlId="formTargetOfPreanalys">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"targets_of_preanalys"}
                        label={t("target_of_preanalys")}
                        maxLength={2500}
                      />
                    </Form.Group>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("project_details")}</h2>
                    <p className={"section-description"}>
                      {t("project_details_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    <Form.Group controlId="formProjectName">
                      <FeasibilityRequestFormControl
                        name={"projektin_nimi"}
                        label={t("full_name_of_project")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formGoalsOfTheStudy">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"goals_of_the_study"}
                        label={t("project_description", {
                          maxProjectDescriptionLength
                        })}
                      />
                    </Form.Group>

                    <Form.Group id="formFeasibilityLevel">
                      <FeasibilityRequestLabel fieldName={"feasibilitylevel"}>
                        {t("what_kind_of_report")}
                      </FeasibilityRequestLabel>
                      <FeasibilityRequestCheckBox
                        label={t("what_kind_of_report_10")}
                        name={"feasibilitylevel"}
                        value={"10"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("what_kind_of_report_30")}
                        name={"feasibilitylevel"}
                        value={"30"}
                      />
                    </Form.Group>

                    <Form.Group id="formSampleTypes">
                      <FeasibilityRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"sample_types"}
                      >
                        {t("what_kind_of_samples")}
                      </FeasibilityRequestLabel>
                      <FeasibilityRequestCheckBox
                        label={t("ffpe_tissue")}
                        name={"sample_types"}
                        value={"10"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("tma_slides_block")}
                        name={"sample_types"}
                        value={"20"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("fresh_frozen_tissue")}
                        name={"sample_types"}
                        value={"30"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("data_title_wholeblood")}
                        name={"sample_types"}
                        value={specimenTypeMapping.wholeblood}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("data_title_serum")}
                        name={"sample_types"}
                        value={specimenTypeMapping.serum}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("data_title_plasma")}
                        name={"sample_types"}
                        value={specimenTypeMapping.plasma}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("data_title_buffycoat")}
                        name={"sample_types"}
                        value={specimenTypeMapping.buffycoat}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("data_title_urine")}
                        name={"sample_types"}
                        value={specimenTypeMapping.urine}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("data_title_dna")}
                        name={"sample_types"}
                        value={specimenTypeMapping.dna}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("ct_dna")}
                        name={"sample_types"}
                        value={"91"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("digitized_scanned_slides")}
                        name={"sample_types"}
                        value={"92"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("genome_data")}
                        name={"sample_types"}
                        value={specimenTypeMapping.genome_data}
                      />
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"sequence_data_desc"}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("any_other")}
                        name={"sample_types"}
                        value={"122"}
                      />
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"other_sample_type"}
                        maxLength={100}
                      />
                    </Form.Group>

                    {/* TODO: Biobank study with samples and data related to the samples / Patient recall through biobanks -selection  */}

                    <Form.Group controlId="formPatientDiagnoses">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"diseases_of_interest"}
                        label={t("patient_diagnoses")}
                        maxLength={2500}
                      />
                    </Form.Group>
                    <Form.Group controlId="formExcludedICD10Codes">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"excluded_icd10_codes"}
                        label={t("excluded_icd10_codes")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formPathologicalSamplesSnomedCodes">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"pathological_samples_snomed_codes"}
                        label={t("pathological_samples_snomed_codes")}
                        maxLength={2000}
                      />
                    </Form.Group>
                    <Form.Group controlId="formPatientAgeRange">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"patient_age_range"}
                        label={t("patient_age_range")}
                        maxLength={100}
                      />
                    </Form.Group>

                    <Form.Group id="formPatientGender">
                      <FeasibilityRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"patient_gender"}
                      >
                        {t("patient_gender")}
                      </FeasibilityRequestLabel>
                      <FeasibilityRequestCheckBox
                        label={t("male")}
                        name={"patient_gender"}
                        value={genderMapping.male}
                      />
                      <FeasibilityRequestCheckBox
                        label={t("female")}
                        name={"patient_gender"}
                        value={genderMapping.female}
                      />
                      <FormFieldError
                        fieldName={"patient_gender"}
                        formProps={formProps}
                      />
                    </Form.Group>

                    <Form.Group controlId="formOtherInclusionExclusionCriterias">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"other_Inclusion_exclusion_criterias"}
                        label={t("other_inclusion_exclusion_criterias")}
                        maxLength={2000}
                      />
                    </Form.Group>

                    <Form.Group controlId="formOtherSampleTypeCriteria">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"other_sample_type_criteria"}
                        label={t("other_sample_type_criteria")}
                        maxLength={2000}
                      />
                    </Form.Group>

                    <Form.Group controlId="formSamplingTime">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"sampling_time"}
                        label={t("sampling_time")}
                      />
                    </Form.Group>

                    <Form.Group controlId="formClinicalDataLinked">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"clinical_data_linked"}
                        label={t("clinical_data_linked")}
                        maxLength={2500}
                      />
                    </Form.Group>

                    <Form.Group id="formWhoCollectsData">
                      <FeasibilityRequestLabel
                        withErrorIndicator
                        formProps={formProps}
                        fieldName={"who_collects_data"}
                      >
                        {t("who_collects_the_data")}
                      </FeasibilityRequestLabel>
                      <FeasibilityRequestRadio
                        label={t("research_team")}
                        name={"who_collects_data"}
                        value={"10"}
                      />
                      <FeasibilityRequestRadio
                        label={t("the_biobanks")}
                        name={"who_collects_data"}
                        value={"20"}
                      />
                      <FeasibilityRequestRadio
                        label={t("research_team_and_biobanks")}
                        name={"who_collects_data"}
                        value={"30"}
                      />
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"who_collects_data_desc"}
                        maxLength={5000}
                      />
                    </Form.Group>
                    <p>{t("registry_study_approval_required")}</p>

                    <Form.Group controlId="formTimelinesForProject">
                      <FeasibilityRequestFormControl
                        as={"textarea"}
                        name={"timelines_for_project"}
                        label={t("timelines_for_project")}
                        maxLength={2500}
                      />
                    </Form.Group>
                  </Col>
                  <HorizontalLine className={"double-vertical-margin"} />
                  <Col lg={4}>
                    <h2>{t("attachments")}</h2>
                    <p className={"section-description"}>
                      {t("attachments_description")}
                    </p>
                  </Col>
                  <Col lg={8}>
                    {id && (
                      <RequestFileList
                        disabled={disabled}
                        files={filesList.map(file => ({
                          name: file.filename,
                          size: file.filesize
                            ? parseInt(file.filesize)
                            : undefined,
                          url: file.path
                        }))}
                        handleRemoveFile={async fileName => {
                          const fileId = await dispatch(
                            removeAttachment(
                              getAttachmentIdByFilename(filesList, fileName),
                              id
                            )
                          );
                          if (fileId) {
                            removeFile(fileId);
                          }
                        }}
                      />
                    )}
                    {!disabled && (
                      <FileUploader
                        onDrop={acceptedFiles => {
                          if (acceptedFiles.length === 0) {
                            return;
                          }
                          formProps.setFieldValue("files", acceptedFiles);
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>

              <HorizontalLine className={"top-margin"} />
              {!disabled && (
                <Container className="form-submit-row">
                  <Row>
                    <Col lg={{ span: 8, offset: 4 }}>
                      <div className={"button-group"}>
                        <button
                          className={"button button-secondary"}
                          type="submit"
                          disabled={formProps.isSubmitting}
                          onClick={event =>
                            handleClick(event, formProps.handleSubmit, false)
                          }
                        >
                          {t("save_and_continue_later")}
                        </button>

                        <button
                          disabled={formProps.isSubmitting}
                          className={"button"}
                          type="submit"
                          onClick={event =>
                            handleClick(event, formProps.handleSubmit, true)
                          }
                        >
                          {t("submit_for_approval")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </Form>
          )}
        </Formik>
      </LoadingWrapper>
    </>
  );
};

FeasibilityRequest.defaultProps = {
  initialValues
};

export default FeasibilityRequest;
