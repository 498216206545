import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { RequestSection } from "../RequestSection";
import React from "react";
import { specimenTypeMapping } from "../../helpers/feasibilityRequestMapper";
import { UploadArea } from "../AccessRequest/UploadFiles";
import { FileInfoType } from "../../helpers/attachmentHelper";

export const GenotypeSection = ({
  formProps,
  formControl: FormControl,
  label: Label,
  disabled,
  id
}) => {
  const { t } = useTranslation();
  const {
    values: { sample_types, genotype_files, filesFromFeasibility }
  } = formProps;
  const genotypeFilesFromFeasibility = filesFromFeasibility
    ? filesFromFeasibility.filter(f => f.infoType === FileInfoType.GENOTYPE)
    : [];

  const isGenotypeEnabled = sample_types.includes(
    specimenTypeMapping.genome_data
  );

  return (
    isGenotypeEnabled && (
      <RequestSection
        title={t("genotype_data_samples")}
        description={t("genotype_data_description")}
      >
        <FormControl
          as={"textarea"}
          name={"genotype_desc"}
          label={t("genotype_data_label")}
          placeholder={t("genotype_data_eg")}
        />
        <Form.Group>
          <Label formProps={formProps} fieldName={"attachements"}>
            <h5>{t("attachments")}</h5>
          </Label>
          <UploadArea
            requestId={id}
            fieldName={"genotype_files"}
            initialFiles={[...genotypeFilesFromFeasibility, ...genotype_files]}
            formProps={formProps}
            disabled={disabled}
          />
        </Form.Group>
      </RequestSection>
    )
  );
};
