import {
  FETCH_MIABIS_MATERIAL_TYPES,
  FETCH_MIABIS_MATERIAL_TYPES_FAILURE,
  FETCH_MIABIS_MATERIAL_TYPES_SUCCESS
} from "../actions/miabis-material-types.actions";

export const defaultState = {
  loading: false,
  error: false,
  miabisMaterialTypes: []
};

export const populateMiabisMaterialTypes = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_MIABIS_MATERIAL_TYPES:
      return {
        ...state,
        loading: true,
        error: false
      };
    case FETCH_MIABIS_MATERIAL_TYPES_SUCCESS:
      return {
        ...state,
        miabisMaterialTypes: action.results,
        loading: false
      };
    case FETCH_MIABIS_MATERIAL_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
