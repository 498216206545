import {
  biobankNameToCodeMap,
  sampleTypeToCodeMap,
  whoCollectsDataToCodeMap,
  projectTypeToCodeMap,
  invoicingMethodToCodeMap,
  feasibilityLevelToCodeMap,
  genderToCodeMap
} from "./requestsHelperMappings";

const findContactDetail = (request, type) => {
  return request.contactDetail?.find(cd => cd.contactType === type);
};

const addLegacyContactDetails = (legacyObject, contactDetails, fieldPrefix) => {
  legacyObject[fieldPrefix + "_firstname"] = contactDetails?.firstName;
  legacyObject[fieldPrefix + "_surname"] = contactDetails?.lastName;
  legacyObject[fieldPrefix + "_street1"] = contactDetails?.address;

  legacyObject[fieldPrefix + "_phone"] = contactDetails?.phone;
  legacyObject[fieldPrefix + "_email"] = contactDetails?.email;
  legacyObject[fieldPrefix + "_address"] = contactDetails?.address;
  legacyObject[fieldPrefix + "_email"] = contactDetails?.email;
  legacyObject[fieldPrefix + "_company"] = contactDetails?.organization;
  legacyObject[fieldPrefix + "_city"] = contactDetails?.city;
  legacyObject[fieldPrefix + "_zip"] = contactDetails?.zipCode;
  legacyObject[fieldPrefix + "_countrycd"] = contactDetails?.countryCode;
};

const biobankNameToNumber = name => biobankNameToCodeMap[name];
const sampleTypeToNumber = type => sampleTypeToCodeMap[type];
const dataCollectorToNumber = collector => whoCollectsDataToCodeMap[collector];
const projectTypeToNumber = type => projectTypeToCodeMap[type];
const invoicingMethodToNumber = method => invoicingMethodToCodeMap[method];
const feasibilityLevelToNumber = level => feasibilityLevelToCodeMap[level];
const genderToNumber = gender => genderToCodeMap[gender];

export {
  findContactDetail,
  addLegacyContactDetails,
  biobankNameToNumber,
  sampleTypeToNumber,
  dataCollectorToNumber,
  projectTypeToNumber,
  invoicingMethodToNumber,
  feasibilityLevelToNumber,
  genderToNumber
};
