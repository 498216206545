import {
  TOGGLE_CART,
  ADD_SAMPLE_TO_CART,
  REMOVE_SAMPLE_FROM_CART,
  REMOVE_SAMPLE_FROM_CART_BY_INDEX,
  EMPTY_CART
} from "./cart.actions";
import { logAddToCart } from "../helpers/amplitude";
import { CATEGORY_CATALOG } from "./category-types";

export const toggleCart = function() {
  return {
    type: TOGGLE_CART
  };
};

export const emptyCart = function() {
  return {
    type: EMPTY_CART
  };
};

export const doAddSampleToCart = function(
  category = CATEGORY_CATALOG,
  codeInfo,
  specimenType,
  filters,
  sample,
  quantity
) {
  return {
    type: ADD_SAMPLE_TO_CART,
    category,
    codeInfo,
    specimenType,
    filters,
    sample,
    quantity
  };
};

export const addSampleToCart = function(
  category = CATEGORY_CATALOG,
  codeInfo,
  specimenType,
  sample,
  quantity
) {
  return function(dispatch, getState) {
    if (codeInfo && specimenType) {
      const filters =
        category === CATEGORY_CATALOG ? [...getState().selectedFilters] : [];
      logAddToCart(category, codeInfo, specimenType, filters);
      dispatch(
        doAddSampleToCart(
          category,
          codeInfo,
          specimenType,
          filters,
          sample,
          quantity
        )
      );
    }
  };
};

export const doRemoveSampleFromCart = (
  category,
  codeInfo,
  specimenType,
  filters
) => {
  return {
    type: REMOVE_SAMPLE_FROM_CART,
    category,
    codeInfo,
    specimenType,
    filters
  };
};

export const removeSampleFromCart = (
  category = CATEGORY_CATALOG,
  codeInfo,
  specimenType
) => {
  return function(dispatch, getState) {
    const filters =
      category === CATEGORY_CATALOG ? [...getState().selectedFilters] : [];
    dispatch(doRemoveSampleFromCart(category, codeInfo, specimenType, filters));
  };
};

export const removeSampleFromCartByIndex = idx => {
  return {
    type: REMOVE_SAMPLE_FROM_CART_BY_INDEX,
    idx
  };
};
