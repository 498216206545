import styles from "./status-indicator.module.scss";
import StatusBlock from "./StatusBlock";
import React from "react";
import { isFeasibilityRequest } from "../requestListHelper";
import requestStates from "../../../common/requestStates";

const newFeasibility = ["orange", null, null, null, null, null];
const refillFeasibility = ["green", "red", null, null, null, null];
const submittedFeasibility = ["green", "orange", null, null, null, null];
const ongoingFeasibility = ["green", "green", "orange", null, null, null];
const completedFeasibility = ["green", "green", "green", null, null, null];
const canceledFeasibility = ["green", "green", "red", null, null, null];

const feasibilityRequestStatusCodeToProgressBarColors = {
  [requestStates.saved]: newFeasibility,
  [requestStates.submitted]: submittedFeasibility,
  [requestStates.refine]: refillFeasibility,
  [requestStates.created]: submittedFeasibility,
  [requestStates.assigned]: ongoingFeasibility,
  [requestStates.ongoing]: ongoingFeasibility,
  [requestStates.reported]: ongoingFeasibility,
  [requestStates.responded]: completedFeasibility,
  [requestStates.hold]: canceledFeasibility
};

const newAccess = ["green", "green", "green", "orange", null, null];
const refillAccess = ["green", "green", "green", "green", "red", null];
const submittedAccess = ["green", "green", "green", "green", "orange", null];
const ongoingAccess = ["green", "green", "green", "green", "orange", null];
const completedAccess = ["green", "green", "green", "green", "green", "orange"];
const returnedAccess = ["green", "green", "green", "green", "green", "green"];
const canceledAccess = ["green", "green", "green", "green", "green", "red"];

const accessRequestStatusCodeToProgressBarColors = {
  [requestStates.saved]: newAccess,
  [requestStates.submitted]: submittedAccess,
  [requestStates.created]: submittedAccess,
  [requestStates.refine]: refillAccess,
  [requestStates.assigned]: ongoingAccess,
  [requestStates.ongoing]: ongoingAccess,
  [requestStates.reported]: ongoingAccess,
  [requestStates.responded]: completedAccess,
  [requestStates.decided]: completedAccess,
  [requestStates.signed]: completedAccess,
  [requestStates.transfer]: completedAccess,
  [requestStates.evaluation]: completedAccess,
  [requestStates.completed]: completedAccess,
  [requestStates.returned]: returnedAccess,
  [requestStates.hold]: canceledAccess
};

const getColors = (projectType, status) =>
  isFeasibilityRequest({ type: projectType })
    ? feasibilityRequestStatusCodeToProgressBarColors[status]
    : accessRequestStatusCodeToProgressBarColors[status];

const StatusIndicator = ({ status, projectType }) => {
  const colors = getColors(projectType, status);

  return colors ? (
    <div className={styles.projectStatus}>
      {colors.map((color, idx) => (
        <StatusBlock key={idx} color={color} />
      ))}
    </div>
  ) : null;
};

export default StatusIndicator;
