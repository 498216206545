import React from "react";
import { NavLink } from "react-router-dom";
import folder from "../assets/img/folder_code.svg";
import { logEvent } from "../helpers/amplitude";

const ICD10Code = ({ id, name, label, totalSamples, onClick }) => (
  <NavLink
    onClick={() =>
      logEvent("select_icd10", { source: "directory", code: name })
    }
    to={"/catalog/samples/" + id}
    className="directory-icd10-item directory-icd10-code"
  >
    <div className="left">
      <img src={folder} width="28" alt="" />
      <div className="info">
        <p className="title">{name}</p>
        <p className="label">{label}</p>
      </div>
    </div>
  </NavLink>
);

export default ICD10Code;
