import config from "../config";

const sessionConfig = {
  history: null,
  requestsToken: null,
  platformToken: null
};

export const setHistory = routerHistory => {
  sessionConfig.history = routerHistory;
};

export const setRequestsToken = async token => {
  sessionConfig.requestsToken = token;
};

export const setPlatformToken = async token => {
  sessionConfig.platformToken = token;
};

export const getRequestsToken = async () => {
  return sessionConfig.requestsToken;
};

export const getPlatformToken = async () => {
  return sessionConfig.platformToken;
};

const setToken = async (getTokenSilently, getToken, audience, setToken) => {
  let token = null;
  try {
    token = await getTokenSilently({ audience });
  } catch (err) {
    token = await getToken({ audience });
  } finally {
    setToken(token);
  }
};

export const getTokens = async (
  isLoading,
  isAuthenticated,
  getAccessTokenSilently,
  getAccessTokenWithPopup
) => {
  if (!isLoading && isAuthenticated) {
    // Get tokens for both of the APIs
    await setToken(
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      config.auth0AudiencePlatform,
      setPlatformToken
    );
    await setToken(
      getAccessTokenSilently,
      getAccessTokenWithPopup,
      config.auth0AudienceRequests,
      setRequestsToken
    );

    return {
      tokensFetched: true
    };
  }

  return {
    tokensFetched: false
  };
};

export const clearCookies = () => {
  sessionConfig.requestsToken = null;
  sessionConfig.platformToken = null;
  sessionConfig.history = null;
};

export const redirectToLogout = () => {
  sessionConfig.history && sessionConfig.history.push(config.logoutUrl);
};
