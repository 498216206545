import iconFile from "./icon_file.svg";
import * as React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { formatFileSize } from "../../helpers/fileSizeFormatter";
import config from "../../config";
import styles from "./file-uploader.module.scss";
import axios from "../../helpers/axios";

const RequestFileList = ({
  files,
  handleRemoveFile,
  disabled,
  requestId = null
}) => {
  const { t } = useTranslation();

  const downloadUrl = filename =>
    `${config.requestsUrl}/storage/downloadFile/${filename}`;
  const downloadFile = async filename => {
    const url = downloadUrl(filename);
    try {
      const response = await axios.get(url, { responseType: "blob" });
      if (response.status === 200) {
        saveFile(response, filename);
      } else {
        console.log(response.data.message);
      }
    } catch (e) {
      console.log("Failure with http request:", e);
    }
  };

  const saveFile = (response, filename) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  };

  return files.map(({ size, name, description, comment, url }) => (
    <div className={styles.fileBlockContainer} key={name}>
      <div className={styles.fileBlock}>
        <img src={iconFile} alt={name} className={styles.fileIcon} />
        <div>
          <button
            onClick={e => {
              e.preventDefault();
              downloadFile(name);
            }}
            style={{
              background: "none",
              padding: 0,
              border: "none",
              cursor: "pointer"
            }}
          >
            {name}
          </button>
          <span className={styles.description}>{description}</span>
          {size && (
            <div className={styles.fileSize}>
              {t("file_size")}: {formatFileSize(size)}
            </div>
          )}
          <div className={styles.description}>{comment}</div>
        </div>
      </div>
      {!disabled && (
        <button
          className={"button left-margin"}
          type={"button"}
          onClick={() => handleRemoveFile(name, requestId)}
        >
          {t("delete_file")}
        </button>
      )}
    </div>
  ));
};

RequestFileList.propTypes = {
  files: PropTypes.array.isRequired,
  handleRemoveFile: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default RequestFileList;
