import { RequestSection } from "../../RequestSection";
import { useTranslation } from "react-i18next";
import { Form, Col } from "react-bootstrap";

export const ContactPersonSection = ({
  feasibilityRequestFormControl: FeasibilityRequestFormControl,
  countryCodeLength
}) => {
  const { t } = useTranslation();

  return (
    <RequestSection
      title={t("contact_info")}
      description={t("contact_info_description")}
      horizontalLineClassName="hidden"
    >
      <Form.Row>
        <Form.Group as={Col} controlId="formCFirstname">
          <FeasibilityRequestFormControl
            name={"c_firstname"}
            label={t("firstname")}
            maxLength={40}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formCLastname">
          <FeasibilityRequestFormControl
            name={"c_surname"}
            label={t("lastname")}
            maxLength={50}
            disabled
          />
        </Form.Group>
      </Form.Row>
      <Form.Group controlId="formCEmail">
        <FeasibilityRequestFormControl
          type="email"
          name={"c_email"}
          label={t("email")}
          maxLength={100}
          disabled
        />
      </Form.Group>
      <Form.Group controlId="formCOrganization">
        <FeasibilityRequestFormControl
          name={"c_company"}
          label={t("organization")}
          maxLength={100}
          disabled
        />
      </Form.Group>
      <Form.Group controlId="formCPhone">
        <FeasibilityRequestFormControl
          type={"tel"}
          name={"c_phone"}
          label={t("phone")}
          maxLength={30}
          disabled
        />
      </Form.Group>
      <Form.Group controlId="formCStreet">
        <FeasibilityRequestFormControl
          name={"c_street1"}
          label={t("address")}
          maxLength={100}
          disabled
        />
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col} controlId="formCCountryCode">
          <FeasibilityRequestFormControl
            name={"c_countrycd"}
            label={t("country_code", { countryCodeLength })}
            length={countryCodeLength}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formCZip">
          <FeasibilityRequestFormControl
            name={"c_zip"}
            label={t("zip")}
            maxLength={10}
            disabled
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formCCity">
          <FeasibilityRequestFormControl
            name={"c_city"}
            label={t("city")}
            maxLength={40}
            disabled
          />
        </Form.Group>
      </Form.Row>
    </RequestSection>
  );
};
