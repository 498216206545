import { Formik, useFormikContext } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";

import { upsertExpertProfile } from "../../actions/users";
import { hasServerError } from "../../helpers/serverErrorHelper";
import CustomFormLabel from "../CustomFormLabel";
import ErrorBox from "../ErrorBox";
import FieldErrorBlock from "../FieldErrorBlock";
import ExpertProfileSchema from "./ExpertProfileSchema";
import KeywordMultiSelect from "./KeywordMultiSelect";
import { fetchExpertProfileCategories } from "../../actions/expert-profile-categories";
import { MultiSelect } from "@finbb/ui-components";
import { FormGroup } from "react-bootstrap";
import styles from "./KeywordMultiSelect.module.scss";
import LoadingForm from "../LoadingForm";

export const isFormikPropertyChanged = (
  property,
  currentValues,
  initialValues
) => currentValues[property] !== initialValues[property];

export const getChangedFormikValue = (
  property,
  currentValues,
  initialValues
) => {
  const isChanged = isFormikPropertyChanged(
    property,
    currentValues,
    initialValues
  );

  if (!isChanged) {
    return null;
  }

  const changedValue = {
    [property]: currentValues[property]
  };

  return changedValue;
};

const CategorySelect = ({ name, placeholder, value, categories }) => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = React.useState("");

  const options = categories.map(category => ({
    value: category.id,
    name: category.name
  }));

  const filteredOptions =
    inputValue.length > 0
      ? options.filter(option =>
          option.name.toLowerCase().includes(inputValue?.toLowerCase())
        )
      : options;

  return (
    <Form.Row key={name}>
      <FormGroup controlId={name} as={Col}>
        <CustomFormLabel
          fieldName={name}
          validationSchema={ExpertProfileSchema}
        >
          {t(`expertprofile_form_label_${name}`)}
        </CustomFormLabel>
        <div className={styles.keywordMultiSelect}>
          <MultiSelect
            name={name}
            options={filteredOptions}
            value={value ?? []}
            onChanged={newValue => setFieldValue(name, newValue)}
            onInputChanged={input => setInputValue(input)}
            allowNewValues={false}
          />
        </div>
      </FormGroup>
    </Form.Row>
  );
};

const ExpertProfileForm = ({
  userData,
  expertProfileId,
  loading,
  categoriesLoading,
  therapyAreas,
  researchExpertises,
  researchPhases,
  roles,
  icd10Codes,
  rareNeurologicalDiseases,
  fetchExpertProfileCategories,
  upsertExpertProfile,
  initialValues
}) => {
  const { t } = useTranslation();
  const { errorCode, success } = useSelector(
    state => state.forms.upsertExpertProfile
  );
  const schema = ExpertProfileSchema;
  const userExpertProfileCreationConsentGivent =
    userData?.expertProfileCreationConsentGivenAt !== null;
  const [displayConsent, setDisplayConsent] = React.useState(
    !userExpertProfileCreationConsentGivent
  );

  useEffect(() => {
    fetchExpertProfileCategories();
  }, [fetchExpertProfileCategories]);

  const handleSubmit = async (values, setSubmitting, resetForm) => {
    const upsertValues = {
      ...getChangedFormikValue("keywords", values, initialValues),
      ...getChangedFormikValue("therapyAreas", values, initialValues),
      ...getChangedFormikValue("researchExpertises", values, initialValues),
      ...getChangedFormikValue("roles", values, initialValues),
      ...getChangedFormikValue("researchPhases", values, initialValues),
      ...getChangedFormikValue("icd10Codes", values, initialValues),
      ...getChangedFormikValue(
        "rareNeurologicalDiseases",
        values,
        initialValues
      )
    };
    await upsertExpertProfile(userData.id, expertProfileId, upsertValues);

    setSubmitting(false);

    resetForm({
      values
    });
  };

  if (loading || categoriesLoading) {
    return <LoadingForm />;
  }

  if (displayConsent) {
    return (
      <Row>
        <Col lg={4}>
          <h2>{t("expertprofile_form_heading_expert_info")}</h2>
          <p className={"section-description"}>
            {t("expertprofile_form_desc_expert_info")}
          </p>
        </Col>
        <Col lg={8}>
          <p className={"section-description"}>
            {t("expertprofile_form_desc_expert_info_consent")}
          </p>
          <button className={"button"} onClick={() => setDisplayConsent(false)}>
            {t("expertprofile_form_submit_consent")}
          </button>
        </Col>
      </Row>
    );
  }
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, resetForm }) =>
        handleSubmit(values, setSubmitting, resetForm)
      }
      validationSchema={schema}
    >
      {({
        dirty,
        values,
        isSubmitting,
        isValid,
        submitForm,
        handleChange,
        error,
        touched,
        errors
      }) => (
        <Form>
          {hasServerError(errorCode) && (
            <ErrorBox msg={t(`expertprofile_form_error_${errorCode}`)} />
          )}
          <Row>
            <Col lg={4}>
              <h2>{t("expertprofile_form_heading_expert_info")}</h2>
              <p className={"section-description"}>
                {t("expertprofile_form_desc_expert_info")}
              </p>
            </Col>
            <Col lg={8}>
              <Form.Row>
                <Form.Group as={Col} controlId="keywords">
                  <CustomFormLabel
                    validationSchema={expertProfileId ? schema : undefined}
                    fieldName={"keywords"}
                  >
                    {t(`expertprofile_form_label_keywords`)}
                  </CustomFormLabel>
                  <KeywordMultiSelect
                    name="keywords"
                    placeholder={t("expertprofile_form_placeholder_keywords")}
                    value={values.keywords}
                  />
                  {touched &&
                    !!errors?.keywords?.key &&
                    !!errors?.keywords?.values && (
                      <FieldErrorBlock>
                        {t(errors.keywords.key, errors.keywords.values)}
                      </FieldErrorBlock>
                    )}
                </Form.Group>
              </Form.Row>
              <CategorySelect
                name="therapyAreas"
                placeholder="Therapy Areas"
                value={values.therapyAreas}
                categories={therapyAreas}
              />
              <CategorySelect
                name="researchExpertises"
                placeholder="Research Expertises"
                value={values.researchExpertises}
                categories={researchExpertises}
              />
              <CategorySelect
                name="roles"
                placeholder="Roles"
                value={values.roles}
                categories={roles}
              />
              <CategorySelect
                name="researchPhases"
                placeholder="Research Phases"
                value={values.researchPhases}
                categories={researchPhases}
              />
              <CategorySelect
                name="icd10Codes"
                placeholder="ICD10 Codes"
                value={values.icd10Codes}
                categories={icd10Codes}
              />
              <CategorySelect
                name="rareNeurologicalDiseases"
                placeholder="Rare Neurological Diseases"
                value={values.rareNeurologicalDiseases}
                categories={rareNeurologicalDiseases}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 4 }}>
              <div className={"button-group"}>
                {!!success && !dirty && (
                  <span>{t("expertprofile_form_success_message")}</span>
                )}
                <button
                  disabled={!isValid || !dirty || isSubmitting}
                  className={"button"}
                  type="submit"
                  onClick={submitForm}
                >
                  {t("expertprofile_form_submit")}
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

ExpertProfileForm.propTypes = {
  expertProfileId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  categoriesLoading: PropTypes.bool.isRequired,
  userData: PropTypes.object,
  initialValues: PropTypes.object
};
const mapStateToProps = state => ({
  therapyAreas: state.expertProfileCategories.therapyAreas,
  researchExpertises: state.expertProfileCategories.researchExpertises,
  researchPhases: state.expertProfileCategories.researchPhases,
  roles: state.expertProfileCategories.roles,
  icd10Codes: state.expertProfileCategories.icd10Codes,
  rareNeurologicalDiseases:
    state.expertProfileCategories.rareNeurologicalDiseases,
  categoriesLoading: state.expertProfileCategories.loading
});
export default connect(mapStateToProps, {
  fetchExpertProfileCategories,
  upsertExpertProfile
})(ExpertProfileForm);
