import config from "../config";
import useFetch from "./useFetch";

const useUser = id => {
  const { data, loading, error } = useFetch(
    `${config.platformUrl}/api/user/${id}`,
    !id
  );

  return {
    data,
    loading,
    error
  };
};

export default useUser;
