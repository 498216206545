import React from "react";
import { useTranslation } from "react-i18next";

const SimpleBar = ({ data }) => {
  const { t } = useTranslation();

  // calculate percentage
  const percentage =
    Math.floor(
      (data.values[0].value / (data.values[0].value + data.values[1].value)) *
        100
    ) + "%";

  const firstValue =
    data.values[0].value >= 5 || data.values[0].value === 0
      ? data.values[0].value
      : "<5";
  const secondValue =
    data.values[1].value >= 5 || data.values[1].value === 0
      ? data.values[1].value
      : "<5";

  return (
    <div className="simple-bar">
      <p className="title">{t("data_label_" + data.key)}</p>
      <div className="bars">
        <span className="bar left" style={{ width: percentage }}></span>
        <span className="bar right"></span>
      </div>
      <div className="labels">
        <span className="label left">
          {t("data_filter_value_" + data.values[0].key)} ({firstValue})
        </span>
        <span className="label right">
          {t("data_filter_value_" + data.values[1].key)} ({secondValue})
        </span>
      </div>
    </div>
  );
};

export default SimpleBar;
