import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

export const ScientificRationalEthicsSection = ({
  formProps,
  formControl: FormControl,
  label: Label,
  radio: Radio
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Label
        withErrorIndicator
        formProps={formProps}
        fieldName={"label_scientific_rational_and_ethics"}
      >
        <h5>{t("arForm_scientific_rationale")}</h5>
      </Label>
      <Form.Group id="formWhoCollects_data">
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"ethicscommitteestatementavailable"}
        >
          {t("arForm_ethicscommitteestatementavailable_label")}
        </Label>
        <Radio
          label={t("arForm_ethicscommitteestatementavailable_no")}
          name={"ethicscommitteestatementavailable"}
          value={"F"}
        />
        <Radio
          label={t("arForm_ethicscommitteestatementavailable_yes")}
          name={"ethicscommitteestatementavailable"}
          value={"T"}
        />
      </Form.Group>
      <Form.Group controlId="formScienceProfit">
        <FormControl
          as={"textarea"}
          name={"scienceprofit"}
          label={t("arForm_scienceprofit_label")}
          maxLength={2500}
        />
      </Form.Group>
      <Form.Group controlId="formEthicalEvaluation">
        <FormControl
          as={"textarea"}
          name={"ethical_evaluation"}
          label={t("arForm_ethical_evaluation_label")}
        />
      </Form.Group>
      <Form.Group controlId="formResearchArguments">
        <FormControl
          as={"textarea"}
          name={"research_arguments"}
          label={t("arForm_research_arguments_label")}
          maxLength={2500}
        />
      </Form.Group>
    </>
  );
};
