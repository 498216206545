import React, { useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import styles from "./NavigationUser.module.scss";
import { NavLink } from "react-router-dom";
import useOnClickOutside from "use-onclickoutside";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import LogoutPage from "../../pages/LogoutPage";
import config from "../../config";
import useUser from "../../hooks/useUser";
import { LoadingIndicator } from "@finbb/ui-components";

const NavigationUserDropdown = ({ active, toggle }) => {
  const { t } = useTranslation();

  const dropdownClasses = classNames(styles.navigationUserDropdown, {
    [styles.navigationUserDropdownVisible]: active
  });

  return (
    <ul className={dropdownClasses}>
      <li>
        <NavLink to="/user/edit-profile" onClick={() => toggle(false)}>
          {t("navigation_dropdown_edit_profile")}
        </NavLink>
      </li>
      <li>
        <NavLink to={config.logoutUrl} onClick={() => toggle(false)}>
          {t("navigation_dropdown_logout")}
        </NavLink>
      </li>
    </ul>
  );
};

const getUserName = user => {
  if (!user) {
    return;
  }

  if (user.firstName || user.lastName) {
    const separator = " ";
    return user.firstName + separator + user.lastName;
  }

  // Do a logout if the user is not properly logged in
  return process.env.NODE_ENV === "test" ? "" : <LogoutPage />;
};

const NavigationUser = () => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { data, loading } = useUser(user && user.sub);

  // handle clicking outside menu collapse stuff
  const divRef = useRef();
  const buttonRef = useRef();
  const [menuActive, setMenuActive] = useState(false);
  useOnClickOutside(divRef, e => {
    if (!buttonRef.current.contains(e.target)) {
      setMenuActive(false);
    }
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!user) {
    return (
      <NavLink to="/user/login" className="nav-link">
        {t("navigation_login")}
      </NavLink>
    );
  }

  const name = getUserName(data);

  return (
    <div className={`${styles.navigationUserContainer} nav-link`}>
      <div
        className={styles.navigationUser}
        ref={buttonRef}
        onClick={() => setMenuActive(!menuActive)}
      >
        {name}
      </div>
      <div ref={divRef}>
        <NavigationUserDropdown active={menuActive} toggle={setMenuActive} />
      </div>
    </div>
  );
};

export default NavigationUser;
