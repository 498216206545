import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { RequestSection } from "../RequestSection";
import React from "react";
import { UploadArea } from "../AccessRequest/UploadFiles";
import { FileInfoType } from "../../helpers/attachmentHelper";

export const PhenotypeLifestyleSection = ({
  formProps,
  label: Label,
  formControl: FormControl,
  disabled,
  id
}) => {
  const { t } = useTranslation();
  const {
    values: {
      selected_materials,
      phenotype_lifestyle_files,
      filesFromFeasibility
    }
  } = formProps;
  const phenotypeFilesFromFeasibility = filesFromFeasibility
    ? filesFromFeasibility.filter(
        f => f.infoType === FileInfoType.PHENOTYPE_OR_LIFESTYLE
      )
    : [];

  const isEnabled = selected_materials.includes("phenotype_or_lifestyle_data");

  return (
    isEnabled && (
      <RequestSection
        title={t("phenotype_or_lifestyle_data")}
        description={t("phenotype_or_lifestyle_data_description")}
      >
        <FormControl
          as={"textarea"}
          name={"phenotype_or_lifestyle_desc"}
          label={t("phenotype_or_lifestyle_data_description_label")}
          placeholder={t("phenotype_or_lifestyle_data_description_eg")}
        />
        <Form.Group>
          <Label formProps={formProps} fieldName={"attachements"}>
            <h5>{t("attachments")}</h5>
          </Label>
          <UploadArea
            requestId={id}
            fieldName={"phenotype_lifestyle_files"}
            initialFiles={[
              ...phenotypeFilesFromFeasibility,
              ...phenotype_lifestyle_files
            ]}
            formProps={formProps}
            disabled={disabled}
          />
        </Form.Group>
      </RequestSection>
    )
  );
};
