import React from "react";

import MainContainer from "../components/MainContainer";
import BreadcrumbContainer from "../components/BreadcrumbContainer";

import CartContainer from "../components/CartContainer";
import SampleViewContainer from "../components/SampleViewContainer";

class ViewPage extends React.Component {
  componentDidMount() {
    this.props.onLoad();
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    return (
      <div className="App page-viewpage">
        <MainContainer>
          <div className="finbb-container">
            <BreadcrumbContainer />
          </div>
          <SampleViewContainer />
        </MainContainer>
        <CartContainer />
      </div>
    );
  }
}

export default ViewPage;
