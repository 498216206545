import React, { useRef, useState } from "react";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Request
import initialValues from "./feasibilityRequestState";
import {
  FeasibilityRequestSchema,
  SaveFeasibilityRequestSchema
} from "./feasibilityRequestSchema";
import requestStates from "../../common/requestStates";

// Actions
import {
  createFeasibilityRequest,
  createFeasibilityRequestForApproval,
  updateFeasibilityRequest,
  updateFeasibilityRequestForApproval
} from "../../actions/feasiblity_request";
import { emptyCart } from "../../actions/cart";

// Components
import Title from "../Title/Title";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import CustomFormControl from "../CustomFormControl";
import CheckBox from "../CheckBox";
import Radio from "../RadioButton";
import LoadingWrapper from "../LoadingWrapper";
import CustomFormLabel from "../CustomFormLabel";
import UnsavedChangesPrompt from "../UnsavedChangesPrompt";
import LoadingForm from "../LoadingForm";

// Helpers
import {
  countryCodeLength,
  maxProjectDescriptionLength
} from "../../helpers/formSchemaHelper";
import {
  cleanPropsForNonSelectedSections,
  fillMissingValues,
  transformAttrTypesToApiFormat
} from "../../helpers/feasibilityRequestFormHelpers";
import { nullsToEmptyStrings } from "../../helpers/formHelper";
import { MaterialSection } from "./sections/MaterialSection";
import { ProjectDetailsSection } from "./sections/ProjectDetailsSection";
import { ContactPersonSection } from "./sections/ContactPersonSection";
import { BackgroundOrganizationSection } from "./sections/BackgroundOrganizationSection";
import { BioBanksSection } from "./sections/BioBanksSection";
import { LiquidSamplesSection } from "./sections/LiquidSamplesSection";
import { TissueSamplesSection } from "./sections/TissueSamplesSection";
import { PhenotypeLifestyleSection } from "./sections/PhenotypeLifestyleSection";
import { GenotypeSection } from "./sections/GenotypeSection";
import { RecruitSection } from "./sections/RecruitSection";
import { UploadArea } from "./UploadFiles";

const isWaitingForFeedback = initialValues =>
  "state" in initialValues && initialValues.state === requestStates.refine;

const finalValidationSchema = FeasibilityRequestSchema;

const FeasibilityRequestLabel = props => (
  <CustomFormLabel validationSchema={finalValidationSchema} {...props} />
);

const booleanPropertiesMap = ["cells_specify", "other_liquid_samples_specify"];

export const FeasibilityRequest = ({
  initialValues,
  isLoading,
  errorPrefilling,
  id,
  disabled
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmittedForApproval, setSubmittedForApproval] = useState(false);

  const schema = isSubmittedForApproval
    ? finalValidationSchema
    : SaveFeasibilityRequestSchema;

  const FeasibilityRequestFormControl = props => (
    <CustomFormControl
      disabled={disabled}
      {...props}
      validationSchema={schema}
      finalValidationSchema={finalValidationSchema}
    />
  );

  const FeasibilityRequestSwitch = props => (
    <Radio
      type="switch"
      disabled={disabled}
      {...props}
      validationSchema={schema}
    />
  );
  const FeasibilityRequestRadio = props => (
    <Radio
      type="radio"
      disabled={disabled}
      {...props}
      validationSchema={schema}
    />
  );
  const FeasibilityRequestCheckBox = props => (
    <CheckBox disabled={disabled} {...props} validationSchema={schema} />
  );

  const errorBox = isWaitingForFeedback(initialValues) && (
    <Alert variant="danger">{t("frform_error_returned_for_more")}</Alert>
  );
  const formRef = useRef(null);

  const handleClick = (event, handleSubmit, isSubmittedForApproval) => {
    setTimeout(() => {
      formRef.current.checkValidity();
      formRef.current.reportValidity();
    }, 0);
    setTimeout(handleSubmit, 0);
    event.preventDefault();
    setSubmittedForApproval(isSubmittedForApproval);
  };

  const onSubmit = async values => {
    const transformedValues = transformAttrTypesToApiFormat({
      numberToBoolean: booleanPropertiesMap,
      values
    });
    const checkedValues = fillMissingValues(transformedValues, id);
    let submit;

    if (isSubmittedForApproval) {
      const values = cleanPropsForNonSelectedSections(checkedValues);

      submit = dispatch(
        id
          ? updateFeasibilityRequestForApproval(id, values)
          : createFeasibilityRequestForApproval(values)
      );
    } else {
      submit = dispatch(
        id
          ? updateFeasibilityRequest(id, checkedValues)
          : createFeasibilityRequest(checkedValues)
      );
    }
    await submit;
    // empty the cart now
    dispatch(emptyCart());
  };

  const processedInitialValues = id
    ? transformAttrTypesToApiFormat({
        booleanToNumber: booleanPropertiesMap,
        values: initialValues
      })
    : initialValues;

  return (
    <>
      <Title>{t("feasibility_request")}</Title>
      <HorizontalLine className={"double-vertical-margin-bottom"} />
      <LoadingWrapper
        isLoading={isLoading}
        isInErrorState={errorPrefilling}
        errorMsg={t("form_api_offline")}
        loadingComponent={<LoadingForm />}
      >
        <Formik
          enableReinitialize={true}
          initialValues={nullsToEmptyStrings(processedInitialValues)}
          validationSchema={schema}
          onSubmit={onSubmit}
        >
          {formProps => (
            <Form onSubmit={formProps.handleSubmit} ref={formRef}>
              <UnsavedChangesPrompt isEnabled={formProps.dirty} />
              <Container>
                {errorBox}
                <Row>
                  <ContactPersonSection
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                    countryCodeLength={countryCodeLength}
                  />
                  <BackgroundOrganizationSection
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                    countryCodeLength={countryCodeLength}
                  />
                  <BioBanksSection
                    formProps={formProps}
                    feasibilityRequestLabel={FeasibilityRequestLabel}
                    feasibilityRequestCheckBox={FeasibilityRequestCheckBox}
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                  />
                  <ProjectDetailsSection
                    formProps={formProps}
                    feasibilityRequestLabel={FeasibilityRequestLabel}
                    feasibilityRequestRadio={FeasibilityRequestRadio}
                    feasibilityRequestSwitch={FeasibilityRequestSwitch}
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                    maxProjectDescriptionLength={maxProjectDescriptionLength}
                  />
                  <MaterialSection
                    formProps={formProps}
                    feasibilityRequestLabel={FeasibilityRequestLabel}
                    feasibilityRequestCheckBox={FeasibilityRequestCheckBox}
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                  />
                  <LiquidSamplesSection
                    formProps={formProps}
                    feasibilityRequestLabel={FeasibilityRequestLabel}
                    feasibilityRequestCheckBox={FeasibilityRequestCheckBox}
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                  />
                  <TissueSamplesSection
                    formProps={formProps}
                    feasibilityRequestLabel={FeasibilityRequestLabel}
                    feasibilityRequestCheckBox={FeasibilityRequestCheckBox}
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                  />
                  <PhenotypeLifestyleSection
                    formProps={formProps}
                    feasibilityRequestLabel={FeasibilityRequestLabel}
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                  >
                    <UploadArea
                      fieldName={"phenotype_lifestyle_files"}
                      initialFiles={formProps.values.phenotype_lifestyle_files}
                      formProps={formProps}
                      disabled={disabled}
                      requestId={id}
                    />
                  </PhenotypeLifestyleSection>
                  <GenotypeSection
                    formProps={formProps}
                    feasibilityRequestLabel={FeasibilityRequestLabel}
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                  >
                    <UploadArea
                      fieldName={"genotype_files"}
                      initialFiles={formProps.values.genotype_files}
                      formProps={formProps}
                      disabled={disabled}
                      requestId={id}
                    />
                  </GenotypeSection>
                  <RecruitSection
                    formProps={formProps}
                    feasibilityRequestLabel={FeasibilityRequestLabel}
                    feasibilityRequestFormControl={
                      FeasibilityRequestFormControl
                    }
                  >
                    <UploadArea
                      fieldName={"recruitment_files"}
                      initialFiles={formProps.values.recruitment_files}
                      formProps={formProps}
                      disabled={disabled}
                      requestId={id}
                    />
                  </RecruitSection>
                </Row>
              </Container>
              <HorizontalLine className={"top-margin"} />
              {!disabled && (
                <Container className="form-submit-row">
                  <Row>
                    <Col lg={{ span: 8, offset: 4 }}>
                      <div className={"button-group"}>
                        <button
                          className={"button button-secondary"}
                          type="submit"
                          disabled={formProps.isSubmitting}
                          onClick={event =>
                            handleClick(event, formProps.handleSubmit, false)
                          }
                        >
                          {t("save_and_continue_later")}
                        </button>

                        <button
                          disabled={formProps.isSubmitting}
                          className={"button"}
                          type="submit"
                          onClick={event =>
                            handleClick(event, formProps.handleSubmit, true)
                          }
                        >
                          {t("submit_for_approval")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              )}
            </Form>
          )}
        </Formik>
      </LoadingWrapper>
    </>
  );
};

FeasibilityRequest.defaultProps = {
  initialValues
};

export default FeasibilityRequest;
