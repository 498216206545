import { useLocation } from "react-router";

import styles from "./app.module.scss";
import CartContainer from "./CartContainer";
import FooterContainer from "./FooterContainer";
import Header from "./Header";
import { StyledDarkMain } from "./Layout.styles";
import SearchBoxContainer from "./SearchBoxContainer";

const Layout = ({ children }) => {
  const { pathname } = useLocation();

  const pathsWithHeaderAndFooter = [
    "/catalog",
    "/cohorts",
    "/experts",
    "/access-request",
    "/feasibility-request",
    "/requests",
    "/user/edit-profile",
    "/static"
  ];

  const pathsWithDarkTheme = ["/cohorts"];

  const pathsWithCart = ["/catalog", "/cohorts"];

  const hasHeaderAndFooter = pathsWithHeaderAndFooter.some(path =>
    pathname.includes(path)
  );
  const hasDarkTheme = pathsWithDarkTheme.some(path => pathname.includes(path));
  const hasCart = pathsWithCart.some(path => pathname.includes(path));
  return (
    <>
      {hasHeaderAndFooter && (
        <Header collapsed={pathname.match(/samples/)}>
          {pathname.match(/catalog/) && <SearchBoxContainer />}
        </Header>
      )}

      {hasDarkTheme ? (
        <StyledDarkMain>{children}</StyledDarkMain>
      ) : (
        <main className={styles.contentWrapper}>{children}</main>
      )}

      {hasHeaderAndFooter && <FooterContainer />}
      {hasCart && <CartContainer />}
    </>
  );
};

export default Layout;
