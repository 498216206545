import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import React from "react";
import { useTranslation } from "react-i18next";

export const CommonContactInfoSection = ({
  formControl: FormControl,
  children,
  prefix,
  disabled = false
}) => {
  const { t } = useTranslation();
  const genId = fieldName => "form" + prefix.toUpperCase() + fieldName;
  const genName = fieldName => prefix.toLowerCase() + "_" + fieldName;

  return (
    <>
      <Form.Row>
        <Form.Group as={Col} controlId={genId("firstname")}>
          <FormControl
            name={genName("firstname")}
            label={t("firstname")}
            maxLength={40}
            disabled={disabled}
          />
        </Form.Group>

        <Form.Group as={Col} controlId={genId("lastname")}>
          <FormControl
            name={genName("surname")}
            label={t("lastname")}
            maxLength={50}
            disabled={disabled}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group controlId={genId("email")}>
        <FormControl
          type="email"
          name={genName("email")}
          label={t("email")}
          maxLength={100}
          disabled={disabled}
        />
      </Form.Group>
      <Form.Group controlId={genId("company")}>
        <FormControl
          name={genName("company")}
          label={t("organization")}
          maxLength={100}
          disabled={disabled}
        />
      </Form.Group>
      {children}
    </>
  );
};
