import config from "../config";
import feasibilityRequestToLegacy from "../helpers/feasibilityRequestToLegacy";
import useFetchRequest from "./useFetchRequest";

const useFetchFeasibilityRequest = id => {
  const { request, loading, error } = useFetchRequest(
    `${config.requestsUrl}/feasibility-request/${id}`
  );

  return {
    feasibilityRequest: feasibilityRequestToLegacy(request),
    loading,
    error
  };
};

export default useFetchFeasibilityRequest;
