export const renderQuantity = quantity => {
  if (quantity === 0) {
    return 0;
  }
  return quantity > 4 ? quantity : "<5";
};

export const toUnique = arr => [...new Set(arr)];

export const booleanAsString = boolValue => (boolValue ? "T" : "F");
export const booleanStringAsBoolean = boolValue => boolValue === "T";

export const isDeprecatedByDate = (deprecatedDate, checkingDate) =>
  new Date(checkingDate) <= new Date(deprecatedDate);

/**
 * Checks how similar 2 given strings are
 *
 * @see https://stackoverflow.com/questions/10473745/compare-strings-javascript-return-of-likely
 */
export const stringSimilarity = (compareTerm1, compareTerm2) => {
  const longer =
    compareTerm1.length < compareTerm2.length ? compareTerm2 : compareTerm1;
  const shorter =
    compareTerm1.length < compareTerm2.length ? compareTerm1 : compareTerm2;
  const longerLength = longer.length;

  if (longerLength === 0) {
    return 1.0;
  }

  const editDistance = () => {
    compareTerm1 = compareTerm1.toLowerCase();
    compareTerm2 = compareTerm2.toLowerCase();

    const costs = [];
    for (let i = 0; i <= compareTerm1.length; i++) {
      let lastValue = i;

      for (let j = 0; j <= compareTerm2.length; j++) {
        if (i === 0) {
          costs[j] = j;
        } else {
          if (j > 0) {
            let newValue = costs[j - 1];

            if (compareTerm1.charAt(i - 1) !== compareTerm2.charAt(j - 1)) {
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            }

            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }

      if (i > 0) {
        costs[compareTerm2.length] = lastValue;
      }
    }

    return costs[compareTerm2.length];
  };

  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
};

export const filterSimilarStrings = ({
  filterWith = "",
  filterFrom = [],
  similarityThreshold = 0.3
}) =>
  filterFrom.filter(entry => {
    const comp1 = entry.toLowerCase();
    const comp2 = filterWith.toLowerCase();

    return (
      comp1.includes(comp2) ||
      stringSimilarity(comp1, comp2) >= similarityThreshold
    );
  });
