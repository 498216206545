import MTACentralTerms, {
  mtaCentralTermsUrl
} from "../pages/static/MTACentralTerms";
import TermsAndConditions, {
  termsAndConditionsUrl,
  termsAndConditionsUrlPreLogin
} from "../pages/static/TermsAndConditions";

const routes = [
  {
    path: mtaCentralTermsUrl,
    private: false,
    exact: true,
    element: <MTACentralTerms />
  },
  {
    path: termsAndConditionsUrl,
    private: false,
    exact: true,
    element: <TermsAndConditions />
  },
  {
    path: termsAndConditionsUrlPreLogin,
    private: false,
    exact: true,
    element: <TermsAndConditions />
  }
];

export default routes;
