import axios from "../helpers/axios";
import {
  REMOVE_FILE_FROM_ACCESS_REQUEST,
  TOGGLE_ACCESS_REQUEST_ARCHIVED_STATUS
} from "./access_request.actions";
import config from "../config";
import { push, replace } from "redux-first-history";
import {
  getAccessRequestEditUrl,
  getAccessRequestStatusUrl,
  getRequestListUrl
} from "../helpers/linkGenerator";
import { handleError, postMultipartFormData } from "../helpers/fetchHelper";
import requestStates from "../common/requestStates";
import notify from "../helpers/notification";
import { notifyArchived } from "./requestActionHelper";

const postAccessRequestUrl = `${config.requestsUrl}/access-request`;

const fieldsToNotFlatten = [];

const notifySaved = () => notify("saved", "arForm_saved_desc");

const savedState = {
  state: requestStates.saved
};

export const createAccessRequest = values => {
  return async dispatch => {
    try {
      const response = await postMultipartFormData(
        postAccessRequestUrl,
        { ...values, ...savedState },
        fieldsToNotFlatten
      );

      const accessRequestId = response.data.id;
      notifySaved();
      dispatch(replace(getAccessRequestEditUrl(accessRequestId)));
    } catch (error) {
      handleError(error, "Error creating new access request");
    }
  };
};

export const updateAccessRequest = (id, values) => {
  return async dispatch => {
    try {
      const response = await postMultipartFormData(
        `${postAccessRequestUrl}/own/${id}`,
        { ...values, ...savedState },
        fieldsToNotFlatten
      );

      const accessRequestId = response.data.id;
      notifySaved();
      dispatch(replace(getAccessRequestEditUrl(accessRequestId)));
    } catch (error) {
      handleError(error, `Error updating access request (id: ${id})`);
    }
  };
};

const fieldIndicatingApproval = {
  state: requestStates.submitted
};

export const createAccessRequestForApproval = values => {
  return async dispatch => {
    try {
      const response = await postMultipartFormData(
        postAccessRequestUrl,
        {
          ...values,
          ...fieldIndicatingApproval
        },
        fieldsToNotFlatten
      );

      const accessRequestId = response.data.id;

      dispatch(replace(getAccessRequestStatusUrl(accessRequestId)));
    } catch (error) {
      handleError(error, "Error sending access request for approval");
    }
  };
};

export const updateAccessRequestForApproval = (id, values) => {
  return async dispatch => {
    try {
      const response = await postMultipartFormData(
        `${postAccessRequestUrl}/own/${id}`,
        {
          ...values,
          ...fieldIndicatingApproval
        },
        fieldsToNotFlatten
      );

      const accessRequestId = response.data.id;

      dispatch(replace(getAccessRequestStatusUrl(accessRequestId)));
    } catch (error) {
      handleError(
        error,
        `Error sending existing access request (id: ${id}) for approval`
      );
    }
  };
};

export const removeAttachment = (filename, id) => {
  return async () => {
    try {
      await axios.post(
        `${config.requestsUrl}/access-request/attachment/delete`,
        {
          fileName: filename,
          requestId: id
        }
      );
      return filename;
    } catch (error) {
      handleError(error, `Error removing attachment with id ${filename}`);
    }
  };
};

export const setFileRemoved = attachmentId => ({
  type: REMOVE_FILE_FROM_ACCESS_REQUEST,
  attachmentId
});

export const toggleAccessRequestArchivedStatus = (id, archive) => {
  return async dispatch => {
    try {
      await postMultipartFormData(`${postAccessRequestUrl}/archive/${id}`, {});

      notifyArchived(archive);

      dispatch(setAccessRequestArchivedStatus(id, archive));
      dispatch(push(getRequestListUrl(archive)));
    } catch (error) {
      handleError(error, `Error archiving access request id ${id}`);
    }
  };
};

export const setAccessRequestArchivedStatus = (id, archive) => ({
  type: TOGGLE_ACCESS_REQUEST_ARCHIVED_STATUS,
  id,
  archive
});
