import {
  FETCH_COHORT_COLLECTION,
  FETCH_COHORT_COLLECTION_SUCCESS,
  FETCH_COHORT_COLLECTION_FAILURE,
  FETCH_COHORT_COLLECTIONS,
  FETCH_COHORT_COLLECTIONS_FAILURE,
  FETCH_COHORT_COLLECTIONS_SUCCESS
} from "../actions/cohort-collections.actions";

export const defaultState = {
  loading: false,
  error: false,
  cohortCollections: [],
  selectedCohortCollection: null
};

export const populateCohortCollections = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_COHORT_COLLECTIONS:
      return {
        ...state,
        loading: true,
        error: false
      };
    case FETCH_COHORT_COLLECTIONS_SUCCESS:
      return {
        ...state,
        cohortCollections: action.results,
        loading: false
      };
    case FETCH_COHORT_COLLECTIONS_FAILURE:
      return {
        ...state,
        error: true,
        loading: false
      };
    case FETCH_COHORT_COLLECTION:
      return {
        ...state,
        loading: true,
        error: false
      };
    case FETCH_COHORT_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        selectedCohortCollection: action.result
      };
    case FETCH_COHORT_COLLECTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
