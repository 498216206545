import { SELECTED_MATERIALS } from "../common/selectedMaterials";
import { specimenTypeMapping } from "./feasibilityRequestMapper";

export const LIQUIDS_SAMPLES_IDS = [
  specimenTypeMapping.wholeblood,
  specimenTypeMapping.serum,
  specimenTypeMapping.plasma,
  specimenTypeMapping.buffycoat,
  specimenTypeMapping.dna,
  specimenTypeMapping.ct_dna,
  specimenTypeMapping.rna,
  specimenTypeMapping.csf
];

export const TISSUE_SAMPLES_IDS = [
  specimenTypeMapping.ffpe_tissue,
  specimenTypeMapping.tma_slides,
  specimenTypeMapping.fresh_frozen_tissue,
  specimenTypeMapping.digitized_scanned_slides
];

/**
 * Maps the selected materials and properties per sections
 * Each selected material contains a list of properties that needs to be cleaned
 * what should it be the new value
 */
export const ACCESS_PROPERTIES_BY_SECTIONS_MAP = {
  [`${SELECTED_MATERIALS.SAMPLE}`]: {
    selected_materials: materials =>
      materials.filter(
        val =>
          ![SELECTED_MATERIALS.LIQUID, SELECTED_MATERIALS.TISSUE].includes(val)
      )
  },
  [`${SELECTED_MATERIALS.LIQUID}`]: {
    sample_types: sampleTypes => {
      return sampleTypes.filter(
        sampleId => !LIQUIDS_SAMPLES_IDS.includes(sampleId)
      );
    },
    other_cell_sample_type: "",
    other_liquid_sample_type: "",
    liquid_samples_additional_info: "",
    sample_volume_description: "",
    samples_from_each_donor: [],
    requirements_for_critical_study: ""
  },
  [`${SELECTED_MATERIALS.TISSUE}`]: {
    sample_types: sampleTypes => {
      return sampleTypes.filter(
        sampleId => !TISSUE_SAMPLES_IDS.includes(sampleId)
      );
    },
    tissue_sample_inclusion_criteria_description: "",
    tissue_sample_exclusion_criteria_description: "",
    tissue_sample_additional_info_description: "",
    pathologist_by_biobank: false,
    pathologist_by_specialisation: false,
    p_firstname: "",
    p_surname: "",
    p_email: "",
    p_company: "",
    p_phone: "",
    p_address: "",
    p_countrycd: "",
    p_zip: "",
    p_city: ""
  },
  [`${SELECTED_MATERIALS.PHENOTYPE}`]: {
    phenotype_or_lifestyle_desc: ""
  },
  GENOTYPE: {
    genotype_desc: ""
  },
  [`${SELECTED_MATERIALS.DONOR}`]: {
    recruitment_desc: ""
  }
};

/**
 * Maps the selected materials and properties per sections
 * Each selected material contains a list of properties that needs to be cleaned
 * what should it be the new value
 */
export const FEASIBILITY_PROPERTIES_BY_SECTIONS_MAP = {
  [`${SELECTED_MATERIALS.SAMPLE}`]: {
    selected_materials: materials =>
      materials.filter(
        val =>
          ![SELECTED_MATERIALS.LIQUID, SELECTED_MATERIALS.TISSUE].includes(val)
      )
  },
  [`${SELECTED_MATERIALS.LIQUID}`]: {
    sample_types: sampleTypes => {
      return sampleTypes.filter(
        sampleId => !LIQUIDS_SAMPLES_IDS.includes(sampleId)
      );
    },
    other_cell_sample_type: "",
    other_liquid_sample_type: "",
    liquid_samples_additional_info: "",
    sample_volume_description: "",
    samples_from_each_donor: [],
    requirements_for_critical_study: "",
    liquid_sample_inclusion_criteria_description: "",
    liquid_sample_exclusion_criteria_description: ""
  },
  [`${SELECTED_MATERIALS.TISSUE}`]: {
    sample_types: sampleTypes => {
      return sampleTypes.filter(
        sampleId => !TISSUE_SAMPLES_IDS.includes(sampleId)
      );
    },
    tissue_sample_inclusion_criteria_description: "",
    tissue_sample_exclusion_criteria_description: "",
    tissue_sample_additional_info_description: ""
  },
  [`${SELECTED_MATERIALS.PHENOTYPE}`]: {
    phenotype_or_lifestyle_desc: ""
  },
  GENOTYPE: {
    genotype_desc: ""
  },
  [`${SELECTED_MATERIALS.DONOR}`]: {
    recruitment_desc: ""
  }
};
