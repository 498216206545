import { RequestSection } from "../../RequestSection";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

import { specimenTypeMapping } from "../../../helpers/feasibilityRequestMapper";

export const GenotypeSection = ({
  formProps,
  feasibilityRequestFormControl: FeasibilityRequestFormControl,
  feasibilityRequestLabel: FeasibilityRequestLabel,
  children
}) => {
  const { t } = useTranslation();
  const { values } = formProps;
  const isGenotypeEnabled = values.sample_types.includes(
    specimenTypeMapping.genome_data
  );

  return (
    isGenotypeEnabled && (
      <RequestSection
        title={t("genotype_data_samples")}
        description={t("genotype_data_description")}
      >
        <FeasibilityRequestFormControl
          as={"textarea"}
          name={"genotype_desc"}
          label={t("genotype_data_label")}
          placeholder={t("genotype_data_eg")}
        />
        <Form.Group>
          <FeasibilityRequestLabel
            formProps={formProps}
            fieldName={"attachements"}
          >
            <h5>{t("add_attachments")}</h5>
          </FeasibilityRequestLabel>
          {children}
        </Form.Group>
      </RequestSection>
    )
  );
};
