import AccessRequestContainer from "../pages/AccessRequestContainer";
import AccessRequestFromFeasibilityRequestContainer from "../pages/AccessRequestFromFeasibilityRequestContainer";
import AccessRequestStatus from "../pages/AccessRequestStatus/AccessRequestStatus";
import EditAccessRequestContainer from "../pages/EditAccessRequestContainer";

export const accessRequestUrl = "/access-request";

const routes = [
  {
    path: `${accessRequestUrl}/new`,
    exact: true,
    private: true,
    element: <AccessRequestContainer />
  },
  {
    path: `${accessRequestUrl}/new/:feasibilityRequestId`,
    exact: true,
    private: true,
    element: <AccessRequestFromFeasibilityRequestContainer />
  },
  {
    path: `${accessRequestUrl}/edit/:accessRequestId`,
    exact: true,
    private: true,
    element: <EditAccessRequestContainer />
  },
  {
    path: `${accessRequestUrl}/status/:accessRequestId`,
    private: true,
    element: <AccessRequestStatus />
  }
];

export default routes;
