const contactInfoFields = [
  "firstname",
  "surname",
  "email",
  "company",
  "phone",
  "address",
  "countrycd",
  "zip",
  "city"
];

const getContactInfoFields = (prefix, extraFields = []) =>
  [...contactInfoFields, ...extraFields].reduce((result, key) => {
    result[`${prefix}${key}`] = "";
    return result;
  }, {});

export const initialValues = {
  // common / generic
  submitForApprovalClicked: false,
  sample_types: [],

  // person contact details section
  ...getContactInfoFields("c_"),

  // Background organisation details section
  ...getContactInfoFields("bg_", ["businessid"]),

  // Biobanks section
  included_biobanks: [],
  selected_cohort_names: "",

  // Project details section
  projektin_nimi: "",
  brief_project_desc: "",
  starting_month: "",
  starting_year: "",
  ending_month: "",
  ending_year: "",
  project_timeline_months: "",
  have_budget: "",
  budget_until_year: "",
  budget: "",
  project_desc: "",
  project_summary: "",

  // materials section
  preselected_research_cohort: "",
  selected_materials: [],
  material_inclusion_criteria: "",
  material_exclusion_criteria: "",

  // Liquid samples section
  other_cell_sample_type: "",
  other_liquid_sample_type: "",
  liquid_samples_additional_info: "",
  sample_volume_description: "",
  samples_from_each_donor: [],
  requirements_for_critical_study: "",
  liquid_sample_inclusion_criteria_description: "",
  liquid_sample_exclusion_criteria_description: "",

  // tissue samples section
  tissue_sample_inclusion_criteria: "",
  tissue_sample_exclusion_criteria: "",
  tissue_sample_additional_info: "",
  pathologist_by_biobank: "",
  pathologist_by_specialisation: "",
  ...getContactInfoFields("p_"),

  // Phenotype or lifestyle section
  phenotype_or_lifestyle_desc: "",
  phenotype_lifestyle_files: [],

  // Genotype section
  genotype_desc: "",
  genotype_files: [],

  // Sample donor recruit section
  recruitment_desc: "",
  recruitment_files: [],

  // Returning results data section
  est_data_return_month: "",
  est_data_return_year: "",
  what_results_returns: "",
  relevant_findings_found: "",
  what_findings: "",

  // Invoicing details section
  invoicing_method: "",
  i_company: "",
  i_address: "",
  i_countrycd: "",
  i_zip: "",
  i_city: "",
  i_businessid: "",
  i_reference: "",
  i_email: "",
  i_invaddress: "",
  i_operator: "",
  i_broker: "",

  // Ethical matters section
  researchers: "",
  ethicscommitteestatementavailable: "F",
  scienceprofit: "",
  ethical_evaluation: "",
  research_arguments: "",
  hashealthbenefits: "",
  healthbenefits: "",
  accidental_findings: "",
  hasrisks: "",
  risks: "",
  linkages: "",
  mainfunding: "",
  research_result_publish_desc: "",

  // Mandatory files section
  mandatory_files: [],
  permits_files: [],

  /**************************************
   *          DEPRECATED
   **************************************/

  // research type | project details
  projecttype: "",
  project_short_name: "",
  exact_desc_data: "",

  // materials
  samples_data: "",

  // Person in charge
  ...getContactInfoFields("r_"),

  // Public summary

  // Description of samples requested
  sequence_data_desc: "",
  other_sample_type: "",
  exact_desc: "",
  requirements: "",
  special_requirements: "",
  ...getContactInfoFields("a_"),

  // Description of the data requested
  who_collects_data: [],
  who_collects_data_desc: "",

  // Pathologist resources
  isauriapato: "F",
  cotykssapapato: "F",

  // Project details

  goals_of_the_study: "",
  diseases_of_interest: "",
  excluded_icd10_codes: "",
  pathological_samples_snomed_codes: "",
  patient_age_range: "",
  patient_gender: [],
  other_Inclusion_exclusion_criterias: "",

  other_sample_type_criteria: "",
  sampling_time: "",
  clinical_data_linked: "",
  timelines_for_project: "",

  // Mandatory documents
  mandatory_documents: "T",

  // Attachments

  files: []
};

export default initialValues;
