import React from "react";
import styles from "./field-error-block.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";

const FieldErrorBlock = ({ children }) => (
  <div className={classNames("invalid-feedback", styles.fieldError)}>
    {children}
  </div>
);

FieldErrorBlock.props = {
  children: PropTypes.node.isRequired
};

export default FieldErrorBlock;
