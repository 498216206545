import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import FeasibilityRequest from "../components/Feasibilityrequest/FeasibilityRequest";
import { useSelector } from "react-redux";
import initialValues from "../components/Feasibilityrequest/feasibilityRequestState";
import { toUnique } from "../helpers/utils";
import useUser from "../hooks/useUser";
import {
  genderMapping,
  specimenTypeMapping
} from "../helpers/feasibilityRequestMapper";
import {
  mapUserInfoToFormFields,
  preSelectedBiobanks
} from "../helpers/requestHelper";
import { CATEGORY_CATALOG, CATEGORY_COHORT } from "../actions/category-types";
import { SELECTED_MATERIALS } from "../common/selectedMaterials";

const liquidSamples = [
  specimenTypeMapping.buffycoat,
  specimenTypeMapping.serum,
  specimenTypeMapping.wholeblood,
  specimenTypeMapping.plasma,
  specimenTypeMapping.dna,
  specimenTypeMapping.ct_dna,
  specimenTypeMapping.rna,
  specimenTypeMapping.csf
];
const tissueSamples = [
  specimenTypeMapping.ffpe_tissue,
  specimenTypeMapping.tma_slides,
  specimenTypeMapping.fresh_frozen_tissue,
  specimenTypeMapping.digitized_scanned_slides
];

const getGenders = items =>
  items.reduce((result, curItem) => {
    const genders = curItem.filters.find(filter => filter.field === "gender");
    if (genders) {
      return result.concat(genders.values);
    } else {
      return result;
    }
  }, []);

const toSpecimenTypeValue = item => specimenTypeMapping[item.specimenType];

const isGenomeDataAvailableFilter = filter =>
  filter.field === "genome_data_available";

const hasGenomeDataAvailableFilter = item =>
  item.filters.find(isGenomeDataAvailableFilter);

const getGenomeDataValue = items =>
  items.find(hasGenomeDataAvailableFilter)
    ? [specimenTypeMapping.genome_data]
    : [];

const getICD10Code = item => item.codeInfo.code;

const getGenderValue = gender => genderMapping[gender];

const isContactPermissionFilter = filter =>
  filter.field === "contact_permission";

const hasContactPermissionFilter = item =>
  item.filters.find(isContactPermissionFilter);

const getSampleTypes = items =>
  toUnique([...items.map(toSpecimenTypeValue), ...getGenomeDataValue(items)]);

const getDiseasesOfInterest = items => items.map(getICD10Code).join(", ");

const getCohortNames = items =>
  items.map(item => item.codeInfo.title).join(", ");

const getSelectedMaterials = selectedSampleTypes => {
  const selected_materials = [];

  const isLiquidSampleEnabled = !!selectedSampleTypes.find(sample =>
    liquidSamples.includes(sample)
  );
  const isTissueSampleEnabled = !!selectedSampleTypes.find(sample =>
    tissueSamples.includes(sample)
  );

  if (isLiquidSampleEnabled || isTissueSampleEnabled) {
    selected_materials.push(SELECTED_MATERIALS.SAMPLE);
    if (isLiquidSampleEnabled) {
      selected_materials.push(SELECTED_MATERIALS.LIQUID);
    }
    if (isTissueSampleEnabled) {
      selected_materials.push(SELECTED_MATERIALS.TISSUE);
    }
  }

  return {
    selected_materials
  };
};

const getCartItems = (redirectedFromCatalog, items, t) => {
  if (!redirectedFromCatalog) return { from_catalog: 0 };

  const catalogItems = items.filter(item => item.category === CATEGORY_CATALOG);
  const cohortItems = items.filter(item => item.category === CATEGORY_COHORT);
  const sample_types = getSampleTypes(catalogItems);

  return {
    sample_types,
    diseases_of_interest: getDiseasesOfInterest(catalogItems),
    other_Inclusion_exclusion_criterias: catalogItems.find(
      hasContactPermissionFilter
    )
      ? t("data_filter_value_contact_permission")
      : "",
    patient_gender: getGenders(catalogItems).map(getGenderValue),
    filter_genders: getGenders(catalogItems),
    from_catalog: 1,
    selected_cohort_names: getCohortNames(cohortItems)
  };
};

const getMaterialsInclusionCriteria = cartItems => {
  const {
    filter_genders: filterGenders,
    diseases_of_interest: diseasesOfInterest,
    other_Inclusion_exclusion_criterias: otherInclusionCriteria
  } = cartItems;
  let inclustionCriteria = "";
  if (diseasesOfInterest) {
    const cleanListOfDiseases = diseasesOfInterest
      .split(`,`)
      .map(disease => disease.trim());
    const uniquediseasesOfInterest = [...new Set(cleanListOfDiseases)].join(
      `, `
    );
    inclustionCriteria = inclustionCriteria.concat(uniquediseasesOfInterest);
  }
  if (filterGenders && filterGenders.length > 0) {
    const uniqueGenders = [...new Set(filterGenders)].join(", ");
    inclustionCriteria = inclustionCriteria.concat(`, ${uniqueGenders}`);
  }
  if (otherInclusionCriteria) {
    inclustionCriteria = inclustionCriteria.concat(
      `, ${otherInclusionCriteria}`
    );
  }
  inclustionCriteria = inclustionCriteria.replace(/^,/g, "");
  return inclustionCriteria;
};

const FeasibilityRequestContainer = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { items } = useSelector(state => state.cart);

  // get the information we use to prefill the user-based fields
  const { user } = useAuth0();
  const { data: userData, loading, error } = useUser(user.sub);

  const queryParams = new URLSearchParams(location.search);
  const redirectedFromCatalog = queryParams.get("from_catalog") ?? false;

  const cartItems = getCartItems(redirectedFromCatalog, items, t);

  const selectedMaterials = cartItems.sample_types
    ? getSelectedMaterials(cartItems.sample_types)
    : {};

  const prefilledValues = {
    ...initialValues,
    included_biobanks: preSelectedBiobanks,
    ...mapUserInfoToFormFields(userData),
    ...cartItems,
    ...selectedMaterials,
    material_inclusion_criteria: getMaterialsInclusionCriteria(cartItems)
  };

  return (
    <FeasibilityRequest
      isLoading={loading}
      errorPrefilling={error}
      initialValues={prefilledValues}
    />
  );
};
export default FeasibilityRequestContainer;
