import {
  FETCH_MIABIS_COLLECTION_TYPES,
  FETCH_MIABIS_COLLECTION_TYPES_FAILURE,
  FETCH_MIABIS_COLLECTION_TYPES_SUCCESS
} from "../actions/miabis-collection-types.actions";

export const defaultState = {
  loading: false,
  error: false,
  miabisCollectionTypes: []
};

export const populateMiabisCollectionTypes = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_MIABIS_COLLECTION_TYPES:
      return {
        ...state,
        loading: true,
        error: false
      };
    case FETCH_MIABIS_COLLECTION_TYPES_SUCCESS:
      return {
        ...state,
        miabisCollectionTypes: action.results,
        loading: false
      };
    case FETCH_MIABIS_COLLECTION_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
