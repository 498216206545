import React from "react";
import { Checkbox } from "@finbb/ui-components";
import {
  sortMiabisEntitiesAlphabetically,
  translateMiabisEntityName
} from "../../helpers/field-translate-util";
import {
  StyledFilterColumn,
  StyledFilterList,
  StyledFilterListItem,
  StyledFilterName
} from "./MiabisFilterList.styles";

const MiabisFilterList = props => {
  const { title, miabisEntities, setMiabisEntities, language } = props;

  const toggleMiabisEntity = selectedMiabisEntity => {
    const changedValue = !selectedMiabisEntity.isSelected;
    const newArray = miabisEntities.filter(
      miabisEntity => miabisEntity.id !== selectedMiabisEntity.id
    );
    newArray.push({ ...selectedMiabisEntity, isSelected: changedValue });

    const sortedMiabisEntities = sortMiabisEntitiesAlphabetically(
      newArray,
      language
    );

    setMiabisEntities(sortedMiabisEntities);
  };
  return (
    <StyledFilterColumn>
      <StyledFilterName>{title}</StyledFilterName>
      <StyledFilterList>
        {miabisEntities.map((miabisEntity, index) => (
          <StyledFilterListItem key={miabisEntity.id || index}>
            <Checkbox
              checkboxSide={"left"}
              label={translateMiabisEntityName(miabisEntity, language)}
              isChecked={miabisEntity.isSelected}
              onChange={() => toggleMiabisEntity(miabisEntity)}
            />
          </StyledFilterListItem>
        ))}
      </StyledFilterList>
    </StyledFilterColumn>
  );
};

export default MiabisFilterList;
