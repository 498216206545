import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { fetchSample } from "../actions/samples";
import SampleView from "./SampleView";

const SampleViewContainer = props => {
  const params = useParams();
  const { fetchSample, selectedFilters } = props;

  const fetchData = useCallback(
    target => {
      fetchSample(target, selectedFilters);
    },
    [fetchSample, selectedFilters]
  );

  useEffect(() => {
    fetchData(params.target || null);
  }, [fetchData, params.target, props.lng]);

  return <SampleView {...props} />;
};

const mapStateToProps = state => ({
  currentSample: state.currentSample,
  selectedFilters: state.selectedFilters,
  lng: state.lng
});

export default connect(mapStateToProps, { fetchSample })(SampleViewContainer);
