import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import FileUploader from "../FileUploader/RequestFileUploader";
import RequestFileList from "../FileUploader/RequestFileList";

import { removeAttachment } from "../../actions/feasiblity_request";

const StoragedFiles = ({ files, handleRemoveFile, disabled }) => {
  return (
    <RequestFileList
      disabled={disabled}
      files={files}
      handleRemoveFile={handleRemoveFile}
    />
  );
};

export const UploadArea = ({
  initialFiles = [],
  fieldName,
  formProps,
  disabled,
  requestId = null
}) => {
  const [filesList, setFilesList] = useState(initialFiles || []);
  const dispatch = useDispatch();

  useEffect(() => {
    setFilesList(filesList || []);
  }, [filesList]);

  const getFiles = () =>
    filesList.map(file => ({
      name: file.filename,
      size: file.filesize ? parseInt(file.filesize) : undefined,
      url: file.path
    }));

  const removeFile = fileName => {
    setFilesList(filesList.filter(file => file.filename !== fileName));
  };

  const handleOnDropFile = acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }
    formProps.setFieldValue(fieldName, acceptedFiles);
  };

  const handleRemoveFile = async fileName => {
    const deletedFileName = await dispatch(
      removeAttachment(fileName, requestId)
    );
    if (deletedFileName) {
      removeFile(deletedFileName);
    }
  };

  return (
    <>
      {initialFiles.length >= 0 && (
        <StoragedFiles
          files={getFiles()}
          handleRemoveFile={handleRemoveFile}
          disabled={disabled}
        />
      )}
      {!disabled && <FileUploader onDrop={handleOnDropFile} />}
    </>
  );
};
