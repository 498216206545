import { RequestSection } from "../../RequestSection";
import { Trans, useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

import { specimenTypeMapping } from "../../../helpers/feasibilityRequestMapper";
import { ICD10CodeLink } from "../../ICD10CodeLink";
import { SELECTED_MATERIALS } from "../../../common/selectedMaterials";

export const MaterialSection = ({
  formProps,
  feasibilityRequestLabel: FeasibilityRequestLabel,
  feasibilityRequestCheckBox: FeasibilityRequestCheckBox,
  feasibilityRequestFormControl: FeasibilityRequestFormControl
}) => {
  const { t } = useTranslation();
  const { values } = formProps;

  const isSampleSelected = values.selected_materials.includes(
    SELECTED_MATERIALS.SAMPLE
  );

  return (
    <RequestSection
      title={t("material_title")}
      description={t("material_description")}
    >
      <Form.Group>
        <FeasibilityRequestLabel
          withErrorIndicator
          formProps={formProps}
          fieldName={"types_of_samples_needed"}
        >
          {t("types_of_samples_label")}
        </FeasibilityRequestLabel>
        <FeasibilityRequestCheckBox
          label={t("samples_label")}
          name={"selected_materials"}
          value={SELECTED_MATERIALS.SAMPLE}
        />
        <FeasibilityRequestCheckBox
          label={t("phenotype_or_lifestyle_data_label")}
          name={"selected_materials"}
          value={SELECTED_MATERIALS.PHENOTYPE}
        />
        <FeasibilityRequestCheckBox
          label={t("genome_data")}
          name={"sample_types"}
          value={specimenTypeMapping.genome_data}
        />
        <FeasibilityRequestCheckBox
          label={t("sample_donor_recruit_label")}
          name={"selected_materials"}
          value={SELECTED_MATERIALS.DONOR}
        />
      </Form.Group>
      {isSampleSelected && (
        <Form.Group>
          <FeasibilityRequestLabel
            withErrorIndicator
            formProps={formProps}
            fieldName={"materials_kind_needed"}
          >
            {t("materials_kind_needed_label")}
          </FeasibilityRequestLabel>
          <FeasibilityRequestCheckBox
            label={t("liquid_samples_label")}
            name={"selected_materials"}
            value={SELECTED_MATERIALS.LIQUID}
          />
          <FeasibilityRequestCheckBox
            label={t("tissue_samples_label")}
            name={"selected_materials"}
            value={SELECTED_MATERIALS.TISSUE}
          />
        </Form.Group>
      )}
      <Form.Group>
        <FeasibilityRequestLabel
          withErrorIndicator
          formProps={formProps}
          fieldName={"already_existing_research_cohort"}
        >
          {t("already_existing_research_cohort")}
        </FeasibilityRequestLabel>
        <FeasibilityRequestCheckBox
          label={t("already_existing_research_cohort_label")}
          name={"preselected_research_cohort"}
          value={"preselected_research_cohort"}
        />
      </Form.Group>
      <Form.Group>
        <FeasibilityRequestLabel
          formProps={formProps}
          fieldName={"inclusion_criteria_title"}
        >
          <h5>{t("inclusion_criteria_title")}</h5>
          <Trans
            i18nKey="material_inclusion_criteria_label"
            components={{
              link: <ICD10CodeLink />
            }}
          />
        </FeasibilityRequestLabel>
        <FeasibilityRequestFormControl
          as={"textarea"}
          name={"material_inclusion_criteria"}
          placeholder={t("material_inclusion_criteria_eg")}
          maxLength={2000}
        />
        <FeasibilityRequestLabel
          formProps={formProps}
          fieldName={"exclusion_criteria_title"}
        >
          <h5>{t("exclusion_criteria_title")}</h5>
        </FeasibilityRequestLabel>
        <FeasibilityRequestFormControl
          as={"textarea"}
          name={"material_exclusion_criteria"}
          label={t("material_exclusion_criteria_label")}
          placeholder={t("material_exclusion_criteria_eg")}
          maxLength={2000}
        />
      </Form.Group>
    </RequestSection>
  );
};
