import Container from "react-bootstrap/Container";
import { Code as Loading } from "react-content-loader";
import React from "react";
import ErrorBox from "./ErrorBox";
import { bool, node } from "prop-types";

const LoadingWrapper = ({
  isLoading,
  isInErrorState,
  errorMsg,
  children,
  loadingComponent
}) => {
  if (isLoading && !isInErrorState) {
    return <Container>{loadingComponent || <Loading />}</Container>;
  } else if (isInErrorState) {
    return (
      <Container>
        <ErrorBox msg={errorMsg} />
      </Container>
    );
  } else {
    return children;
  }
};

LoadingWrapper.propTypes = {
  isLoading: bool,
  isInErrorState: bool,
  errorMsg: node.isRequired,
  children: node.isRequired,
  loadingComponent: node
};

export default LoadingWrapper;
