import {
  FETCH_DIRECTORIES_REQUEST,
  FETCH_DIRECTORIES_SUCCESS,
  FETCH_DIRECTORIES_FAILURE
} from "../actions/directory.actions";

import { FETCH_SAMPLE_SUCCESS } from "../actions/samples.actions";

export const populateDirectories = (state = null, action) => {
  switch (action.type) {
    case FETCH_DIRECTORIES_REQUEST:
      return state;
    case FETCH_DIRECTORIES_SUCCESS:
      return action.results;
    case FETCH_DIRECTORIES_FAILURE:
      return state;
    case FETCH_SAMPLE_SUCCESS:
      return null;
    default:
      return state;
  }
};
