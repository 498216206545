import { CHANGE_LANGUAGE } from "../actions/i18n.actions";
import config from "../config";

export const initialState = config.defaultLanguage || "en";

export const lng = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return action.lng;
    default:
      return state;
  }
};
