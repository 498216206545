import classNames from "classnames";
import styles from "./status-block.module.scss";
import React from "react";

const getStyleForColor = color => {
  switch (color) {
    case "orange":
    case "green":
    case "red":
      return styles[color];
    default:
      return "";
  }
};

const StatusBlock = ({ color }) => (
  <div className={classNames(styles.statusBlock, getStyleForColor(color))} />
);

export default StatusBlock;
