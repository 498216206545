import React from "react";
import { NavLink } from "react-router-dom";
import folder from "../assets/img/folder.svg";

const ICD10Category = ({ id, name, label, totalSamples, onClick }) => (
  <NavLink
    to={"/catalog/browse/" + id}
    className="directory-icd10-item directory-icd10-category"
  >
    <div className="left">
      <img src={folder} width="38" alt="" />
      <div className="info">
        <p className="title">{name}</p>
        <p className="label">{label}</p>
      </div>
    </div>
  </NavLink>
);

export default ICD10Category;
