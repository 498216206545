import {
  SEARCH_UPDATE_INPUT_VALUE,
  SEARCH_CLEAR_SUGGESTIONS,
  SEARCH_MAYBE_UPDATE_SUGGESTIONS,
  SEARCH_LOAD_SUGGESTIONS_BEGIN
} from "../actions/search.actions";
import { LOAD_VIEW_PAGE } from "../actions/pages.actions";
import { FETCH_DIRECTORIES_SUCCESS } from "../actions/directory.actions";

export const initialState = {
  value: "",
  suggestions: [],
  isLoading: false
};

export const searchReducer = function(state = initialState, action = {}) {
  switch (action.type) {
    case SEARCH_UPDATE_INPUT_VALUE:
      return {
        ...state,
        value: action.value
      };

    case SEARCH_CLEAR_SUGGESTIONS:
      return {
        ...state,
        suggestions: []
      };

    case LOAD_VIEW_PAGE:
    case FETCH_DIRECTORIES_SUCCESS:
      return {
        ...state,
        suggestions: [],
        value: ""
      };

    case SEARCH_LOAD_SUGGESTIONS_BEGIN:
      return {
        ...state,
        isLoading: true
      };

    case SEARCH_MAYBE_UPDATE_SUGGESTIONS:
      // Ignore suggestions if input value changed
      if (action.value !== state.value) {
        return {
          ...state,
          isLoading: false
        };
      }

      return {
        ...state,
        suggestions: action.suggestions,
        isLoading: false
      };

    default:
      return state;
  }
};
