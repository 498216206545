import styled from "styled-components";

export const StyledCardListItemMessage = styled.li`
  ${({ theme }) => theme.objects.ListItem.Base}
  ${({ theme }) => theme.objects.ListItem.Unobtrusive}
  ${({ theme }) => theme.objects.Copy.Scale.Large}
  ${({ theme }) => theme.objects.Copy.Style.Default.Subtle}
  margin: 0 auto;
`;

export const StyledCardListItem = styled.li`
  ${({ theme }) => theme.objects.ListItem.Base}
  ${({ theme }) => theme.objects.ListItem.Unobtrusive}
`;

export const StyledCardList = styled.ul`
  ${({ theme }) => theme.objects.List.Base}
  ${({ theme }) => theme.objects.List.Unobtrusive}
  ${({ theme }) => theme.mixins.Container("ROW", { wrap: "wrap" })}
  max-width: ${({ theme }) =>
    `calc((${theme.values.dimensions.CARD_WIDTH} + ${theme.values.spacing.MEDIUM}) * 4)`};
  width: 100%;

  > ${StyledCardListItem} {
    flex-shrink: 0;
  }
`;

export const StyledCohortCollectionSearchList = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", { gap: theme.values.spacing.LARGE })}
  width: 100vw;
  max-width: ${({ theme }) =>
    `calc((${theme.values.dimensions.CARD_WIDTH} + ${theme.values.spacing.MEDIUM}) * 4)`};
`;
