import React from "react";
import Container from "react-bootstrap/Container";
import Title from "../../components/Title/Title";
import HorizontalLine from "../../components/HorizontalLine/HorizontalLine";
import { useTranslation } from "react-i18next";
import styles from "./new-request.module.scss";
import iconFeasibilityRequest from "./iconFeasibilityRequest.svg";
import iconAccessRequest from "./iconAccessRequest.svg";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const RequestTypeBlock = ({
  title,
  icon,
  description,
  btn,
  additionalInfo
}) => (
  <div className={styles.requestTypeBlock}>
    {icon}
    <h2 className={styles.requestTitle}>{title}</h2>
    <p
      dangerouslySetInnerHTML={{ __html: description }}
      className={styles.requestDescription}
    />
    {btn}
    {additionalInfo}
  </div>
);

const NewRequest = () => {
  const { t } = useTranslation();

  return (
    <>
      <Title>{t("select_request_type")}</Title>
      <HorizontalLine className={"reset-top-margin"} />
      <Container>
        <Row>
          <Col xs={12} lg={6} className={styles.requestTypeBlockContainer}>
            <RequestTypeBlock
              icon={
                <img
                  src={iconFeasibilityRequest}
                  alt={t("feasibility_request")}
                />
              }
              title={t("feasibility_request")}
              description={t("feasibility_request_description")}
              btn={
                <Link to={"/feasibility-request/new"}>
                  <button className={"button"}>{t("get_started")}</button>
                </Link>
              }
              additionalInfo={
                <small className={styles.recommendedText}>
                  {t("recommended")}
                </small>
              }
            />
          </Col>
          <Col xs={12} lg={6} className={styles.requestTypeBlockContainer}>
            <RequestTypeBlock
              icon={<img src={iconAccessRequest} alt={t("access_request")} />}
              title={t("access_request")}
              description={t("access_request_description")}
              btn={
                <Link to={"/access-request/new"}>
                  <button className={"button button-secondary"}>
                    {t("continue_to_request")}
                  </button>
                </Link>
              }
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewRequest;
