import React from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "./SimpleBar";
import CartSampleButtonContainer from "./CartSampleButtonContainer";
import InfoModalButton from "./InfoModalButton";
import { renderQuantity } from "../helpers/utils";
import classNames from "classnames";

const Card = ({ code, title, quantity, distributions }) => {
  const { t } = useTranslation();

  const cardClasses = classNames({
    card: true,
    "card-disabled": quantity === 0
  });

  return (
    <div className={cardClasses}>
      <div className="info">
        <div className="title">
          {t("data_title_" + title)}{" "}
          <InfoModalButton
            text={
              t("data_title_" + title + "_tooltip") +
              " " +
              t("data_title_generic_tooltip_suffix")
            }
          />
        </div>
        <p className="total">
          <span>{renderQuantity(quantity)}</span>
        </p>
      </div>
      <div className="distributions">
        {distributions.map(distribution => (
          <SimpleBar key={distribution.key} data={distribution} />
        ))}
      </div>
      <div className="controls">
        {quantity > 0 ? (
          <CartSampleButtonContainer
            codeInfo={code}
            specimenType={title}
            sample={distributions}
            quantity={quantity}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Card;
