import { getCatalogUrl } from "../helpers/linkGenerator";
import FrontPage from "../pages/FrontPage";
import ViewPageContainer from "../pages/ViewPageContainer";

const catalogUrl = getCatalogUrl();

const routes = [
  { path: catalogUrl, private: false, exact: true, element: <FrontPage /> },
  {
    path: `${catalogUrl}/browse/:target`,
    private: false,
    exact: true,
    element: <FrontPage />
  },
  {
    path: `${catalogUrl}/samples/:target`,
    private: false,
    element: <ViewPageContainer />,
    header: true
  }
];

export default routes;
