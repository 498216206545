import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@finbb/ui-components";

import toCatalogIfLoggedIn from "../toCatalogIfLoggedIn";
import { termsAndConditionsUrlPreLogin } from "../../pages/static/TermsAndConditions";
import config from "../../config";
import AfterLoginContext from "../../contexts/AfterLogin";
import DividerWithText from "../DividerWithText/DividerWithText";

const LoginDisclaimer = () => {
  const { t } = useTranslation();

  return (
    <p
      className="form-text"
      dangerouslySetInnerHTML={{
        __html: t("login_form_disclaimer", {
          termsUrl: termsAndConditionsUrlPreLogin,
          privacyUrl: config.platformWebUrl + "/privacy-policy"
        })
      }}
    />
  );
};

const Login = () => {
  const { setIsAfterLogin } = useContext(AfterLoginContext);

  const { t, i18n } = useTranslation();
  const { loginWithPopup } = useAuth0();

  const handleLoginClick = async () => {
    await loginWithPopup({ ui_locales: i18n.language });
    setIsAfterLogin(true);
  };

  return (
    <>
      <Button
        onClick={handleLoginClick}
        text={t("login")}
        variant="CALL_TO_ACTION"
        isFullWidth={true}
      />
      <LoginDisclaimer />
      <DividerWithText>{t("sign_up_call_to_action")}</DividerWithText>
      <Button
        onClick={() => (window.location.href = `${config.registrationUrl}`)}
        text={t("sign_up")}
        variant="MINIMAL"
        isFullWidth={true}
      />

      <p className="form-text">{t("sign_up_description")}</p>
    </>
  );
};

export default toCatalogIfLoggedIn(Login);
