import axios from "../helpers/axios";
import {
  REMOVE_FILE_FROM_FEASIBILITY_REQUEST,
  TOGGLE_FEASIBILITY_REQUEST_ARCHIVED_STATUS
} from "./feasibility_request.actions";
import config from "../config";
import { push, replace } from "redux-first-history";
import {
  getFeasibilityRequestEditUrl,
  getFeasibilityRequestStatusUrl,
  getRequestListUrl
} from "../helpers/linkGenerator";
import { handleError, postMultipartFormData } from "../helpers/fetchHelper";
import notify from "../helpers/notification";
import { notifyArchived } from "./requestActionHelper";
import requestStates from "../common/requestStates";

const postFeasibilityRequestUrl = `${config.requestsUrl}/feasibility-request`;

const notifySaved = () => notify("saved", "frForm_saved_desc");

export const createFeasibilityRequest = values => {
  return async dispatch => {
    try {
      const response = await postMultipartFormData(
        postFeasibilityRequestUrl,
        values
      );

      const feasibilityRequestId = response.data.id;
      notifySaved();
      dispatch(replace(getFeasibilityRequestEditUrl(feasibilityRequestId)));
    } catch (error) {
      handleError(error, "Error creating new feasibility request");
    }
  };
};

export const updateFeasibilityRequest = (id, values) => {
  return async dispatch => {
    try {
      const response = await postMultipartFormData(
        `${postFeasibilityRequestUrl}/own/${id}`,
        values
      );

      const feasibilityRequestId = response.data.id;
      notifySaved();
      dispatch(replace(getFeasibilityRequestEditUrl(feasibilityRequestId)));
    } catch (error) {
      handleError(error, `Error updating feasibility request (id: ${id})`);
    }
  };
};

const fieldIndicatingApproval = {
  state: requestStates.submitted
};

export const createFeasibilityRequestForApproval = values => {
  return async dispatch => {
    try {
      const response = await postMultipartFormData(postFeasibilityRequestUrl, {
        ...values,
        ...fieldIndicatingApproval
      });

      const feasibilityRequestId = response.data.id;

      dispatch(replace(getFeasibilityRequestStatusUrl(feasibilityRequestId)));
    } catch (error) {
      handleError(error, "Error sending feasibility request for approval");
    }
  };
};

export const updateFeasibilityRequestForApproval = (id, values) => {
  return async dispatch => {
    try {
      const response = await postMultipartFormData(
        `${postFeasibilityRequestUrl}/own/${id}`,
        {
          ...values,
          ...fieldIndicatingApproval
        }
      );

      const feasibilityRequestId = response.data.id;

      dispatch(replace(getFeasibilityRequestStatusUrl(feasibilityRequestId)));
    } catch (error) {
      handleError(
        error,
        `Error sending existing feasibility request (id: ${id}) for approval`
      );
    }
  };
};

export const removeAttachment = (filename, id) => {
  return async () => {
    try {
      await axios.post(
        `${config.requestsUrl}/feasibility-request/attachment/delete`,
        {
          fileName: filename,
          requestId: id
        }
      );
      return filename;
    } catch (error) {
      handleError(error, `Error removing attachment with id ${filename}`);
    }
  };
};

export const setFileRemoved = attachmentId => ({
  type: REMOVE_FILE_FROM_FEASIBILITY_REQUEST,
  attachmentId
});

export const toggleFeasibilityRequestArchivedStatus = (id, archive) => {
  return async dispatch => {
    try {
      await postMultipartFormData(
        `${postFeasibilityRequestUrl}/archive/${id}`,
        {}
      );

      notifyArchived(archive);

      dispatch(setFeasibilityRequestArchivedStatus(id, archive));
      dispatch(push(getRequestListUrl(archive)));
    } catch (error) {
      handleError(error, `Error archiving feasibility request id ${id}`);
    }
  };
};

export const setFeasibilityRequestArchivedStatus = (id, archive) => ({
  type: TOGGLE_FEASIBILITY_REQUEST_ARCHIVED_STATUS,
  id,
  archive
});
