import styled, { css } from "styled-components";

import searchIcon from "../../assets/img/search.svg";

export const StyledSearchContainer = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("ROW", {
      alignItems: "center",
      justifyContent: "center"
    })}
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
  ${({ theme }) => theme.objects.Copy.Scale.Small}
  flex: 1;
  margin-bottom: 0px;
`;

export const StyledSearchInput = styled.input`
  display: flex;
  width: 100%;
  margin-bottom: 0;
  padding: 25px 140px 25px 60px;
  border-radius: 5px;
  border: 1px solid var(--color-blue);
  outline: 0;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: 20px center;

  ${({ theme }) =>
    theme.mixins.RespondTo(
      "TABLET",
      css`
        width: 70%;
      `
    )}
`;

export const StyledCenteredColumn = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", {
      alignItems: "center"
    })}
  flex: 1;
`;

export const StyledMaxWidthRow = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("ROW", {
      alignItems: "center",
      justifyContent: "space-between",
      wrap: "wrap"
    })}
  max-width: ${({ theme }) =>
    `calc((${theme.values.dimensions.CARD_WIDTH} + ${theme.values.spacing.MEDIUM}) * 4)`};
  padding: 0 ${({ theme }) => theme.values.spacing.MEDIUM};
  width: 100vw;
`;

export const StyledResultBox = styled.div.attrs({
  className: "card"
})`
  & {
    ${({ theme }) =>
      theme.mixins.Container("ROW", {
        alignItems: "start",
        justifyContent: "space-between",
        wrap: "wrap",
        gap: theme.values.spacing.XSMALL
      })}
    background: var(--color-grey-hover);
    border: 1px solid var(--color-light-grey);
    padding: ${({ theme }) => theme.values.spacing.MEDIUM};
    margin: ${({ theme }) => theme.values.spacing.LARGE}
      ${({ theme }) => theme.values.spacing.MEDIUM};
    max-width: calc(var(--max-width));
    width: calc(100vw - 2em);
    min-height: 150px;
  }

  img {
    margin-right: 30px;
  }

  p {
    margin: 0;
  }

  .search-info {
    .info {
      ${({ theme }) =>
        theme.mixins.Container("COLUMN", {
          alignItems: "start",
          justifyContent: "space-between",
          wrap: "wrap"
        })}
    }

    .title {
      font-size: 20px;
    }

    .label {
      margin-top: 0em;
      color: var(--color-grey);
    }
  }

  .search-results {
    ${({ theme }) =>
      theme.mixins.Container("COLUMN", {
        alignItems: "start",
        wrap: "wrap"
      })}

    text-align: center;

    .title {
      display: flex;
      align-items: center;
      align-self: center;
      font-size: 5rem;
      min-height: 5rem;
      margin-bottom: 0px;
    }

    .label {
      display: flex;
      align-items: center;
      align-self: center;
      margin-top: 0em;
    }
  }
`;

export const StyledExpertsSearchPage = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", {
      alignItems: "center",
      wrap: "wrap"
    })}
  width: 100%;
  margin-top: ${({ theme }) => theme.values.spacing.LARGE};
`;

export const StyledFilterButtonContainer = styled.div`
  ${({ theme }) => theme.mixins.Container("COLUMN", { alignItems: "center" })}
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
  ${({ theme }) => theme.objects.Copy.Scale.MEDIUM}

`;

export const StyledFilterContainer = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", { gap: theme.values.spacing.MEDIUM })}
  ul {
    margin-top: 0;
    padding-left: 0;
  }

  max-width: calc(var(--max-width));
  width: calc(100vw - 2em);
  margin: 0 auto;
  ${({ isHidden }) =>
    isHidden &&
    css`
      display: none;
    `}
`;
