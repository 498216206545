import { Col } from "react-bootstrap";
import HorizontalLine from "./HorizontalLine/HorizontalLine";

export const RequestSection = ({
  title,
  description,
  horizontalLineClassName = "double-vertical-margin",
  display = true,
  children
}) => {
  return (
    display && (
      <>
        <HorizontalLine className={horizontalLineClassName} />
        <Col lg={4}>
          <h2>{title}</h2>
          <p className={"section-description"}>{description}</p>
        </Col>
        <Col lg={8}>{children}</Col>
      </>
    )
  );
};
