import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { logout as logoutAction } from "../actions/root";

const LogoutPage = () => {
  const dispatch = useDispatch();
  const { logout, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated && dispatch) {
      logout();
      dispatch(logoutAction());
    }
  }, [dispatch, logout, isAuthenticated]);

  return <></>;
};

export default LogoutPage;
