import {
  USER_LOGIN_SUCCESSFUL,
  USER_LOGIN_FAILURE
} from "../actions/users.actions";

export const initialState = {
  token: null,
  data: {}
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESSFUL:
      return {
        token: action.token,
        data: action.data
      };
    case USER_LOGIN_FAILURE:
      return initialState;
    default:
      return state;
  }
};
