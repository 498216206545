import styled from "styled-components";

export const LoginContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 450px;
  margin: 2rem auto 0;
  text-align: center;
`;
