import React from "react";
import { useMatch } from "react-router-dom";
import { pick } from "lodash";

import DeprecatedAccessRequest from "../components/AccessRequest/DeprecatedAccessRequest";
import { initialValues } from "../components/AccessRequest/accessRequestState";
import useFetchFeasibilityRequest from "../hooks/useFetchFeasibilityRequest";
import { accessRequestUrl } from "../routes/AccessRequestRoutes";
import config from "../config";
import { AccessRequestForm } from "../components/AccessRequest/AccessRequestForm";

// TODO: Move to shared helper
const contactInfoFields = [
  "firstname",
  "surname",
  "email",
  "company",
  "phone",
  "street1",
  "countrycd",
  "zip",
  "city"
];

// TODO: Move to shared helper
const contactFields = [
  ...contactInfoFields.map(field => `c_${field}`),
  ...contactInfoFields.map(field => `bg_${field}`)
];

// TODO: Check that the list of included fields is correct
const included_fields = [
  // common
  "sample_types",

  // personal and background org contact details
  ...contactFields,

  // Biobanks section
  "included_biobanks",

  // Project details section
  "projektin_nimi",
  "selected_cohort_names",

  // materials section
  "selected_materials",
  "preselected_research_cohort",
  "material_inclusion_criteria",
  "material_exclusion_criteria",

  // Liquid samples section
  "other_cell_sample_type",
  "other_liquid_sample_type",
  "liquid_samples_additional_info",
  "sample_volume_description",
  "samples_from_each_donor",
  "requirements_for_critical_study",
  "liquid_sample_inclusion_criteria_description",
  "liquid_sample_exclusion_criteria_description",

  // Phenotype or lifestyle section
  "phenotype_or_lifestyle_desc",

  // Genotype section
  "genotype_desc",

  // Sample donor recruit section
  "recruitment_desc",

  // other - probably deprecated
  "sequence_data_desc",
  "files"
];

const changeMap = {
  c_street1: "c_address",
  bg_street1: "bg_address"
};

const AccessRequestFromFeasibilityRequestContainer = () => {
  const match = useMatch(`${accessRequestUrl}/new/:feasibilityRequestId`);
  const { feasibilityRequestId } = match.params;
  const { feasibilityRequest, loading, error } = useFetchFeasibilityRequest(
    feasibilityRequestId
  );

  const prefilledValues = {
    ...Object.assign(initialValues, pick(feasibilityRequest, included_fields)),
    preanalysisid: feasibilityRequestId,

    // project description section
    brief_project_desc: feasibilityRequest.goals_of_the_study,
    have_budget: feasibilityRequest.exist_budget,
    budget_until_year: feasibilityRequest.budget_timeline_year,
    budget: feasibilityRequest.budget_description_desc,

    // tissue samples
    tissue_sample_inclusion_criteria:
      feasibilityRequest.tissue_sample_inclusion_criteria_description,
    tissue_sample_exclusion_criteria:
      feasibilityRequest.tissue_sample_exclusion_criteria_description,
    tissue_sample_additional_info:
      feasibilityRequest.tissue_sample_additional_info_description,

    filesFromFeasibility: [
      ...feasibilityRequest.phenotype_lifestyle_files,
      ...feasibilityRequest.genotype_files,
      ...feasibilityRequest.recruitment_files
    ]
  };

  Object.keys(changeMap).forEach(change => {
    prefilledValues[changeMap[change]] = prefilledValues[change];
    delete prefilledValues[change];
  });

  // The file-object has only the filename property. We map the filenames from the attachments
  // of the feasibility request we are creating access request from.  These filenames are
  // rendered in the form's file list.
  prefilledValues.filesFromFeasibility = [
    ...prefilledValues.filesFromFeasibility,
    ...prefilledValues.files.map(f => f.filename)
  ];

  // We set the files to be empty array, since the files-property will be used in the form
  // for newly added files containing the actual file.
  prefilledValues.files = [];

  const isCurrentFormOutdated = () =>
    new Date(feasibilityRequest.createdate) <=
    new Date(config.requestFormsV1DeadlineDate);

  return isCurrentFormOutdated() ? (
    <DeprecatedAccessRequest
      isLoading={loading}
      initialValues={prefilledValues}
      errorPrefilling={error}
    />
  ) : (
    <AccessRequestForm
      isLoading={loading}
      initialValues={prefilledValues}
      errorPrefilling={error}
    />
  );
};

export default AccessRequestFromFeasibilityRequestContainer;
