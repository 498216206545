import React from "react";
import { useTranslation } from "react-i18next";

// TODO: remove &nbsp; and style using css
const Filter = ({ group, id, onSelectFilter, selected, enabled }) => {
  const { t } = useTranslation();
  return (
    <li className="filter">
      <label className="container-checkbox">
        <input
          type="checkbox"
          onChange={e => {
            onSelectFilter(group, id, e.target.checked);
          }}
          checked={selected === true}
          disabled={!enabled}
        />
        <span className="checkmark"></span>
        <span className="label">
          {t("filter_group_" + group + "_label_" + id)}
        </span>
      </label>
    </li>
  );
};

export default Filter;
