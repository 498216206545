import React, { useRef, useEffect } from "react";
import { ModalContainer } from "./CohortsInfoModal.styles";
import { useTranslation } from "react-i18next";

const typesList = types => {
  const listItems = types.map((type, i) => <li key={i}>{type}</li>);
  return <ul>{listItems}</ul>;
};

const ClickOutside = props => {
  const wrapperRef = useRef(null);
  useOutsideClose(wrapperRef, props.onClose);

  return <ModalContainer ref={wrapperRef}>{props.children}</ModalContainer>;
};

function useOutsideClose(ref, onClose) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);
}

const InfoModal = ({ title, onClose }) => {
  const { t } = useTranslation();
  const cohortTranslations = t("cohortsInfoModal", { returnObjects: true });

  return (
    <ClickOutside onClose={onClose}>
      <h2>{title}</h2>
      <p>{cohortTranslations.first_paragraph}</p>
      <p>{cohortTranslations.cohort_types_list_title}</p>
      {typesList(cohortTranslations.cohort_types)}
      <p>{cohortTranslations.cohorts_instructions}</p>
      <p>{cohortTranslations.cohorts_last_paragraph}</p>
      <button className={"button button-secondary"} onClick={onClose}>
        {t("close")}
      </button>
    </ClickOutside>
  );
};

export default InfoModal;
