export const filterSelectedMiabisEntities = miabisEntities => {
  return miabisEntities.filter(miabisEntity => miabisEntity.isSelected);
};

export const filterCohortCollectionsByDataCategories = (
  cohortCollections,
  dataCategories
) => {
  return cohortCollections.filter(cohortCollection => {
    return dataCategories.every(dataCategory => {
      return cohortCollection.data_categories.find(dataCategoryOfCohort => {
        return dataCategoryOfCohort.id === dataCategory.id;
      });
    });
  });
};

export const filterCohortCollectionsByCollectionTypes = (
  cohortCollections,
  collectionTypes
) => {
  return cohortCollections.filter(cohortCollection => {
    return collectionTypes.every(collectionType => {
      return cohortCollection.collection_types.find(collectionTypeOfCohort => {
        return collectionTypeOfCohort.id === collectionType.id;
      });
    });
  });
};

export const filterCohortCollectionsByMaterialTypes = (
  cohortCollections,
  materialTypes
) => {
  return cohortCollections.filter(cohortCollection => {
    return materialTypes.every(materialType => {
      return cohortCollection.material_types.find(materialTypeOfCohort => {
        return materialTypeOfCohort.id === materialType.id;
      });
    });
  });
};

export const filterCohortCollectionsByGenders = (
  cohortCollections,
  genders
) => {
  return cohortCollections.filter(cohortCollection => {
    return genders.every(gender => {
      /*
       * 2022-01-18
       * TODO: Improve on this implementation once the sex data type has been improved on the backend.
       * Currently, this is a really really really error prone and dumb parser, which should later on be more similar
       * to the filters above.
       */
      return cohortCollection.sex.split(",").find(genderOfCohort => {
        return (
          genderOfCohort.trim().toLocaleLowerCase() ===
          gender.name_en.toLocaleLowerCase()
        );
      });
    });
  });
};

export const filterCohortCollectionsBySearchValue = (
  cohortCollections,
  searchValue
) => {
  return cohortCollections.filter(cohortCollection => {
    const searchWords = searchValue
      .trim()
      .split(" ")
      .map(searchWord => searchWord.toLowerCase());

    const searchEntities = [
      cohortCollection.name.toLowerCase(),
      cohortCollection.description.toLowerCase()
    ];

    return searchWords.every(searchWord => {
      return searchEntities.find(searchEntity => {
        return searchEntity.includes(searchWord);
      });
    });
  });
};

export const getCohortAgeDistribution = cohortCollection => {
  const plural = "s";
  return !!cohortCollection.age_unit
    ? `${cohortCollection.age_low} - ${cohortCollection.age_high} ${
        cohortCollection.age_unit
      }${!cohortCollection.age_unit.endsWith(plural) ? plural : ""}`
    : `${cohortCollection.age_low} - ${cohortCollection.age_high}`;
};
