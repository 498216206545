import styled from "styled-components";

export const DividerContainer = styled.div`
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  position: relative;
  width: 100%;
`;

export const DividerBorderContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
`;

export const DividerBorder = styled.div`
  border-top: 1px solid ${({ theme }) => theme.values.colorPalette.grey[300]};
  width: 100%;
`;

export const DividerTextContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const DividerText = styled.div`
  background-color: #fff;
  color: ${({ theme }) => theme.values.colorPalette.grey[700]};
  padding: 0 1rem;
`;
