import config from "../config";

// We make assumption that the config is Finnish summer time => +03:00 to UTC.
const createDateString = (month, day, hours, minutes) =>
  `${new Date().getFullYear()}-${month?.padStart(2, "0")}-${day?.padStart(
    2,
    "0"
  )} ${hours?.padStart(2, "0")}:${minutes?.padStart(2, "0")}:00+03:00`;

const vacationStartDate = new Date(
  createDateString(
    config.vacationStartMonth,
    config.vacationStartDay,
    config.vacationStartHours,
    config.vacationStartMinutes
  )
);
const vacationEndDate = new Date(
  createDateString(
    config.vacationEndMonth,
    config.vacationEndDay,
    config.vacationEndHours,
    config.vacationEndMinutes
  )
);

const isVacationPeriod = date => {
  return (
    vacationStartDate.getTime() <= date.getTime() &&
    date.getTime() < vacationEndDate.getTime()
  );
};

export default isVacationPeriod;
