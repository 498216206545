import {
  FETCH_MIABIS_MATERIAL_TYPES,
  FETCH_MIABIS_MATERIAL_TYPES_FAILURE,
  FETCH_MIABIS_MATERIAL_TYPES_SUCCESS
} from "./miabis-material-types.actions";
import config from "../config";
import axios from "../helpers/axios";
import { logError } from "../helpers/logger";

export const requestMiabisMaterialTypes = function(target) {
  return {
    type: FETCH_MIABIS_MATERIAL_TYPES,
    target
  };
};

export const requestMiabisMaterialTypesSuccess = function(target, results) {
  return {
    type: FETCH_MIABIS_MATERIAL_TYPES_SUCCESS,
    target,
    results
  };
};

export const requestMiabisMaterialTypesFailure = function(target) {
  return {
    type: FETCH_MIABIS_MATERIAL_TYPES_FAILURE,
    target
  };
};

export const fetchMiabisMaterialTypes = function(target) {
  return async function(dispatch) {
    dispatch(requestMiabisMaterialTypes(target));

    const url = `${config.catalogApiUrl}/v1/miabis-material-types/`;

    try {
      const response = await axios.get(url);
      dispatch(
        requestMiabisMaterialTypesSuccess(
          target,
          response.data.miabisMaterialTypes
        )
      );
    } catch (error) {
      logError(error);
      dispatch(requestMiabisMaterialTypesFailure(target));
    }
  };
};
