import axios from "axios";

import {
  getPlatformToken,
  getRequestsToken,
  redirectToLogout
} from "./session";
import appConfig from "../config";
import i18next from "i18next";

const getToken = async url => {
  if (
    url.startsWith(appConfig.platformUrl) ||
    url.startsWith(appConfig.catalogApiUrl)
  ) {
    return await getPlatformToken();
  }
  if (url.startsWith(appConfig.requestsUrl)) {
    return await getRequestsToken();
  }

  return "";
};

axios.interceptors.request.use(
  async function(config) {
    const token = await getToken(config.url);
    if (token && token.length) {
      config.headers.Authorization = `Bearer ${token}`;
      if (i18next && i18next.language) {
        config.headers["Accept-Language"] = i18next.language;
      }
    }

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  res => res,
  error => {
    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      redirectToLogout();
      // TODO: Refactor vacationSeasonNotification.js into something more generic and show a "session expired" message here
      return;
    }
    return Promise.reject(error);
  }
);

export default axios;
