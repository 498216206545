import config from "../config";
import axios from "../helpers/axios";
import { logError } from "../helpers/logger";
import {
  FETCH_MIABIS_COLLECTION_TYPES,
  FETCH_MIABIS_COLLECTION_TYPES_FAILURE,
  FETCH_MIABIS_COLLECTION_TYPES_SUCCESS
} from "./miabis-collection-types.actions";

export const requestMiabisCollectionTypes = function(target) {
  return {
    type: FETCH_MIABIS_COLLECTION_TYPES,
    target
  };
};

export const requestMiabisCollectionTypesSuccess = function(target, results) {
  return {
    type: FETCH_MIABIS_COLLECTION_TYPES_SUCCESS,
    target,
    results
  };
};

export const requestMiabisCollectionTypesFailure = function(target) {
  return {
    type: FETCH_MIABIS_COLLECTION_TYPES_FAILURE,
    target
  };
};

export const fetchMiabisCollectionTypes = function(target) {
  return async function(dispatch) {
    dispatch(requestMiabisCollectionTypes(target));

    const url = `${config.catalogApiUrl}/v1/miabis-collection-types/`;

    try {
      const response = await axios.get(url);
      dispatch(
        requestMiabisCollectionTypesSuccess(
          target,
          response.data.miabisCollectionTypes
        )
      );
    } catch (error) {
      logError(error);
      dispatch(requestMiabisCollectionTypesFailure(target));
    }
  };
};
