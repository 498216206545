import Yup from "../../Yup";
import {
  getContactInfoFields,
  optionalText,
  requiredTextFn
} from "../../helpers/formValidators";
import {
  commonOptionalContactInfoFields,
  optionalAddress,
  projectName,
  businessId,
  maxProjectDescriptionLength,
  projectDescription
} from "../../helpers/formSchemaHelper";

const optionalContactInfoFields = {
  ...commonOptionalContactInfoFields,
  address: optionalAddress,
  businessid: businessId
};

export const AccessRequestSchema = Yup.object().shape({
  included_biobanks: Yup.array(),

  // Contact Info data is not to be validated because backend(requests-backend) fill the properties internally

  // Background organization
  ...getContactInfoFields(optionalContactInfoFields, "bg_"),

  projektin_nimi: projectName,
  brief_project_desc: requiredTextFn(maxProjectDescriptionLength),
  sample_types: Yup.array(),
  who_collects_data: Yup.array(),
  projecttype: Yup.array(),
  mandatory_documents: Yup.string()
    .min(1)
    .max(1)
    .required(),
  exact_desc_data: optionalText(5000),
  what_findings: optionalText(500),
  project_short_name: optionalText(300),
  starting_month: optionalText(),
  starting_year: optionalText(),
  ending_month: optionalText(),
  ending_year: optionalText(),
  project_desc: optionalText(3000),
  project_summary: optionalText(3000),
  sequence_data_desc: optionalText(2000),
  other_sample_type: optionalText(100),
  exact_desc: optionalText(5000),
  requirements: optionalText(1000),
  special_requirements: optionalText(500),
  who_collects_data_desc: optionalText(1000),
  est_data_return_month: optionalText(),
  est_data_return_year: optionalText(),
  what_results_returns: optionalText(500),
  invoicing_method: optionalText(),
  i_company: Yup.string(),
  i_address: Yup.string(),
  i_countrycd: Yup.string(),
  i_zip: Yup.string(),
  i_city: Yup.string(),
  i_businessid: Yup.string(),
  i_email: Yup.string(),
  i_operator: optionalText(100),
  i_broker: optionalText(100),
  i_reference: optionalText(200),
  i_invaddress: optionalText(100),
  scienceprofit: optionalText(3000),
  ethical_evaluation: optionalText(5000),
  research_arguments: optionalText(5000),
  healthbenefits: optionalText(3000),
  accidental_findings: optionalText(3000),
  risks: optionalText(2000),
  linkages: optionalText(1000),
  mainfunding: optionalText(1000),
  research_result_publish_desc: optionalText(1000),
  project_timeline_months: Yup.string(),
  have_budget: Yup.boolean(),
  budget_until_year: Yup.string(),
  budget: Yup.string(),
  material_inclusion_criteria: optionalText(2000),
  material_exclusion_criteria: optionalText(2000),
  other_cell_sample_type: optionalText(200),
  other_liquid_sample_type: optionalText(200),
  liquid_samples_additional_info: optionalText(500),
  sample_volume_description: optionalText(200),
  requirements_for_critical_study: optionalText(500),
  liquid_sample_inclusion_criteria_description: optionalText(1000),
  liquid_sample_exclusion_criteria_description: optionalText(1000),
  tissue_sample_inclusion_criteria_description: optionalText(1000),
  tissue_sample_additional_info_description: optionalText(1000),
  phenotype_or_lifestyle_desc: optionalText(1000),
  genotype_desc: optionalText(1000),
  recruitment_desc: optionalText(1000),
  researchers: optionalText(5000)
});

export const SaveAccessRequestSchema = Yup.object().shape({
  projektin_nimi: projectName,
  brief_project_desc: projectDescription
});
