import { getCohortsUrl } from "../helpers/linkGenerator";
import CohortCollectionListPage from "../pages/CohortCollectionListPage/CohortCollectionListPage";
import CohortCollectionPage from "../pages/CohortCollectionPage/CohortCollectionPage";
import CohortCollectionSearchPage from "../pages/CohortCollectionSearchPage/CohortCollectionSearchPage";

const cohortUrl = getCohortsUrl();

const routes = [
  {
    path: cohortUrl,
    private: false,
    exact: true,
    element: <CohortCollectionListPage />
  },
  {
    path: `${cohortUrl}/search`,
    private: false,
    exact: true,
    element: <CohortCollectionSearchPage />
  },
  {
    path: `${cohortUrl}/:target`,
    private: false,
    element: <CohortCollectionPage />,
    header: true
  }
];

export default routes;
