import { Formik, useField } from "formik";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { updateProfile } from "../../actions/users";
import { isRequiredField } from "../../helpers/formHelper";
import { hasServerError } from "../../helpers/serverErrorHelper";
import CustomFormControl from "../CustomFormControl";
import CustomFormLabel from "../CustomFormLabel";
import ErrorBox from "../ErrorBox";
import FieldErrorBlock from "../FieldErrorBlock";
import EditProfileSchema from "./EditProfileSchema";
import { fetchCollaborationOrganisations } from "../../actions/expert-profile-categories";

const schema = EditProfileSchema;

const EditProfileFormControl = props => (
  <CustomFormControl {...props} validationSchema={schema} />
);

const EditProfileSelect = ({ children, ...props }) => {
  const { name } = props;
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  return (
    <>
      <CustomFormLabel validationSchema={schema} fieldName={name}>
        {t(`editprofile_form_label_${name}`)}
      </CustomFormLabel>
      <Form.Control
        {...field}
        as="select"
        name={name}
        required={isRequiredField(schema, name)}
      >
        {children}
      </Form.Control>
      {touched && error && (
        <FieldErrorBlock>{t(error.key, error.values)}</FieldErrorBlock>
      )}
    </>
  );
};

const EditProfileForm = ({
  userId,
  loading,
  loadingError,
  initialValues,
  permissions
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { errorCode, success } = useSelector(
    state => state.forms.updateProfile
  );
  const collaborationOrganisations = useSelector(
    state => state.expertProfileCategories.collaborationOrganisations
  );

  const canReadCollaborationOrganisations =
    permissions.includes("read:all-collaboration-organisations") ||
    permissions.includes("read:all-organisations");

  useEffect(() => {
    if (canReadCollaborationOrganisations) {
      fetchCollaborationOrganisations()(dispatch);
    }
  }, [dispatch, canReadCollaborationOrganisations]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        lang: "",
        collaborationOrganisationId: "",
        ...initialValues
      }}
      validationSchema={schema}
      onSubmit={values => {
        dispatch(updateProfile(userId, values));
      }}
    >
      {({ handleSubmit, isSubmitting, handleChange, values }) => (
        <Form onSubmit={handleSubmit}>
          <Container>
            {hasServerError(errorCode) && (
              <ErrorBox msg={t(`editprofile_form_error_${errorCode}`)} />
            )}
            <Row>
              <Col lg={4}>
                <h2>{t("editprofile_form_heading_personal_info")}</h2>
                <p className={"section-description"}>
                  {t("editprofile_form_desc_personal_info")}
                </p>
              </Col>
              <Col lg={8}>
                <Form.Row>
                  <Form.Group as={Col} controlId="formFirstname">
                    <EditProfileFormControl
                      name={"firstName"}
                      label={t("editprofile_form_label_firstname")}
                    />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formSurname">
                    <EditProfileFormControl
                      name={"lastName"}
                      label={t("editprofile_form_label_surname")}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Group controlId="formPhone">
                  <EditProfileFormControl
                    type="tel"
                    name={"phone"}
                    label={t("editprofile_form_label_phone")}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <EditProfileFormControl
                    type="email"
                    name={"email"}
                    label={t("editprofile_form_label_email")}
                  />
                </Form.Group>
                <Form.Group controlId="formLanguage">
                  <EditProfileSelect
                    name={"lang"}
                    label={t("editprofile_form_label_lang")}
                  >
                    <option value="en">
                      {t("editprofile_form_language_option_en")}
                    </option>
                    <option value="fi">
                      {t("editprofile_form_language_option_fi")}
                    </option>
                  </EditProfileSelect>
                </Form.Group>
                {canReadCollaborationOrganisations && (
                  <Form.Group controlId="collaborationOrganisationId">
                    <EditProfileSelect
                      name="collaborationOrganisationId"
                      label={t(
                        "editprofile_form_label_collaboration_organisation"
                      )}
                      placeholder="Collaboration Network"
                    >
                      {collaborationOrganisations.map(org => (
                        <option key={org.id} value={org.id}>
                          {org.name}
                        </option>
                      ))}
                    </EditProfileSelect>
                  </Form.Group>
                )}
              </Col>
              <Container>
                <Row>
                  <Col lg={{ span: 8, offset: 4 }}>
                    <div className={"button-group"}>
                      {!success ? (
                        <button
                          disabled={isSubmitting}
                          className={"button"}
                          type="button"
                          onClick={handleSubmit}
                        >
                          {t("editprofile_form_submit")}
                        </button>
                      ) : (
                        <span>{t("editprofile_form_success_message")}</span>
                      )}
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

EditProfileForm.propTypes = {
  userId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object
};

export default EditProfileForm;
