import { REMOVE_FILE_FROM_FEASIBILITY_REQUEST } from "../actions/feasibility_request.actions";

export const initialState = {};

export const feasibilityRequestReducer = (
  state = initialState,
  { type, data, attachmentId }
) => {
  switch (type) {
    case REMOVE_FILE_FROM_FEASIBILITY_REQUEST:
      return {
        ...state,
        files:
          state.files &&
          state.files.filter(file => file.attachmentid !== attachmentId)
      };
    default:
      return state;
  }
};
