import React from "react";
import { useTranslation } from "react-i18next";
import flag from "../assets/img/avainlippu.svg";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={"footer-wrapper"}>
      <footer>
        <div className="finbb-container">
          <div className="footer-row">
            <div className="primary">
              <p className="footer-title">{t("footer_title")}</p>
              <p className="footer-desc">{t("footer_desc")}</p>
            </div>
            <div className="secondary">
              <p>
                <img
                  className="avainlippu"
                  src={flag}
                  alt={t("made_in_finland")}
                />
              </p>
            </div>
          </div>
          <div className="footer-row">
            <div className="primary"></div>
            <div className="secondary">
              <p dangerouslySetInnerHTML={{ __html: t("footer_support") }}></p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
