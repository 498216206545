import {
  FETCH_SAMPLE_SUCCESS,
  TOGGLE_SAMPLE_FILTER
} from "../actions/samples.actions";
import { UNLOAD_VIEW_PAGE } from "../actions/pages.actions";

import { cloneDeep } from "lodash";

export const initialState = [];

export const currentSample = (state = null, action) => {
  switch (action.type) {
    case FETCH_SAMPLE_SUCCESS:
      return action.sample;
    case UNLOAD_VIEW_PAGE:
      return null;
    default:
      return state;
  }
};

// TODO: be a bit more clever with es6 array/object functions here
export const selectedFilters = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SAMPLE_FILTER:
      const copiedState = cloneDeep(state);

      if (action.selected) {
        if (!filterFieldAlreadyExists(copiedState, action.group)) {
          copiedState.push({
            field: action.group,
            values: [action.field]
          });
          return copiedState;
        } else {
          return addFilterIfNotExists(copiedState, action.group, action.field);
        }
      } else {
        const newState = removeFilter(copiedState, action.group, action.field);
        return cleanupFilterObject(newState);
      }
    default:
      return state;
  }
};

const addFilterIfNotExists = (filters, group, field) => {
  const filterKey = filters.findIndex(filter => filter.field === group);
  if (filterKey >= 0 && !filters[filterKey].values.includes(field)) {
    filters[filterKey].values.push(field);
  }
  return filters;
};

const removeFilter = (filters, group, field) => {
  const filterKey = filters.findIndex(filter => filter.field === group);
  if (filterKey >= 0) {
    filters[filterKey].values = filters[filterKey].values.filter(
      v => v !== field
    );
  }
  return filters;
};

const filterFieldAlreadyExists = (filters, field) =>
  filters.some(filter => filter.field === field);

const cleanupFilterObject = filterObject => {
  return filterObject.filter(
    filter => filter.values && filter.values.length > 0
  );
};
