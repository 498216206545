import styled from "styled-components";

export const StyledFilterListItem = styled.li`
  ${({ theme }) => theme.objects.ListItem.Base}
  ${({ theme }) => theme.objects.Copy.Scale.Small}
`;

export const StyledFilterList = styled.ul`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", { gap: theme.values.spacing.XSMALL })}
  ${({ theme }) => theme.objects.List.Base}
  ${({ theme }) => theme.objects.List.Unobtrusive}
`;

export const StyledFilterName = styled.div`
  ${({ theme }) => theme.objects.Heading.Style.Primary.Base}
  ${({ theme }) => theme.objects.Heading.Scale.Small}
  font-weight: ${({ theme }) => theme.values.fontWeights.BOLD};
`;

export const StyledFilterColumn = styled.div`
  ${({ theme }) =>
    theme.mixins.Container("COLUMN", { gap: theme.values.spacing.XSMALL })}
`;
