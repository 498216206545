import { memo } from "react";
import {
  StyledLabel,
  StyledLabeledValue,
  StyledValue
} from "./LabeledValue.styles";

const LabeledValue = ({ label, value }) => (
  <StyledLabeledValue>
    <StyledLabel>{label}</StyledLabel>
    <StyledValue>{value || "–"}</StyledValue>
  </StyledLabeledValue>
);

export default memo(LabeledValue);
