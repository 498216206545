import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";

export const BenefitRiskEvalSection = ({
  formProps,
  formControl: FormControl,
  label: Label,
  radio: Radio
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Label
        withErrorIndicator
        formProps={formProps}
        fieldName={"label_benefit_risk_evaluation"}
      >
        <h5>{t("arForm_benefit_risk")}</h5>
      </Label>
      <Form.Group id="formExpectedHealthBenefit">
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"hashealthbenefits"}
        >
          {t("arForm_hashealthbenefits_label")}
        </Label>
        <Radio
          label={t("arForm_hashealthbenefits_no")}
          name={"hashealthbenefits"}
          value={"F"}
        />
        <Radio
          label={t("arForm_hashealthbenefits_yes")}
          name={"hashealthbenefits"}
          value={"T"}
        />
      </Form.Group>
      <Form.Group controlId="formHealthBenefits">
        <FormControl
          as={"textarea"}
          name={"healthbenefits"}
          label={t("arForm_healthbenefits_label")}
          maxLength={2500}
        />
      </Form.Group>
      <Form.Group id="formHasRisks">
        <Label withErrorIndicator formProps={formProps} fieldName={"hasrisks"}>
          {t("arForm_hasrisks_label")}
        </Label>
        <Radio label={t("arForm_hasrisks_no")} name={"hasrisks"} value={"F"} />
        <Radio label={t("arForm_hasrisks_yes")} name={"hasrisks"} value={"T"} />
      </Form.Group>
      <Form.Group controlId="formRisks">
        <FormControl
          as={"textarea"}
          name={"risks"}
          label={t("arForm_risks_label")}
          maxLength={2500}
        />
      </Form.Group>
    </>
  );
};
