import { useTranslation } from "react-i18next";
import OptionPlaceholder from "./OptionPlaceholder";
import { Field } from "formik";

export const SelectField = ({ name, options }) => {
  const { t } = useTranslation();
  const selectOptions = options.map(value => (
    <option value={value} key={value}>
      {value}
    </option>
  ));
  selectOptions.push(
    <OptionPlaceholder key={0}>
      {t("default_dropdown_method_choose")}
    </OptionPlaceholder>
  );
  return (
    <Field name={name} as="select">
      {selectOptions}
    </Field>
  );
};
