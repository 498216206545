import { isRequiredField } from "../helpers/formHelper";
import Form from "react-bootstrap/Form";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import FormFieldError from "./FormFieldError";

const CustomFormLabel = ({
  children,
  validationSchema,
  formProps,
  fieldName,
  withErrorIndicator
}) => {
  const shouldHaveAsterisk = useMemo(
    () => isRequiredField(validationSchema, fieldName),
    [validationSchema, fieldName]
  );

  return (
    <>
      <Form.Label>
        {children} {shouldHaveAsterisk && "*"}
      </Form.Label>
      {withErrorIndicator && (
        <FormFieldError fieldName={fieldName} formProps={formProps} />
      )}
    </>
  );
};

CustomFormLabel.propTypes = {
  children: PropTypes.node.isRequired,
  validationSchema: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired
};

export default CustomFormLabel;
