import config from "../config";

const feasibilityRequestUrl = "/feasibility-request";
const accessRequestUrl = "/access-request";
const requestsUrl = "/requests";

export const getUrlForNewFeasibilityRequest = () =>
  `${feasibilityRequestUrl}/new`;

export const getFeasibilityRequestEditUrl = feasibilityRequestId =>
  `${feasibilityRequestUrl}/edit/${feasibilityRequestId}`;

export const getFeasibilityRequestStatusUrl = feasibilityRequestId =>
  `${feasibilityRequestUrl}/status/${feasibilityRequestId}`;

export const getUrlForNewAccessRequest = () => `${accessRequestUrl}/new`;

export const getUrlForNewAccessRequestFromFeasibility = feasibilityRequestId =>
  `${accessRequestUrl}/new/${feasibilityRequestId}`;

export const getAccessRequestEditUrl = accessRequestId =>
  `${accessRequestUrl}/edit/${accessRequestId}`;

export const getAccessRequestStatusUrl = accessRequestId =>
  `${accessRequestUrl}/status/${accessRequestId}`;

export const getActiveRequestsUrl = () => requestsUrl;

export const getArchivedRequestsUrl = () => `${requestsUrl}/archived`;

export const getRequestListUrl = archived =>
  archived ? getArchivedRequestsUrl() : getActiveRequestsUrl();

export const getCatalogUrl = () => "/catalog";

export const getCohortsUrl = () => "/cohorts";

export const getExpertsUrl = () => "/experts";

export const getStaticUrl = () => "/static";

export const getProtocol = () =>
  config.appEnv === "dev" ? "http://" : "https://";
