import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import initialValues from "../components/AccessRequest/accessRequestState";
import useUser from "../hooks/useUser";
import {
  mapUserInfoToFormFields,
  preSelectedBiobanks
} from "../helpers/requestHelper";
import { AccessRequestForm } from "../components/AccessRequest/AccessRequestForm";

const AccessRequestContainer = () => {
  const { user } = useAuth0();
  const { data: userInfo, loading, error } = useUser(user.sub);

  const prefilledValues = {
    ...initialValues,
    included_biobanks: preSelectedBiobanks,
    ...mapUserInfoToFormFields(userInfo)
  };

  return (
    <AccessRequestForm
      isLoading={loading}
      errorPrefilling={error}
      initialValues={prefilledValues}
    />
  );
};
export default AccessRequestContainer;
