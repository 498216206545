import requestStates from "../common/requestStates";

export const mapUserInfoToFormFields = (userInfo, prefix = "c_") => {
  if (!userInfo) {
    return {};
  }
  return {
    [`${prefix}firstname`]: userInfo.firstName,
    [`${prefix}surname`]: userInfo.lastName,
    [`${prefix}email`]: userInfo.email,
    [`${prefix}company`]: userInfo.organisation?.name,
    [`${prefix}phone`]: userInfo.phone,
    [`${prefix}street1`]: userInfo.organisation?.address,
    [`${prefix}address`]: userInfo.organisation?.address,
    [`${prefix}countrycd`]: userInfo.organisation?.countryCode,
    [`${prefix}zip`]: userInfo.organisation?.zip,
    [`${prefix}city`]: userInfo.organisation?.city,
    [`${prefix}org_type`]: userInfo.organisation?.organisationType
  };
};

export const preSelectedBiobanks = [
  "10",
  "20",
  "30",
  "40",
  "50",
  "60",
  "80",
  "90"
];

export const isArchived = request => request.deleted === "T";

export const inverseArchived = request => (isArchived(request) ? "F" : "T");

export const isNewFeasibilityRequest = data =>
  data && data.state === requestStates.saved;

export const isNewAccessRequest = data =>
  data && data.state === requestStates.saved;

export const isFeasibilityRequestReturnedForCompletion = data =>
  data && data.state === requestStates.refine;

export const isAccessRequestReturnedForCompletion = data =>
  data && data.state === requestStates.refine;
