import React from "react";
import isVacationPeriod from "../../helpers/isVacationPeriod";
import i18n from "../../i18n";
import styles from "./vacation-notification.module.scss";

const VacationNotification = () => {
  if (isVacationPeriod(new Date())) {
    return (
      <div className={styles.vacationContainer}>
        <h2>{i18n.t("holiday_season_title")}</h2>
        <p>{i18n.t("holiday_season_message")}</p>
        <p>
          <span>{i18n.t("holiday_season_signature1")}</span>
          <br />
          <span>{i18n.t("holiday_season_signature2")}</span>
        </p>
      </div>
    );
  }

  return null;
};

export default VacationNotification;
