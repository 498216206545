import Container from "react-bootstrap/Container";
import logo from "../assets/img/logo_blue.svg";
import Alert from "react-bootstrap/Alert";
import React from "react";
import { useTranslation } from "react-i18next";

const IEFallback = () => {
  const { t } = useTranslation();
  return (
    <Container className={"top-margin text-center"}>
      <img src={logo} alt={"Fingenious"} />
      <Alert variant={"warning"}>
        <span
          dangerouslySetInnerHTML={{ __html: t("ie_not_supported") }}
        ></span>
      </Alert>
    </Container>
  );
};

export default IEFallback;
