import * as Yup from "yup";
import {
  email,
  firstName,
  langId,
  phone,
  surName
} from "../../helpers/formSchemaHelper";

const EditProfileSchema = Yup.object().shape({
  phone: phone,
  firstName: firstName,
  lastName: surName,
  email: email,
  lang: langId
});

export default EditProfileSchema;
