import React from "react";
import { Navigate, Outlet } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

import config from "../config";

const PrivateRoute = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  // TODO: Show a loader instead of nothing while auth0 is loading
  if (isLoading) {
    return null;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={config.loginUrl} />;
};

export default PrivateRoute;
