import {
  FETCH_EXPERT_PROFILES,
  FETCH_EXPERT_PROFILES_FAILURE,
  FETCH_EXPERT_PROFILES_SUCCESS
} from "./expert-profiles.actions";
import axios from "../helpers/axios";
import config from "../config";
import { logError } from "../helpers/logger";

/**
 *
 * @param query {{
 *     query?: string;
 *     therapyAreas?: string[];
 *     researchExpertises?: string[];
 *     roles?: string[];
 *     researchPhases?: string[];
 *     icd10Codes?: string[];
 * } | undefined}
 */
export const searchExpertProfiles = query => {
  return async function(dispatch) {
    dispatch({
      type: FETCH_EXPERT_PROFILES
    });

    if (!query || Object.keys(query).length === 0) {
      dispatch({
        type: FETCH_EXPERT_PROFILES_SUCCESS,
        results: []
      });
      return;
    }

    const url = `${config.catalogApiUrl}/v1/expert-profiles/`;

    try {
      const response = await axios.get(url, {
        params: query
      });
      const json = await response.data.results;

      dispatch({
        type: FETCH_EXPERT_PROFILES_SUCCESS,
        results: json
      });
    } catch (error) {
      logError(error);
      dispatch({
        type: FETCH_EXPERT_PROFILES_FAILURE
      });
    }
  };
};
