import React from "react";
import { Navigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

import { getCatalogUrl } from "../helpers/linkGenerator";

const toCatalogIfLoggedIn = WrappedComponent => {
  return props => {
    const { isAuthenticated } = useAuth0();
    if (isAuthenticated) {
      return <Navigate to={getCatalogUrl()} />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default toCatalogIfLoggedIn;
