import React from "react";
import { useTranslation } from "react-i18next";
import InfoModalButton from "./InfoModalButton";
import CartItemDeleteButtonContainer from "./CartItemDeleteButtonContainer";
import { renderQuantity } from "../helpers/utils";
import { CATEGORY_CATALOG } from "../actions/category-types";

const CartItem = ({ item, itemIndex }) => {
  const { t } = useTranslation();

  const filterFlatten = ["gender"];

  const renderFilter = (filter, flatten = false) => {
    return flatten
      ? t("data_filter_value_" + filter.values[0])
      : t("data_filter_value_" + filter.field);
  };

  const appliedFilters = item.filters.map(filter =>
    renderFilter(filter, filterFlatten.includes(filter.field))
  );

  const { quantity } = item;
  const category = item.category ?? CATEGORY_CATALOG;

  const CohortCartItem = () => (
    <div className="cart-item">
      <div className="finbb-container">
        <div className="info">
          <div className="title-specimen">
            <h3>{"Cohort: " + item.codeInfo.title}</h3>
          </div>
        </div>
        <div className="extra">
          <ul className="filters">
            {appliedFilters.map(filter => (
              <li key={filter}>{filter}</li>
            ))}
          </ul>
          <CartItemDeleteButtonContainer item={item} itemIndex={itemIndex} />
        </div>
      </div>
    </div>
  );

  const CatalogCartItem = () => (
    <div className="cart-item">
      <div className="finbb-container">
        <div className="info">
          <div className="title-specimen">
            <h3>{`${item.codeInfo.code} ${item.codeInfo.title}`}</h3>
            {category === CATEGORY_CATALOG && (
              <p className="specimen-type">
                {t("data_title_" + item.specimenType)}
              </p>
            )}
          </div>
          <div className="total">
            <div className="amount">{renderQuantity(quantity)}</div>
            <div className="label">
              {t("samples_from_unique_donors", { total: quantity })}
              <InfoModalButton text={t("sample_view_desc")} />
            </div>
          </div>
        </div>
        <div className="extra">
          <ul className="filters">
            {appliedFilters.map(filter => (
              <li key={filter}>{filter}</li>
            ))}
          </ul>
          <CartItemDeleteButtonContainer item={item} itemIndex={itemIndex} />
        </div>
      </div>
    </div>
  );

  return category === CATEGORY_CATALOG ? (
    <CatalogCartItem />
  ) : (
    <CohortCartItem />
  );
};

export default CartItem;
