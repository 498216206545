import { LOAD_VIEW_PAGE, UNLOAD_VIEW_PAGE } from "./pages.actions";

export const loadViewPage = function() {
  return {
    type: LOAD_VIEW_PAGE
  };
};

export const unloadViewPage = function() {
  return {
    type: UNLOAD_VIEW_PAGE
  };
};
