import { RequestSection } from "../../RequestSection";
import { useTranslation } from "react-i18next";
import { Form, Col } from "react-bootstrap";

export const BackgroundOrganizationSection = ({
  feasibilityRequestFormControl: FeasibilityRequestFormControl
}) => {
  const { t } = useTranslation();

  return (
    <RequestSection
      title={t("background_organization")}
      description={t("background_organization_description")}
    >
      {/* TODO: Reuse contact person fields for background organization */}
      <Form.Row>
        <Form.Group as={Col} controlId="formBgFirstname">
          <FeasibilityRequestFormControl
            name={"bg_firstname"}
            label={t("firstname")}
            maxLength={40}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formBgLastname">
          <FeasibilityRequestFormControl
            name={"bg_surname"}
            label={t("lastname")}
            maxLength={50}
          />
        </Form.Group>
      </Form.Row>
      <Form.Group controlId="formBgEmail">
        <FeasibilityRequestFormControl
          type="email"
          name={"bg_email"}
          label={t("email")}
          maxLength={100}
        />
      </Form.Group>
      <Form.Group controlId="formBgOrganization">
        <FeasibilityRequestFormControl
          name={"bg_company"}
          label={t("organization")}
          maxLength={100}
        />
      </Form.Group>
    </RequestSection>
  );
};
