import { Header, themes } from "@finbb/ui-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { LoginContainer } from "./LoginPage.styles";
import Login from "../../components/Login/Login";
import MainContainer from "../../components/MainContainer";

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={themes.light}>
      <div className="App page-user" style={{ marginTop: "-2rem" }}>
        <Header isAuthenticated={false} />
        <MainContainer>
          <LoginContainer>
            <p>{t("login_form_title")}</p>
            <Login />
          </LoginContainer>
        </MainContainer>
      </div>
    </ThemeProvider>
  );
};

export default LoginPage;
