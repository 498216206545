import {
  FETCH_EXPERT_PROFILES,
  FETCH_EXPERT_PROFILES_FAILURE,
  FETCH_EXPERT_PROFILES_SUCCESS
} from "../actions/expert-profiles.actions";

export const defaultState = {
  loading: false,
  error: false,
  expertProfiles: []
};

export const populateExpertProfiles = (state = defaultState, action) => {
  switch (action.type) {
    case FETCH_EXPERT_PROFILES:
      return {
        ...state,
        loading: true,
        error: false
      };
    case FETCH_EXPERT_PROFILES_SUCCESS:
      return {
        ...state,
        expertProfiles: action.results,
        loading: false
      };
    case FETCH_EXPERT_PROFILES_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};
