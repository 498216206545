import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { requestPasswordReset } from "../actions/users";
import EditProfileForm from "../components/EditProfile/EditProfileForm";
import ExpertProfileForm from "../components/EditProfile/ExpertProfileForm";
import HorizontalLine from "../components/HorizontalLine/HorizontalLine";
import LoadingForm from "../components/LoadingForm";
import LoadingWrapper from "../components/LoadingWrapper";
import Title from "../components/Title/Title";
import { useAuth0UserWithPermissions } from "../hooks/useAuth0UserWithPermissions";
import { useExpertProfile } from "../hooks/useExpertProfile";
import useUser from "../hooks/useUser";

const mapDataToProfileForm = data => {
  return (
    data && {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      lang: data.lang,
      phone: data.phone,
      collaborationOrganisationId: data.collaborationOrganisationId
    }
  );
};

const mapDataToExpertProfileForm = data => ({
  keywords: data?.keywords?.map(keyword => keyword.value) ?? [],
  therapyAreas: data?.therapyAreas?.map(therapyArea => therapyArea.id) ?? [],
  researchExpertises:
    data?.researchExpertises?.map(researchExpertise => researchExpertise.id) ??
    [],
  roles: data?.roles?.map(role => role.id) ?? [],
  icd10Codes: data?.icd10Codes?.map(icd10Code => icd10Code.id) ?? [],
  researchPhases:
    data?.researchPhases?.map(researchPhase => researchPhase.id) ?? [],
  rareNeurologicalDiseases:
    data?.rareNeurologicalDiseases?.map(
      rareNeurologicalDisease => rareNeurologicalDisease.id
    ) ?? []
});

const EditProfilePage = () => {
  const { t } = useTranslation();
  const { user, permissions } = useAuth0UserWithPermissions();
  const { data, loading: userLoading, error } = useUser(user.sub);
  const {
    data: expertProfileData,
    loading: expertProfileLoading
  } = useExpertProfile(data?.expertProfileId);
  const loading = userLoading || expertProfileLoading;
  const canShowExpertProfile = [
    "create:own-expert-profile",
    "update:own-expert-profile"
  ].every(permission => (permissions ?? []).includes(permission));
  const dispatch = useDispatch();

  const requestPasswordResetFromApi = async () => {
    // TODO: Change this to a custom modal component once we have one available in @finbb/ui-components
    // eslint-disable-next-line no-alert
    const isConfirmed = window.confirm(
      t("editprofile_form_password_confirm_change")
    );

    if (isConfirmed) {
      dispatch(requestPasswordReset(data.auth0Id));
      // TODO: Notify the user of a successfully sent password change request
    }
  };

  return (
    <div className="App page-user-editprofile">
      <div>
        <Title>{t("editprofile_form_title")}</Title>
        <HorizontalLine className={"reset-top-margin"} />
        <LoadingWrapper
          isLoading={loading}
          isInErrorState={error}
          errorMsg={t("editprofile_form_error_loading")}
          loadingComponent={<LoadingForm />}
        >
          <EditProfileForm
            loading={loading}
            loadingError={error}
            permissions={permissions}
            userId={data && data.auth0Id}
            initialValues={mapDataToProfileForm(data)}
          />

          {canShowExpertProfile && (
            <Container>
              <HorizontalLine className={"double-vertical-margin"} />

              <ExpertProfileForm
                loading={loading}
                loadingError={error}
                userData={data}
                expertProfileId={data && data.expertProfileId}
                initialValues={mapDataToExpertProfileForm(expertProfileData)}
              />
            </Container>
          )}

          <Container>
            <HorizontalLine className={"double-vertical-margin"} />
            <Row>
              <Col lg={4}>
                <h2>{t("register_form_label_password")}</h2>
              </Col>
              <Col lg={8}>
                <Form.Row>
                  <Form.Group as={Col}>
                    <button
                      onClick={requestPasswordResetFromApi}
                      className="button"
                    >
                      {t("editprofile_form_label_changepassword")}
                    </button>
                  </Form.Group>
                </Form.Row>
              </Col>
            </Row>
          </Container>
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default EditProfilePage;
