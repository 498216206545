import config from "../config";
import {
  FETCH_COLLABORATION_ORGANISATIONS,
  FETCH_COLLABORATION_ORGANISATIONS_FAILURE,
  FETCH_COLLABORATION_ORGANISATIONS_SUCCESS,
  FETCH_EXPERT_PROFILE_CATEGORIES,
  FETCH_EXPERT_PROFILE_CATEGORIES_FAILURE,
  FETCH_EXPERT_PROFILE_CATEGORIES_SUCCESS
} from "./expert-profile-category.actions";
import { logError } from "../helpers/logger";
import axios from "../helpers/axios";

export const fetchExpertProfileCategories = function(target) {
  return async function(dispatch) {
    dispatch({
      type: FETCH_EXPERT_PROFILE_CATEGORIES,
      target
    });

    const url = `${config.catalogApiUrl}/v1/expert-profile-categories`;

    try {
      const response = await axios.get(url);
      const json = await response.data;

      dispatch({
        type: FETCH_EXPERT_PROFILE_CATEGORIES_SUCCESS,
        target,
        results: json
      });
    } catch (error) {
      logError(error);
      dispatch({
        type: FETCH_EXPERT_PROFILE_CATEGORIES_FAILURE,
        target
      });
    }
  };
};

export const fetchCollaborationOrganisations = function(target) {
  return async function(dispatch) {
    dispatch({
      type: FETCH_COLLABORATION_ORGANISATIONS
    });

    const url = `${config.platformUrl}/api/collaboration-organisation`;

    try {
      const response = await axios.get(url);
      const json = await response.data;

      dispatch({
        type: FETCH_COLLABORATION_ORGANISATIONS_SUCCESS,
        results: json
      });
    } catch (error) {
      logError(error);
      dispatch({
        type: FETCH_COLLABORATION_ORGANISATIONS_FAILURE
      });
    }
  };
};
