import { RequestSection } from "../../RequestSection";
import OptionPlaceholder from "../../OptionPlaceholder";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { FeasibilityRequestPricing } from "../FeasibilityRequestPricing";
import { Field } from "formik";
import { SelectYearField } from "../../SelectYearField";

export const ProjectDetailsSection = ({
  formProps,
  feasibilityRequestLabel: FeasibilityRequestLabel,
  maxProjectDescriptionLength,
  feasibilityRequestSwitch: FeasibilityRequestSwitch,
  feasibilityRequestFormControl: FeasibilityRequestFormControl
}) => {
  const { t } = useTranslation();
  const {
    values: { budget_timeline_year: budgetTimeLineYear, c_org_type: orgType }
  } = formProps;

  return (
    <RequestSection
      title={t("project_details")}
      description={t("project_details_description")}
    >
      <Form.Group controlId="formProjectName">
        <FeasibilityRequestFormControl
          name={"projektin_nimi"}
          label={t("full_name_of_project")}
        />
      </Form.Group>
      <Form.Group controlId="formGoalsOfTheStudy">
        <FeasibilityRequestFormControl
          as={"textarea"}
          name={"goals_of_the_study"}
          label={t("project_description", {
            maxProjectDescriptionLength
          })}
        />
      </Form.Group>
      <Form.Group>
        <FeasibilityRequestLabel
          withErrorIndicator
          formProps={formProps}
          fieldName={"timeline_target"}
        >
          <h5>{t("timeline_and_budget_title")}</h5>
          {t("feasibility_time_constrain_label")}
        </FeasibilityRequestLabel>
        <Form.Group>
          <Field name="budget_timeline_week" as="select">
            <OptionPlaceholder>
              {t("default_dropdown_method_choose")}
            </OptionPlaceholder>
            <option value={2} key={2}>
              {t("dropdown_weeks_options", { weekNumber: 2 })}
            </option>
            <option value={3} key={3}>
              {t("dropdown_weeks_options", { weekNumber: "3 - 4" })}
            </option>
            <option value={4} key={4}>
              {t("dropdown_weeks_options", { weekNumber: "4 - 5" })}
            </option>
            <option value={6} key={6}>
              {t("dropdown_above_weeks_options", { weekNumber: "6" })}
            </option>
          </Field>
        </Form.Group>
      </Form.Group>
      <Form.Group>
        <FeasibilityRequestLabel
          withErrorIndicator
          formProps={formProps}
          fieldName={"budget_target"}
        >
          <h5>{t("budget_target_title")}</h5>
          {t("budget_target_label")}
        </FeasibilityRequestLabel>
        <FeasibilityRequestSwitch
          label={t("exist_budget")}
          name={"exist_budget"}
          value={"exist_budget"}
        />
      </Form.Group>
      <Form.Group>
        <FeasibilityRequestLabel
          withErrorIndicator
          formProps={formProps}
          fieldName={"budget_timeline"}
        >
          {t("budget_timeline_label")}
        </FeasibilityRequestLabel>
        <Form.Group>
          <SelectYearField
            fieldName="budget_timeline_year"
            selectedYear={budgetTimeLineYear}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group>
        <FeasibilityRequestLabel
          withErrorIndicator
          formProps={formProps}
          fieldName={"budget_description"}
        >
          <h5>{t("budget_description_title")}</h5>
        </FeasibilityRequestLabel>
        <FeasibilityRequestFormControl
          as={"textarea"}
          label={t("budget_description_label")}
          name={"budget_description_desc"}
        />
      </Form.Group>
      <Form.Group>
        <FeasibilityRequestLabel
          formProps={formProps}
          fieldName={"feasibility_request_pricing_info"}
        >
          <h5>{t("feasibility_request_pricing_info_label")}</h5>
          <FeasibilityRequestPricing requestType={orgType} />
        </FeasibilityRequestLabel>
      </Form.Group>
    </RequestSection>
  );
};
