import styled from "styled-components";

export const StyledValue = styled.div`
  ${({ theme }) => theme.objects.Copy.Style.Default.Base}
`;

export const StyledLabel = styled.div`
  ${({ theme }) => theme.objects.Copy.Style.Default.Subtle}
  ${({ theme }) => theme.objects.Copy.Scale.Small}
  text-transform: uppercase;
  color: ${({ theme }) => theme.values.colorPalette.greyCold[100]};
  font-weight: bold;
`;

export const StyledLabeledValue = styled.div`
  ${({ theme }) => theme.mixins.Container("COLUMN", { gap: 0 })}
  line-height: 150%;
`;
