import { LoadingIndicator } from "@finbb/ui-components";
import { IconChevronRight } from "@tabler/icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";

import config from "../../config";
import { fetchCohortCollection } from "../../actions/cohort-collections";
import LabeledValue from "../../components/LabeledValue/LabeledValue";
import { translateMiabisEntityName } from "../../helpers/field-translate-util";
import { getCohortsUrl } from "../../helpers/linkGenerator";
import { getCohortAgeDistribution } from "../../helpers/cohort-collection-util";
import {
  StyledAside,
  StyledBoldCopy,
  StyledBreadcrumbs,
  StyledCohortCollectionPage,
  StyledCohortInfo,
  StyledCollectionName,
  StyledColumn,
  StyledDataList,
  StyledDataListItem,
  StyledHeadRole,
  StyledLinkSmall,
  StyledImage,
  StyledMain,
  StyledSectionHeading,
  StyledTwoColumns
} from "./CohortCollectionPage.styles";
import CartSampleButtonContainer from "../../components/CartSampleButtonContainer";
import { CATEGORY_COHORT } from "../../actions/category-types";

const CohortCollectionPage = ({
  fetchCohortCollection,
  cohortCollectionState
}) => {
  const theme = useTheme();
  const params = useParams();
  const {
    t,
    i18n: { language }
  } = useTranslation();

  useEffect(() => {
    const fixedUrlId = params.target.replace(":id:", ":ID:");
    fetchCohortCollection(fixedUrlId);
  }, [fetchCohortCollection, params]);

  if (cohortCollectionState.error) {
    return <p>{t("cohort_collection_view.error")}</p>;
  }

  if (
    cohortCollectionState.loading ||
    !cohortCollectionState.selectedCohortCollection
  ) {
    return <LoadingIndicator />;
  }

  const cohortCollection = cohortCollectionState.selectedCohortCollection;

  const formattedDate = new Date(cohortCollection.timestamp)
    .toISOString()
    .split("T")[0];

  const renderMiabisDataList = dataList => {
    if (dataList.length === 0) {
      return null;
    }

    return (
      <StyledDataList>
        {dataList.map(dataListItem => (
          <StyledDataListItem key={dataListItem.id}>
            {translateMiabisEntityName(dataListItem, language)}
          </StyledDataListItem>
        ))}
      </StyledDataList>
    );
  };

  return (
    <StyledCohortCollectionPage>
      <StyledAside>
        <StyledImage backgroundSrc={cohortCollection.image_url} />
        <StyledCohortInfo>
          <LabeledValue
            label={t("cohort_collection_view.biobank")}
            value={
              cohortCollection.biobank ? (
                <StyledBoldCopy>
                  {cohortCollection.url_biobank ? (
                    <StyledLinkSmall>
                      <a
                        href={cohortCollection.url_biobank}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {translateMiabisEntityName(
                          cohortCollection.biobank,
                          language
                        )}
                      </a>
                    </StyledLinkSmall>
                  ) : (
                    <StyledBoldCopy>
                      {translateMiabisEntityName(
                        cohortCollection.biobank,
                        language
                      )}
                    </StyledBoldCopy>
                  )}
                </StyledBoldCopy>
              ) : null
            }
          />
          <LabeledValue
            label={t("cohort_collection_view.person_in_charge")}
            value={
              <StyledBoldCopy>
                <div>
                  {cohortCollection.head_firstname}{" "}
                  {cohortCollection.head_lastname}
                </div>
                <StyledHeadRole>{cohortCollection.head_role}</StyledHeadRole>
              </StyledBoldCopy>
            }
          />
          <LabeledValue
            label={t("cohort_collection_view.links_title")}
            value={
              <>
                {cohortCollection.url_collection && (
                  <StyledLinkSmall>
                    <a
                      href={cohortCollection.url_collection}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("cohort_collection_view.link_to_cohort_text")}
                    </a>
                  </StyledLinkSmall>
                )}
                <StyledLinkSmall>
                  {t("cohort_collection_view.contact_us")}
                  <a
                    href={`mailto:${config.contact_us_email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {config.contact_us_email}
                  </a>
                </StyledLinkSmall>
              </>
            }
          />
          <CartSampleButtonContainer
            category={CATEGORY_COHORT}
            codeInfo={{
              code: "",
              id: cohortCollection.id,
              title: cohortCollection.name
            }}
            specimenType={"x"}
            sample={""}
            quantity={0}
          />
        </StyledCohortInfo>
      </StyledAside>
      <StyledMain>
        <StyledColumn>
          <StyledBreadcrumbs>
            <Link to={getCohortsUrl()}>
              {t("cohort_collection_view.all_cohorts")}
            </Link>
            <IconChevronRight
              color={theme.values.colors.text.default.SUBTLE}
              size="1em"
            />
          </StyledBreadcrumbs>
          <StyledCollectionName>{cohortCollection.name}</StyledCollectionName>
          <div>{cohortCollection.description}</div>
        </StyledColumn>

        <StyledTwoColumns>
          <StyledColumn>
            <StyledSectionHeading>
              {t("cohort_collection_view.general_information")}
            </StyledSectionHeading>
            <StyledTwoColumns>
              <LabeledValue
                label={t("cohort_collection_view.acronym")}
                value={cohortCollection.acronym}
              />
              <LabeledValue
                label={t("cohort_collection_view.updated")}
                value={formattedDate}
              />
            </StyledTwoColumns>
            <LabeledValue
              label={t("cohort_collection_view.donors")}
              value={cohortCollection.donor_size}
            />
            <LabeledValue
              label={t("cohort_collection_view.number_of_samples")}
              value={cohortCollection.size}
            />
            <LabeledValue
              label={t("cohort_collection_view.storage_temperatures")}
              value={cohortCollection.storage_temperatures}
            />
            <LabeledValue
              label={t("cohort_collection_view.standards")}
              value={cohortCollection.standards}
            />
          </StyledColumn>

          <StyledColumn>
            <StyledSectionHeading>
              {t("cohort_collection_view.cohort_categories")}
            </StyledSectionHeading>
            <LabeledValue
              label={t("cohort_collection_view.collection_type")}
              value={renderMiabisDataList(cohortCollection.collection_types)}
            />
            <LabeledValue
              label={t("cohort_collection_view.data_type")}
              value={renderMiabisDataList(cohortCollection.data_categories)}
            />
            <LabeledValue
              label={t("cohort_collection_view.sample_type")}
              value={renderMiabisDataList(cohortCollection.material_types)}
            />
          </StyledColumn>
        </StyledTwoColumns>

        <StyledColumn>
          <StyledSectionHeading>
            {t("cohort_collection_view.donor_information")}
          </StyledSectionHeading>
          <StyledTwoColumns>
            <StyledColumn>
              <LabeledValue
                label={t("cohort_collection_view.gender")}
                value={cohortCollection.sex}
              />
              <LabeledValue
                label={t("cohort_collection_view.dataset_type")}
                value={cohortCollection.survey_data}
              />
              <LabeledValue
                label={t("cohort_collection_view.body_parts_examined")}
                value={cohortCollection.body_part_examined}
              />
              <LabeledValue
                label={t("cohort_collection_view.inclusion_criteria")}
                value={cohortCollection.inclusion_criteria}
              />
            </StyledColumn>
            <StyledColumn>
              <LabeledValue
                label={t("cohort_collection_view.age_distribution")}
                value={getCohortAgeDistribution(cohortCollection)}
              />
              <LabeledValue
                label={t("cohort_collection_view.medical_information")}
                value={cohortCollection.medical_information}
              />
              <LabeledValue
                label={t("cohort_collection_view.image_dataset_type")}
                value={cohortCollection.image_dataset_type}
              />
            </StyledColumn>
          </StyledTwoColumns>
        </StyledColumn>
      </StyledMain>
    </StyledCohortCollectionPage>
  );
};

const mapStateToProps = state => ({
  cohortCollectionState: state.cohortCollections
});

export default connect(mapStateToProps, { fetchCohortCollection })(
  CohortCollectionPage
);
