import { useTranslation } from "react-i18next";
import { RequestSection } from "../../RequestSection";
import { maxProjectDescriptionLength } from "../../../helpers/formSchemaHelper";
import { ScientificRationalEthicsSection } from "./ScientificRationalEthicsSection";
import { BenefitRiskEvalSection } from "./BenefitRiskEvalSection";
import Form from "react-bootstrap/Form";

export const EthicalMattersSection = ({
  formProps,
  formControl: FormControl,
  label: Label,
  radio: Radio
}) => {
  const { t } = useTranslation();

  return (
    <>
      <RequestSection
        title={t("ethical_matters")}
        description={t("ethical_matters_desc")}
      >
        {/* TODO: convert list of firstName, lastName, title, organisation and roleInProject with option to add new row per researcher */}
        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"label_list_of_researchers"}
        >
          {t("arForm_researchers")}
        </Label>
        <FormControl
          as={"textarea"}
          name={"researchers"}
          maxLength={maxProjectDescriptionLength}
          rows={3}
        />

        <ScientificRationalEthicsSection
          formProps={formProps}
          formControl={FormControl}
          label={Label}
          radio={Radio}
        />

        <BenefitRiskEvalSection
          formProps={formProps}
          formControl={FormControl}
          label={Label}
          radio={Radio}
        />

        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"label_other_ethical_aspects"}
        >
          <h5>{t("arForm_other_ethical_aspects")}</h5>
        </Label>
        <Form.Group controlId="formLinkages">
          <FormControl
            as={"textarea"}
            name={"linkages"}
            label={t("arForm_linkages_label")}
            maxLength={2500}
          />
        </Form.Group>
        <Form.Group controlId="formMainFunding">
          <FormControl
            as={"textarea"}
            name={"mainfunding"}
            label={t("arForm_mainfunding_label")}
            maxLength={2500}
          />
        </Form.Group>

        <Label
          withErrorIndicator
          formProps={formProps}
          fieldName={"label_other_ethical_aspects"}
        >
          <h5>{t("arForm_publication_plan")}</h5>
        </Label>
        <Form.Group controlId="formPubPlan">
          <FormControl
            as={"textarea"}
            name={"research_result_publish_desc"}
            label={t("arForm_research_result_publish_desc_label")}
          />
        </Form.Group>
      </RequestSection>
    </>
  );
};
