import config from "../config";
import useFetchRequest from "./useFetchRequest";
import accessRequestToLegacy from "../helpers/accessRequestToLegacy";

const useFetchAccessRequest = id => {
  const { request, loading, error } = useFetchRequest(
    `${config.requestsUrl}/access-request/${id}`
  );

  return {
    accessRequest: accessRequestToLegacy(request),
    loading,
    error
  };
};

export default useFetchAccessRequest;
