import { connect } from "react-redux";
import { toggleSampleFilter } from "../actions/samples";
import withRouter from "../helpers/withRouter";
import Filters from "./Filters";

/**
 * Logic for disabling filters is
 * 1) If no filters are selected, disable all but the specimen_type filter
 * 2) If specimen type is selected, enable all other filters
 */
function mapStateToProps(state) {
  return {
    selectedFilters: state.selectedFilters,
    disabledFilters: []
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSelectFilter(group, field, checked) {
      //   dispatch(loadSuggestions(value));
      dispatch(
        toggleSampleFilter(ownProps.params.target, group, field, checked)
      );
    }
  };
}

const FiltersContainer = connect(mapStateToProps, mapDispatchToProps)(Filters);
export default withRouter(FiltersContainer);
