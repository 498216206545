import {
  FETCH_SAMPLE_REQUEST,
  FETCH_SAMPLE_FAILURE,
  FETCH_SAMPLE_SUCCESS,
  TOGGLE_SAMPLE_FILTER
} from "./samples.actions";
import axios from "../helpers/axios";
import config from "../config";
import { logEvent } from "../helpers/amplitude";
import { logError } from "../helpers/logger";

export const doToggleSampleFilter = function(target, group, field, selected) {
  return {
    type: TOGGLE_SAMPLE_FILTER,
    target,
    group,
    field,
    selected
  };
};

export const toggleSampleFilter = function(target, group, field, selected) {
  return function(dispatch) {
    if (selected) {
      logEvent("apply_filter", { target, group, field });
    }
    dispatch(doToggleSampleFilter(target, group, field, selected));
    dispatch(fetchSample(target));
  };
};

export const requestSample = function(code) {
  return {
    type: FETCH_SAMPLE_REQUEST,
    code
  };
};

export const requestSampleSuccess = function(target, sample) {
  return {
    type: FETCH_SAMPLE_SUCCESS,
    target,
    sample
  };
};

export const requestSampleFailure = function(target) {
  return {
    type: FETCH_SAMPLE_FAILURE,
    target
  };
};

export const fetchSample = function(target) {
  return function(dispatch, getState) {
    dispatch(requestSample(target));

    let url = `${config.catalogApiUrl}/v1/samples`;

    const currentLanguage = getState().lng;
    if (currentLanguage && currentLanguage.length) {
      url += "?lang=" + currentLanguage;
    }

    axios({
      method: "post",
      url,
      data: {
        filters: [...getState().selectedFilters],
        code: target
      }
    })
      .then(function(response) {
        dispatch(
          requestSampleSuccess(target, {
            ...response.data
          })
        );
      })
      .catch(function(error) {
        logError(error);
      });
  };
};
