import Yup from "../../Yup";
import {
  getContactInfoFields,
  optionalText,
  requiredTextFn
} from "../../helpers/formValidators";
import {
  commonOptionalContactInfoFields,
  maxProjectDescriptionLength,
  optionalAddress,
  projectDescription,
  projectName
} from "../../helpers/formSchemaHelper";

const optionalContactInfoFields = {
  ...commonOptionalContactInfoFields,
  street1: optionalAddress
};

export const FeasibilityRequestSchema = Yup.object().shape({
  included_biobanks: Yup.array(),

  // Contact Info data is not to be validated because backend(requests-backend) fill the properties internally

  // Background organization
  ...getContactInfoFields(optionalContactInfoFields, "bg_"),

  projektin_nimi: projectName,
  goals_of_the_study: requiredTextFn(maxProjectDescriptionLength),
  who_collects_data: Yup.array(),
  feasibilitylevel: Yup.array(),
  sample_types: Yup.array(),
  diseases_of_interest: optionalText(2500),
  excluded_icd10_codes: optionalText(2000),
  pathological_samples_snomed_codes: optionalText(2000),
  other_Inclusion_exclusion_criterias: optionalText(2000),
  clinical_data_linked: optionalText(2500),
  other_sample_type: optionalText(200),
  other_sample_type_criteria: optionalText(1000),
  patient_age_range: optionalText(200),
  sequence_data_desc: optionalText(5000),
  sampling_time: optionalText(1500),
  who_collects_data_desc: optionalText(1000),
  timelines_for_project: optionalText(1000),
  show_feasibility_table_to_client: optionalText(5000),
  material_inclusion_criteria: optionalText(2000),
  material_exclusion_criteria: optionalText(2000),
  other_cell_sample_type: optionalText(200),
  other_liquid_sample_type: optionalText(200),
  liquid_samples_additional_info: optionalText(500),
  sample_volume_description: optionalText(200),
  requirements_for_critical_study: optionalText(500),
  liquid_sample_inclusion_criteria_description: optionalText(1000),
  liquid_sample_exclusion_criteria_description: optionalText(1000),
  tissue_sample_inclusion_criteria_description: optionalText(1000),
  tissue_sample_additional_info_description: optionalText(1000),
  phenotype_or_lifestyle_desc: optionalText(1000),
  genotype_desc: optionalText(1000),
  recruitment_desc: optionalText(1000)
});

export const SaveFeasibilityRequestSchema = Yup.object().shape({
  projektin_nimi: projectName,
  goals_of_the_study: projectDescription
});
